import React from 'react';
import { A, Box, H1, H4, P, styled } from 'sp-ui';
import { ReactComponent as ForbiddenImage } from '../assets/svg/forbidden.svg';
import { ReactComponent as ShootProofPayLogo } from '../assets/svg/shootproof-pay-logo.svg';
import { UnbecomeBanner } from '../components/navigation';
import Pendo from '../components/Pendo';
import { Message } from '../components/intl';

const ForbiddenPage: React.FC = () => {
    const descriptionLink = (
        <A onClick={() => window.location.replace(`${process.env.REACT_APP_SP_DASHBOARD_URL}`)}>
            <Message id="forbidden.descriptionLink" />
        </A>
    );

    return (
        <>
            <UnbecomeBanner />
            <Header>
                <ShootProofPayLogo width="222px" />
            </Header>
            <Box padding="20px" textAlign="center">
                <Box margin="0 auto 40px" maxWidth="750px">
                    <ForbiddenImage />
                </Box>
                <H1 marginBottom="8px">
                    <Message id="forbidden.heading" />
                </H1>
                <H4 marginBottom="32px">
                    <Message id="forbidden.subheading" />
                </H4>
                <P>
                    <Message
                        id="forbidden.description"
                        values={{
                            link: descriptionLink
                        }}
                    />
                </P>
            </Box>
            <Pendo />
        </>
    );
};

export const Header = styled(Box)(
    ({ theme }) => `
        border-bottom: 1px solid ${theme.colors.gray[200]};
        padding: 16px 20px;
    `
);

export default ForbiddenPage;
