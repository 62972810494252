import React from 'react';
import { ReactComponent as AccountInfoIcon } from '../assets/svg/account-info.svg';
import { ReactComponent as PayoutSettingsIcon } from '../assets/svg/payout-settings.svg';
import { useMessage } from '../components/intl';
import { AccountInformation, PayoutSettings } from '../components/settings';
import SideMenuTabs from '../components/SideMenuTabs';
import SettingsDetailLayout from '../layouts/SettingsDetailLayout';
import SettingsView from '../layouts/SettingsView';

const SettingsPage: React.FC = () => {
    const t = useMessage();
    const sideMenuTabItems = [
        {
            component: () => (
                <SettingsDetailLayout
                    heading={t('settings.accountInformation')}
                    subheading={t('settings.accountInformation.subheading')}>
                    <AccountInformation />
                </SettingsDetailLayout>
            ),
            exact: true,
            icon: <AccountInfoIcon />,
            path: 'settings/account',
            text: t('settings.accountInformation')
        },
        {
            component: () => (
                <SettingsDetailLayout
                    heading={t('settings.payoutSettings')}
                    subheading={t('settings.payoutSettings.subheading')}>
                    <PayoutSettings />
                </SettingsDetailLayout>
            ),
            exact: true,
            icon: <PayoutSettingsIcon />,
            path: 'settings/payout',
            text: t('settings.payoutSettings')
        }
    ];

    return (
        <SettingsView>
            <SideMenuTabs tabs={sideMenuTabItems} />
        </SettingsView>
    );
};

export default SettingsPage;
