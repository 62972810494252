import React, { useCallback, useState } from 'react';
import { Box, IModal, styled } from 'sp-ui';
import { NewBankAccountModalContent, usePayoutAccount } from '../payout-account';
import NewBankAccountButton from './NewBankAccountButton';
import { useMessage } from '../intl';

interface INewBankAccountModalProps {
    close: () => void;
    modalComponent: IModal;
    onCreateError: () => void;
    onPlaidCreated: () => void;
}

const NewBankAccountModal: React.FC<INewBankAccountModalProps> = ({
    close,
    modalComponent: Modal,
    onCreateError,
    onPlaidCreated
}) => {
    const { getPayoutAccounts } = usePayoutAccount();
    const [newBankAccount, setNewBankAccount] = useState<() => void>(() => () => {});
    const onBankAccountCreated = () => getPayoutAccounts();
    const withOnNewBankAccountButtonClick = useCallback((newBankAccount) => {
        setNewBankAccount(() => newBankAccount);
    }, []);
    const t = useMessage();

    return (
        <NewBankAccountModalContainer>
            <Modal heading={t('settings.newBankAccountModal.heading')}>
                <NewBankAccountModalContent
                    onCancel={close}
                    onNewBankAccount={() => {
                        close();
                        newBankAccount();
                    }}
                    hideBackButton
                />
            </Modal>
            <NewBankAccountButton
                onCreateError={onCreateError}
                onCreated={onBankAccountCreated}
                onPlaidCreated={onPlaidCreated}
                withOnClick={withOnNewBankAccountButtonClick}
            />
        </NewBankAccountModalContainer>
    );
};

const NewBankAccountModalContainer = styled(Box)`
    > button {
        display: none;
    }
`;

export default NewBankAccountModal;
