import React from 'react';
import { Redirect } from 'react-router-dom';
import {
    MerchantAccountCompletionStatus,
    useMerchantAccount
} from '../../components/merchant-account';
import { usePayoutAccount } from '../../components/payout-account';
import { isUnverified } from '../../services/onboarding';

const OnboardingStartPage: React.FC = () => {
    const { merchantAccount } = useMerchantAccount();
    const { completionStatus, id } = merchantAccount;
    const { payoutAccounts, payoutAccountsLoading } = usePayoutAccount();

    if (payoutAccountsLoading) {
        return null;
    }

    if (isUnverified(merchantAccount)) {
        return <Redirect to={`/payment-account/${id}/onboarding/incomplete`} />;
    }

    if (completionStatus === MerchantAccountCompletionStatus.Pending) {
        return <Redirect to={`/payment-account/${id}/onboarding/pending`} />;
    }

    if (payoutAccounts.length === 0) {
        return <Redirect to={`/payment-account/${id}/onboarding/payout-account/new`} />;
    }

    return <Redirect to={`/payment-account/${id}/onboarding/settings/edit`} />;
};

export default OnboardingStartPage;
