import React from 'react';
import { Box, BoxProps, styled } from 'sp-ui';

const Hr: React.FC<BoxProps> = (props) => <HrBox as="hr" {...props} />;

const HrBox = styled(Box)(
    ({ theme }) => `
        background: ${theme.colors.gray[200]};
        border: none;
        height: 1px;
    `
);

export default Hr;
