import React, { PropsWithChildren } from 'react';
import { ErrorBoundary as SpUiErrorBoundary } from 'sp-ui';
import { TrackJS } from 'trackjs';

interface IErrorBoundaryProps {
    fallbackContent?: React.ReactNode;
}

const ErrorBoundary: React.FC<PropsWithChildren<IErrorBoundaryProps>> = ({
    children,
    fallbackContent
}) => {
    function onInit() {
        const { REACT_APP_TRACKJS_API_KEY } = process.env;

        if (REACT_APP_TRACKJS_API_KEY && REACT_APP_TRACKJS_API_KEY.length) {
            TrackJS.install({
                token: REACT_APP_TRACKJS_API_KEY
            });
        }
    }

    function onError(error) {
        if (TrackJS.isInstalled()) {
            TrackJS.track(error);
        }
    }

    return (
        <SpUiErrorBoundary fallbackContent={fallbackContent} onInit={onInit} onError={onError}>
            {children}
        </SpUiErrorBoundary>
    );
};

export default ErrorBoundary;
