import React, { createContext, useContext, useEffect, useState } from 'react';
import { IMerchantAccount } from './types';
import { usePaymentsApiFetch } from '../../hooks';

type IMerchantAccountPatchRequest = Partial<
    Pick<IMerchantAccount, 'businessName' | 'payoutScheduleInterval' | 'statementDescriptor'>
>;

interface IMerchantAccountContext {
    merchantAccount: IMerchantAccount | null;
    updateMerchantAccount: (
        merchantAccountPatchRequest: IMerchantAccountPatchRequest
    ) => Promise<{ merchantAccount?: IMerchantAccount; status: number }>;
}

const MerchantAccountContext = createContext<IMerchantAccountContext>({
    merchantAccount: null,
    updateMerchantAccount: async () => {
        return {
            merchantAccount: undefined,
            status: 404
        };
    }
});

interface IMerchantAccountProviderProps {
    merchantAccount: IMerchantAccount;
}

export const MerchantAccountProvider: React.FC<IMerchantAccountProviderProps> = ({
    children,
    merchantAccount: initialMerchantAccount
}) => {
    const { id } = initialMerchantAccount;
    const [merchantAccount, setMerchantAccount] = useState(initialMerchantAccount);
    const { 2: patchMerchantAccount } = usePaymentsApiFetch(`/merchant-account/${id}`, {
        defer: true,
        method: 'patch'
    });
    const updateMerchantAccount = async (
        merchantAccountPatchRequest: IMerchantAccountPatchRequest
    ) => {
        const { data: merchantAccount, status } = await patchMerchantAccount({
            data: merchantAccountPatchRequest
        });

        if (status === 200) {
            setMerchantAccount(merchantAccount);
        }

        return { merchantAccount, status };
    };

    useEffect(() => {
        setMerchantAccount(initialMerchantAccount);
    }, [initialMerchantAccount]);

    return (
        <MerchantAccountContext.Provider value={{ merchantAccount, updateMerchantAccount }}>
            {children}
        </MerchantAccountContext.Provider>
    );
};

type IMerchantAccountHook = IMerchantAccountContext & {
    merchantAccount: IMerchantAccount;
};

export function useMerchantAccount(): IMerchantAccountHook {
    const { merchantAccount, updateMerchantAccount } = useContext(MerchantAccountContext);

    if (!merchantAccount) {
        throw new Error('useMerchantAccount must be used within a MerchantAccountProvider');
    }

    return { merchantAccount, updateMerchantAccount };
}
