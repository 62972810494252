import React, { useState } from 'react';
import {
    Box,
    IModal,
    LabelSubtext,
    ModalStepsProvider,
    P,
    RadioGroup,
    RadioGroupVariant
} from 'sp-ui';
import PayoutAccountDetails, { BorderedPayoutAccountDetails } from './PayoutAccountDetails';
import { Label } from '../form';
import { IPayoutAccount, usePayoutAccount } from '../payout-account';
import HelpLink from '../HelpLink';
import Hr from '../Hr';
import { Message, useMessage } from '../intl';
import { usePaymentsApiFetch, usePaymentsApiMerchantAccountPath } from '../../hooks';
import { useToast } from '../toast';

interface IConfirmRemoveDefaultPayoutAccountProps {
    defaultPayoutAccount: IPayoutAccount;
    newDefaultPayoutAccount: IPayoutAccount;
}

export const ConfirmRemoveDefaultPayoutAccount: React.FC<IConfirmRemoveDefaultPayoutAccountProps> = ({
    defaultPayoutAccount,
    newDefaultPayoutAccount
}) => (
    <>
        <Label marginBottom="10px">
            <Message id="settings.removeDefaultPayoutAccountModal.confirmNewPayoutAccountLabel" />
        </Label>
        <BorderedPayoutAccountDetails marginBottom="24px" payoutAccount={newDefaultPayoutAccount} />
        <Hr marginBottom="20px" />
        <Label>
            <Message id="settings.removePayoutMethod" />
        </Label>
        <LabelSubtext marginBottom="10px">
            <Message id="settings.confirmRemovePayoutAccount" />
        </LabelSubtext>
        <BorderedPayoutAccountDetails marginBottom="12px" payoutAccount={defaultPayoutAccount} />
    </>
);

interface IRemoveDefaultPayoutAccountModalProps {
    modalComponent: IModal;
}

const RemoveDefaultPayoutAccountModal: React.FC<IRemoveDefaultPayoutAccountModalProps> = ({
    modalComponent: Modal
}) => {
    const { createToast } = useToast();
    const { payoutAccounts, setPayoutAccounts } = usePayoutAccount();
    const [defaultPayoutAccount, nonDefaultPayoutAccounts] = payoutAccounts.reduce(
        ([defaultPayoutAccount, nonDefaultPayoutAccounts], payoutAccount) => {
            const { isDefaultForCurrency } = payoutAccount;

            if (!isDefaultForCurrency) {
                nonDefaultPayoutAccounts.push(payoutAccount);

                return [defaultPayoutAccount, nonDefaultPayoutAccounts];
            }

            return [payoutAccount, nonDefaultPayoutAccounts];
        },
        [(null as unknown) as IPayoutAccount, [] as IPayoutAccount[]]
    );
    const deleteDefaultPayoutAccountPath = usePaymentsApiMerchantAccountPath(
        `/payout-account/${defaultPayoutAccount.id}`
    );
    const { 2: deleteDefaultPayoutAccount } = usePaymentsApiFetch(deleteDefaultPayoutAccountPath, {
        defer: true,
        method: 'delete'
    });
    const [newDefaultPayoutAccountId, setNewDefaultPayoutAccountId] = useState(
        nonDefaultPayoutAccounts.length > 0 ? nonDefaultPayoutAccounts[0].id : ''
    );
    const newDefaultPayoutAccount = (nonDefaultPayoutAccounts.find(
        ({ id }) => id === newDefaultPayoutAccountId
    ) as unknown) as IPayoutAccount;
    const patchPayoutAccountPath = usePaymentsApiMerchantAccountPath(
        `/payout-account/${newDefaultPayoutAccountId}`
    );
    const { 2: patchPayoutAccount } = usePaymentsApiFetch(patchPayoutAccountPath, {
        defer: true,
        method: 'patch'
    });
    const t = useMessage();
    const steps = [
        {
            content: (
                <SelectNewDefaultPayoutAccount
                    defaultPayoutAccount={defaultPayoutAccount}
                    newDefaultPayoutAccountId={newDefaultPayoutAccountId}
                    nonDefaultPayoutAccounts={nonDefaultPayoutAccounts}
                    onNewDefaultPayoutAccountSelected={setNewDefaultPayoutAccountId}
                />
            ),
            heading: t('settings.removeDefaultPayoutAccountModal.headingStepOne')
        },
        {
            content: (
                <ConfirmRemoveDefaultPayoutAccount
                    defaultPayoutAccount={defaultPayoutAccount}
                    newDefaultPayoutAccount={newDefaultPayoutAccount}
                />
            ),
            destructive: true,
            heading: t('settings.removeDefaultPayoutAccountModal.headingStepTwo'),
            loadingText: t('settings.removingPayoutMethod')
        }
    ];

    return (
        <ModalStepsProvider
            onLastStepComplete={async (close) => {
                await patchPayoutAccount({ data: { isDefaultForCurrency: true } });
                await deleteDefaultPayoutAccount();
                close();
                createToast({
                    text: t('settings.payoutSettings.payoutAccountUpdatedToast.text'),
                    subText: t('settings.payoutSettings.payoutAccountUpdatedToast.subText')
                });

                const nextPayoutAccounts = nonDefaultPayoutAccounts.map((payoutAccount) => {
                    if (payoutAccount === newDefaultPayoutAccount) {
                        newDefaultPayoutAccount.isDefaultForCurrency = true;
                    }

                    return payoutAccount;
                });
                const [nextNonDefaultPayoutAccount] = nextPayoutAccounts.filter(
                    ({ isDefaultForCurrency }) => !isDefaultForCurrency
                );

                if (nextNonDefaultPayoutAccount) {
                    setNewDefaultPayoutAccountId(nextNonDefaultPayoutAccount.id);
                }

                setPayoutAccounts(nextPayoutAccounts);
            }}
            steps={steps}
            text={{
                buttons: {
                    last: t('settings.removePayoutMethod'),
                    next: t('continueButton'),
                    previous: t('modalWithSteps.previousButton')
                },
                progressHeading: (currentStep, stepCount) =>
                    t('modalWithSteps.progressHeading', {
                        currentStep,
                        stepCount
                    })
            }}>
            <Modal heading={t('settings.removeDefaultPayoutAccountModal.heading')} />
        </ModalStepsProvider>
    );
};

interface ISelectNewDefaultPayoutAccountProps {
    defaultPayoutAccount: IPayoutAccount;
    newDefaultPayoutAccountId: string;
    nonDefaultPayoutAccounts: IPayoutAccount[];
    onNewDefaultPayoutAccountSelected: (payoutAccountId: string) => void;
}

export const SelectNewDefaultPayoutAccount: React.FC<ISelectNewDefaultPayoutAccountProps> = ({
    defaultPayoutAccount,
    newDefaultPayoutAccountId,
    nonDefaultPayoutAccounts,
    onNewDefaultPayoutAccountSelected
}) => (
    <Box data-testid="select-new-payout-account">
        <P marginBottom="14px">
            <Message id="settings.removeDefaultPayoutAccountModal.description" />
        </P>
        <BorderedPayoutAccountDetails marginBottom="24px" payoutAccount={defaultPayoutAccount} />
        <Hr marginBottom="20px" />
        <Box marginBottom="12px">
            <Label>
                <Message id="settings.removeDefaultPayoutAccountModal.payoutAccountIdLabel" />
            </Label>
            <LabelSubtext>
                <Message id="settings.removeDefaultPayoutAccountModal.payoutAccountIdLabelSubtext" />
            </LabelSubtext>
            <Box marginBottom="10px">
                <HelpLink
                    content="settings.removeDefaultPayoutAccountModal.payoutAccountIdLearnMoreLink"
                    path="/articles/360061561054"
                />
            </Box>
            <RadioGroup
                name="payoutAccountId"
                onChange={(_, { value: payoutAccountId } = { description: null, value: '' }) => {
                    onNewDefaultPayoutAccountSelected(payoutAccountId);
                }}
                radioOptions={nonDefaultPayoutAccounts
                    .filter(({ type }) => type === 'bank_account')
                    .map((payoutAccount) => ({
                        description: (
                            <PayoutAccountDetails
                                payoutAccount={payoutAccount}
                                paddingBottom="0px"
                                paddingLeft="0px"
                                paddingRight="0px"
                                paddingTop="0px"
                                showMenu={false}
                            />
                        ),
                        value: payoutAccount.id
                    }))}
                value={newDefaultPayoutAccountId}
                variant={RadioGroupVariant.Bordered}
            />
        </Box>
    </Box>
);

export default RemoveDefaultPayoutAccountModal;
