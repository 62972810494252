import React from 'react';
import { PayoutStatus } from './common';
import { IPayout } from './types';

export interface IWithPayoutStatusProps {
    payout: IPayout;
    payoutStatus: PayoutStatus;
}

const withPayoutStatus = <T extends IWithPayoutStatusProps>(
    Component: React.ComponentType<T>
): React.FC<Pick<T, 'payout'>> => {
    return ({ payout, ...props }) => {
        const { status } = payout;

        return <Component {...(props as T)} payout={payout} payoutStatus={status} />;
    };
};

export default withPayoutStatus;
