import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { IMerchantAccount } from './types';
import { usePaymentsApiFetch } from '../../hooks';

interface IMerchantAccountGetProps {
    onGot: (merchantAccount: IMerchantAccount) => void;
}

const MerchantAccountGet: React.FC<IMerchantAccountGetProps> = ({ onGot }) => {
    const { id: currentMerchantAccountId } = useParams<{ id: string }>();
    const [response, loading] = usePaymentsApiFetch(
        `/merchant-account/${currentMerchantAccountId}`
    );
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        if (!redirect && !loading && response) {
            const { data: merchantAccount, status } = response;

            if (status !== 404 && merchantAccount) {
                onGot(merchantAccount);
            } else {
                setRedirect(true);
            }
        }
    }, [currentMerchantAccountId, loading, onGot, redirect, response]);

    return redirect ? <Redirect to="/" /> : null;
};

export default MerchantAccountGet;
