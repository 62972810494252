import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { A, Box, styled } from 'sp-ui';
import { RouteProps } from 'react-router';

interface NavigationLinkProps {
    href?: string;
    icon?: ReactElement;
    label: React.ReactElement | string;
    to?: string;
}

export const LinkElement = styled(A)(
    ({ theme }) => `
        align-items: center;
        display: flex;
    
        svg {
            width: 20px;
            margin-right: 5px;
        }
    
        ${theme.responsive.smallScreenOnly(`
            svg {
                width: 25px;
                margin-right: 8px;
            }
        `)}
    `
);

const LinkElementText = styled(Box)(
    ({ theme }) => `
        border-left: 1px solid ${theme.colors.gray[200]};
        overflow: hidden;
        padding-left: 23px;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 164px;
        
        ${theme.responsive.smallScreenOnly(`
            border-left: none;
            font-size: ${theme.fontSizes.lg};
            padding-left: 0;
            width: 100%;
            
            svg {
                display: inline-block;
                margin-right: 8px;
                width: 25px;
            }
        `)}
    `
);

const NavigationLink: React.FC<NavigationLinkProps & RouteProps> = ({
    href,
    icon,
    label,
    to = null,
    ...props
}) => {
    const history = useHistory();
    const handleClick = (event) => {
        to ? history.push(to) : event.stopPropagation();
    };

    return href || to ? (
        <>
            {href ? (
                <LinkElement
                    href={href}
                    onClick={(event) => handleClick(event)}
                    target="_blank"
                    {...props}>
                    {icon}
                    {label}
                </LinkElement>
            ) : (
                <LinkElement onClick={(event) => handleClick(event)} {...props}>
                    {icon}
                    {label}
                </LinkElement>
            )}
        </>
    ) : (
        <LinkElementText onClick={(event) => handleClick(event)}>
            {icon}
            {label}
        </LinkElementText>
    );
};

export default NavigationLink;
