import { ButtonVariant, spTheme } from 'sp-ui';
import { ComponentState } from 'sp-ui/dist/src/common/constants/theme/common';
import { DeepPartial } from 'sp-ui/dist/src/common/constants/theme/mergePartialTheme';

const colors = {
    purple: {
        200: '#6749A7'
    }
};
const components = {
    Button: {
        variants: {
            [ButtonVariant.Tertiary]: {
                [ComponentState.Default]: {
                    backgroundColor: 'transparent',
                    borderColor: spTheme.colors.gray[700],
                    color: spTheme.colors.gray[700]
                },
                [ComponentState.Hover]: {
                    borderColor: spTheme.colors.gray[700]
                }
            },
            [ButtonVariant.Dismissive]: {
                [ComponentState.Default]: {
                    backgroundColor: 'transparent'
                }
            }
        }
    },
    Card: {
        baseStyle: {
            elementColor: spTheme.colors.gray[500],
            placeholderColor: spTheme.colors.gray[400]
        }
    },
    DevButton: {
        baseStyle: {
            backgroundColor: spTheme.colors.orange[400],
            borderColor: spTheme.colors.orange[400],
            color: '#FFFFFF'
        }
    }
};

const fontSizes = {
    '2md': '16px'
};

const theme: DeepPartial<typeof spTheme> = {
    colors,
    // @ts-ignore
    components,
    fontSizes
};

export default theme;
