import React, { useRef } from 'react';
import { FormikValues, IModal } from 'sp-ui';
import RefundForm from './RefundForm';
import { ITransaction } from './types';
import { Message, useMessage } from '../intl';

interface IRefundModalProps {
    close: () => void;
    modalComponent: IModal;
    onRefunded: (object) => void;
    transaction: ITransaction;
}

const RefundModal: React.FC<IRefundModalProps> = ({
    close,
    modalComponent: Modal,
    onRefunded,
    transaction
}) => {
    const { amount, amountRefunded } = transaction;
    const onRefundCreated = (refund) => {
        close();
        onRefunded(refund);
    };
    const refundFormRef = useRef<FormikValues>(null);
    const t = useMessage();

    return (
        <Modal
            heading={t('transaction.refundTransaction')}
            subheading={t('transactionRefundAction.modalSubheading')}>
            <Modal.Body>
                <RefundForm
                    initialValues={{ amount: amount - amountRefunded, note: '', reason: '' }}
                    innerRef={refundFormRef}
                    onRefundCreated={onRefundCreated}
                    transaction={transaction}
                />
            </Modal.Body>
            <Modal.PrimaryButton
                onClick={() => {
                    if (!refundFormRef?.current?.isSubmitting) {
                        refundFormRef?.current?.handleSubmit();
                    }
                }}>
                <Message id="transaction.refund" />
            </Modal.PrimaryButton>
        </Modal>
    );
};

export default RefundModal;
