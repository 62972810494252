import React, { ReactNode } from 'react';
import { Banner, BannerVariant, BoxProps, P } from 'sp-ui';
import { TransactionDisputeStatus, TransactionStatus } from './common';
import { ITransaction } from './types';
import withTransactionStatus from './withTransactionStatus';
import { Message, useMessage } from '../intl';
import { getReasonTranslationKey } from '../../services/dispute';
import { getCardFailureTranslation } from '../../services/card';

type ITransactionStatusBannerProps = {
    transaction: ITransaction;
    transactionDisputeStatus: TransactionDisputeStatus;
    transactionStatus: TransactionStatus;
} & BoxProps;

export const TransactionStatusBanner: React.FC<ITransactionStatusBannerProps> = ({
    transaction,
    transactionDisputeStatus,
    transactionStatus,
    ...props
}) => {
    const bannerVariant = [
        TransactionStatus.Blocked,
        TransactionStatus.Failed,
        TransactionStatus.FailedRefund
    ].includes(transactionStatus)
        ? BannerVariant.Danger
        : BannerVariant.Warning;
    const { disputes, failureCode, declineCode } = transaction;
    const [dispute] = disputes;
    const { reason } = dispute || {};
    const showBanner =
        transactionDisputeStatus === TransactionDisputeStatus.Pending ||
        [
            TransactionStatus.Blocked,
            TransactionStatus.Failed,
            TransactionStatus.FailedRefund,
            TransactionStatus.PreDispute
        ].includes(transactionStatus);
    const t = useMessage();
    let statusMessage: ReactNode = null;

    if (
        transactionDisputeStatus === TransactionDisputeStatus.Pending ||
        transactionStatus === TransactionStatus.PreDispute
    ) {
        statusMessage = (
            <Message
                id="transactionStatusBanner.disputedMessage"
                values={{ reason: t(getReasonTranslationKey(reason)) }}
            />
        );
    } else if (transactionStatus === TransactionStatus.Failed) {
        statusMessage = (
            <Message id={getCardFailureTranslation(failureCode, declineCode).statusBanner} />
        );
    } else if (transactionStatus === TransactionStatus.FailedRefund) {
        statusMessage = <Message id="transactionStatusBanner.failedRefundMessage" />;
    } else if (transactionStatus === TransactionStatus.Blocked) {
        statusMessage = <Message id="transactionStatusBannerBlocked.blockedMessage" />;
    }

    return showBanner ? (
        <Banner data-testid="transaction-status-banner" variant={bannerVariant} {...props}>
            <P>{statusMessage}</P>
        </Banner>
    ) : null;
};

export default withTransactionStatus<ITransactionStatusBannerProps>(TransactionStatusBanner);
