import React from 'react';
import { StatusPill, StatusPillVariant } from 'sp-ui';
import { TransactionStatus } from './common';
import { ITransaction } from './types';
import withTransactionStatus from './withTransactionStatus';
import { useCurrency, useMessage } from '../intl';
import { getCardFailureTranslation } from '../../services/card';

interface ITransactionStatusPillProps {
    transaction: ITransaction;
    transactionStatus: TransactionStatus;
}

export const TransactionStatusPill: React.FC<ITransactionStatusPillProps> = ({
    transaction,
    transactionStatus
}) => {
    const { amountRefunded, currency, declineCode, failureCode } = transaction;
    const $ = useCurrency(currency);
    const t = useMessage();
    const failureTranslation = getCardFailureTranslation(failureCode, declineCode);
    const { variant, tooltipLabel, statusPillText } = {
        [TransactionStatus.Blocked]: {
            variant: StatusPillVariant.Danger,
            tooltipLabel: t('transactionStatusPill.blockedLabel'),
            statusPillText: t('transactionStatus.blocked')
        },
        [TransactionStatus.Disputed]: {
            variant: StatusPillVariant.Warning,
            tooltipLabel: t('transactionStatusPill.disputedLabel'),
            statusPillText: t('transactionStatus.disputed')
        },
        [TransactionStatus.Failed]: {
            variant: StatusPillVariant.Danger,
            tooltipLabel: t(failureTranslation.statusTooltip),
            statusPillText: t(failureTranslation.status)
        },
        [TransactionStatus.FailedRefund]: {
            variant: StatusPillVariant.Danger,
            tooltipLabel: t('transactionStatusPill.failedRefundLabel'),
            statusPillText: t('transactionStatus.failedRefund')
        },
        [TransactionStatus.Refunded]: {
            variant: StatusPillVariant.Neutral,
            tooltipLabel: t('transactionStatusPill.refundedLabel'),
            statusPillText: t('transactionStatus.refunded')
        },
        [TransactionStatus.PartiallyRefunded]: {
            variant: StatusPillVariant.Neutral,
            tooltipLabel: t('transactionStatusPill.partiallyRefundedLabel', {
                amount: $(amountRefunded / 100)
            }),
            statusPillText: t('transactionStatus.partiallyRefunded')
        },
        [TransactionStatus.PreDispute]: {
            variant: StatusPillVariant.Warning,
            tooltipLabel: t('transactionStatusPill.preDisputeLabel'),
            statusPillText: t('transactionStatus.preDispute')
        },
        [TransactionStatus.Pending]: {
            variant: StatusPillVariant.Neutral,
            tooltipLabel: t('transactionStatusPill.pendingLabel'),
            statusPillText: t('transactionStatus.pending')
        },
        [TransactionStatus.Succeeded]: {
            variant: StatusPillVariant.Success,
            tooltipLabel: t('transactionStatusPill.succeededLabel'),
            statusPillText: t('transactionStatus.succeeded')
        }
    }[transactionStatus];

    return (
        <StatusPill statusPillText={statusPillText} tooltipLabel={tooltipLabel} variant={variant} />
    );
};

export default withTransactionStatus(TransactionStatusPill);
