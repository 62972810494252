import React from 'react';
import { A } from 'sp-ui';
import { useMerchantAccount } from './Context';
import { GlobalErrorBanner } from '../navigation';
import { PayoutAccountType, usePayoutAccount } from '../payout-account';
import { Message } from '../intl';
import { usePaymentsApiFetch, usePaymentsApiMerchantAccountPath } from '../../hooks';
import { noop } from '../../utils';

const BankAccountOwnershipVerificationDocumentsRequiredBanner: React.FC = () => {
    const {
        merchantAccount: { requirements = [] }
    } = useMerchantAccount();

    if (!requirements.includes('documents.bank_account_ownership_verification.files')) {
        return null;
    }

    const accountLinkPath = usePaymentsApiMerchantAccountPath('/account-link');
    const [accountLinkResponse] = usePaymentsApiFetch(accountLinkPath, {
        data: { type: 'update' },
        fetchOnUrlChange: true,
        handleForbidden: noop,
        method: 'post'
    });
    const accountLinkUrl = accountLinkResponse?.data?.url;
    const { payoutAccounts } = usePayoutAccount();
    const { last4 } = payoutAccounts.find(({ type }) => type === PayoutAccountType.BankAccount) || {
        last4: ''
    };

    return (
        <GlobalErrorBanner>
            <Message
                id="merchantAccount.bankAccountOwnershipVerificationDocumentsRequiredBanner.description"
                values={{
                    last4,
                    link: (
                        <A href={accountLinkUrl}>
                            <Message id="merchantAccount.bankAccountOwnershipVerificationDocumentsRequiredBanner.link" />
                        </A>
                    )
                }}
            />
        </GlobalErrorBanner>
    );
};

export default BankAccountOwnershipVerificationDocumentsRequiredBanner;
