export function getReasonTranslationKey(reason: string): string {
    return (
        {
            bank_cannot_process: 'dispute.reason.bankCannotProcess',
            check_returned: 'dispute.reason.checkReturned',
            credit_not_processed: 'dispute.reason.creditNotProcessed',
            customer_initiated: 'dispute.reason.customerInitiated',
            debit_not_authorized: 'dispute.reason.debitNotAuthorized',
            duplicate: 'dispute.reason.duplicate',
            fraudulent: 'dispute.reason.fraudulent',
            general: 'dispute.reason.general',
            incorrect_account_details: 'dispute.reason.incorrectAccountDetails',
            insufficient_funds: 'dispute.reason.insufficientFunds',
            product_not_received: 'dispute.reason.productNotReceived',
            product_unacceptable: 'dispute.reason.productUnacceptable',
            subscription_canceled: 'dispute.reason.subscriptionCanceled',
            unrecognized: 'dispute.reason.unrecognized'
        }[reason] || ''
    );
}
