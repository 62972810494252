import React, { useCallback, useState } from 'react';
import { Banner, BannerVariant, Modal, P } from 'sp-ui';
import {
    IPayoutAccount,
    PayoutAccountCardForm,
    PayoutAccountCardFormFields
} from '../payout-account';
import { Message, useMessage } from '../intl';

interface INewDebitCardModalContentProps {
    hideBackButton?: boolean;
    inModalBody?: boolean;
    onCancel: () => void;
    onSuccess: (payoutAccount: IPayoutAccount) => void;
}

const NewDebitCardModalContent: React.FC<INewDebitCardModalContentProps> = ({
    hideBackButton,
    inModalBody = false,
    onCancel,
    onSuccess
}) => {
    const onPayoutAccountCreated = useCallback(
        async (payoutAccount) => {
            await onSuccess(payoutAccount);
        },
        [onSuccess]
    );
    const onStripeError = useCallback(() => {}, []);
    const [
        showNoInstantPayoutDebitCardErrorBanner,
        setShowNoInstantPayoutDebitCardErrorBanner
    ] = useState<boolean>(false);
    const [showNotDebitCardErrorBanner, setShowNotDebitCardErrorBanner] = useState<boolean>(false);
    const body = (
        <PayoutAccountCardForm
            onNoInstantPayoutDebitCardError={() => setShowNoInstantPayoutDebitCardErrorBanner(true)}
            onNotDebitCardError={() => setShowNotDebitCardErrorBanner(true)}
            onStripeError={onStripeError}
            onSubmit={() => {
                setShowNoInstantPayoutDebitCardErrorBanner(false);
                setShowNotDebitCardErrorBanner(false);
            }}
            onSuccess={onPayoutAccountCreated}>
            {({ handleSubmit, isSubmitting }) => (
                <>
                    {showNoInstantPayoutDebitCardErrorBanner && (
                        <Banner marginBottom="24px" variant={BannerVariant.Danger}>
                            <P>
                                <Message id="payoutAccount.newDebitCardModalContent.noInstantPayoutDebitCardErrorBanner" />
                            </P>
                        </Banner>
                    )}
                    {showNotDebitCardErrorBanner && (
                        <Banner marginBottom="24px" variant={BannerVariant.Danger}>
                            <P>
                                <Message id="payoutAccount.newDebitCardModalContent.notDebitCardErrorBanner" />
                            </P>
                        </Banner>
                    )}
                    <PayoutAccountCardFormFields />
                    <Modal.PrimaryButton isDisabled={isSubmitting} onClick={() => handleSubmit()}>
                        <Message id="payoutAccount.newDebitCardModal.primaryButton" />
                    </Modal.PrimaryButton>
                    <Modal.SecondaryButton isDisabled={isSubmitting} onClick={onCancel}>
                        <Message id="cancelButton" />
                    </Modal.SecondaryButton>
                </>
            )}
        </PayoutAccountCardForm>
    );
    const t = useMessage();

    return (
        <>
            <Modal.Header
                heading={t('addDebitCard')}
                onBack={hideBackButton ? undefined : onCancel}
            />
            {inModalBody ? body : <Modal.Body>{body}</Modal.Body>}
        </>
    );
};

export default NewDebitCardModalContent;
