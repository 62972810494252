import React, { createContext, useState } from 'react';
import { Box } from 'sp-ui';
import PayoutDetailsNoteView from './NoteView';
import PayoutDetailsPayoutView from './PayoutView';
import PayoutDetailsTransactionView from './TransactionView';
import { IBalanceTransaction } from '../types';

export interface IPayoutDetailsViewContext {
    backToPayout: () => void;
    backToPayoutText: string;
    viewNote: (balanceTransaction: IBalanceTransaction) => void;
    viewPayout: (payoutId: string) => void;
    viewTransaction: (transactionId: string) => void;
}

export const PayoutDetailsViewContext = createContext<IPayoutDetailsViewContext>({
    backToPayout: () => {},
    backToPayoutText: '',
    viewNote: () => {},
    viewPayout: () => {},
    viewTransaction: () => {}
});

interface IPayoutDetailsViewProviderProps {
    backToPayoutText: string;
}

export const PayoutDetailsViewProvider: React.FC<IPayoutDetailsViewProviderProps> = ({
    backToPayoutText,
    children
}) => {
    const [
        balanceTransactionWithNote,
        setBalanceTransactionWithNote
    ] = useState<IBalanceTransaction | null>(null);
    const [transactionId, setTransactionId] = useState<string | null>(null);
    const [viewPayoutId, setViewPayoutId] = useState<string | null>(null);
    const showChildren = !balanceTransactionWithNote && !transactionId && !viewPayoutId;

    return (
        <PayoutDetailsViewContext.Provider
            value={{
                backToPayout: () => {
                    setBalanceTransactionWithNote(null);
                    setTransactionId(null);
                    setViewPayoutId(null);
                },
                backToPayoutText,
                viewNote: setBalanceTransactionWithNote,
                viewPayout: setViewPayoutId,
                viewTransaction: setTransactionId
            }}>
            <>
                {balanceTransactionWithNote ? (
                    <PayoutDetailsNoteView balanceTransaction={balanceTransactionWithNote} />
                ) : transactionId ? (
                    <PayoutDetailsTransactionView transactionId={transactionId} />
                ) : viewPayoutId ? (
                    <PayoutDetailsPayoutView payoutId={viewPayoutId} />
                ) : null}
                <Box
                    height={showChildren ? 'initial' : 0}
                    overflow={showChildren ? 'initial' : 'hidden'}
                    visibility={showChildren ? 'visible' : 'hidden'}>
                    {children}
                </Box>
            </>
        </PayoutDetailsViewContext.Provider>
    );
};
