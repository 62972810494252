import React from 'react';
import { A, Box, Flex, H4, P } from 'sp-ui';
import PayoutDetailsBackToPayoutLink from './BackToPayoutLink';
import { IBalanceTransaction } from '../types';
import { Label } from '../../form';
import Hr from '../../Hr';
import { Currency, Date, Message } from '../../intl';
import {
    DetailsViewContent,
    DetailsViewHeading,
    DetailsViewSupertext
} from '../../../layouts/DetailsView';

interface IPayoutDetailsNoteViewProps {
    balanceTransaction: IBalanceTransaction;
}

const PayoutDetailsNoteView: React.FC<IPayoutDetailsNoteViewProps> = ({ balanceTransaction }) => {
    const { created, currency, metadata, net } = balanceTransaction;
    const { internalAssociatedNote } = metadata;

    return (
        <Flex data-testid="note-detail" flexDirection="column" minHeight="100%">
            <PayoutDetailsBackToPayoutLink />
            <Box flex="1">
                <DetailsViewSupertext marginBottom="28px">
                    <Label textAlign="center">
                        <Message id="payoutDetails.noteView.supertextLabel" />
                    </Label>
                    <Date shortFormat value={created} />
                </DetailsViewSupertext>
                <DetailsViewContent>
                    <DetailsViewHeading>
                        <Currency currency={currency} value={net / 100} />
                    </DetailsViewHeading>
                    <Hr marginBottom="20px" marginTop="20px" />
                    <H4 marginBottom="20px">
                        <Message id="payoutDetails.noteView.contentHeading" />
                    </H4>
                    <P>{internalAssociatedNote}</P>
                </DetailsViewContent>
            </Box>
            <P textAlign="center">
                <Message id="payoutDetails.noteView.supportDescription" />{' '}
                <A href="https://help.shootproof.com/hc/en-us/requests/new" target="_blank">
                    <Message id="payoutDetails.noteView.supportLink" />
                </A>
            </P>
        </Flex>
    );
};

export default PayoutDetailsNoteView;
