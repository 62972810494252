import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { H3, P, yup } from 'sp-ui';
import { FormButton, FormField } from '../../components/form';
import {
    MerchantAccountCompletionStatus,
    useMerchantAccount
} from '../../components/merchant-account';
import {
    OnboardingForm,
    OnboardingFormContent,
    OnboardingPageFooter,
    Step
} from '../../components/onboarding';
import {
    PayoutScheduleInterval,
    PayoutScheduleIntervalField,
    statementDescriptorValidation
} from '../../components/settings';
import { Message, useMessage } from '../../components/intl';

const OnboardingSettingsEditPage: React.FC = () => {
    const { merchantAccount, updateMerchantAccount } = useMerchantAccount();
    const { completionStatus, id } = merchantAccount;
    const { payoutScheduleInterval, statementDescriptor } = merchantAccount;
    const initialValues = {
        payoutScheduleInterval,
        statementDescriptor
    };
    const [redirect, setRedirect] = useState(false);
    const t = useMessage();
    const validationSchema = yup.object().shape({
        payoutScheduleInterval: yup
            .string()
            .oneOf(Object.values(PayoutScheduleInterval))
            .required(),
        statementDescriptor: statementDescriptorValidation
    });

    if (redirect) {
        let redirectTo = `/payment-account/${id}/onboarding/complete`;

        if (completionStatus === MerchantAccountCompletionStatus.Incomplete) {
            redirectTo = `/payment-account/${id}/onboarding/incomplete`;
        } else if (completionStatus === MerchantAccountCompletionStatus.Pending) {
            redirectTo = `/payment-account/${id}/onboarding/pending`;
        }

        return <Redirect to={redirectTo} />;
    }

    return (
        <OnboardingForm
            initialValues={initialValues}
            onSubmit={async ({ payoutScheduleInterval, statementDescriptor }) => {
                const { status } = await updateMerchantAccount({
                    payoutScheduleInterval,
                    statementDescriptor
                });

                if (status === 200) {
                    setRedirect(true);
                }
            }}
            validationSchema={validationSchema}>
            <OnboardingFormContent currentStep={Step.Fourth}>
                <H3>
                    <Message id="onboarding.settingsEdit.heading" />
                </H3>
                <P>
                    <Message id="onboarding.settingsEdit.description" />
                </P>
                <FormField
                    isRequired
                    label="onboarding.settingsEdit.statementDescriptorLabel"
                    labelSubtext={t('onboarding.settingsEdit.statementDescriptorSubtext')}
                    name="statementDescriptor"
                />
                <PayoutScheduleIntervalField />
            </OnboardingFormContent>
            <OnboardingPageFooter hasClose submitFormOnClose>
                <FormButton disableUntilFormValid message="onboarding.settingsEdit.formButton" />
            </OnboardingPageFooter>
        </OnboardingForm>
    );
};

export default OnboardingSettingsEditPage;
