import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { A, Banner, BannerVariant, Box, Span, styled } from 'sp-ui';
import { ReactComponent as TriangleWarningIcon } from '../../assets/svg/triangle-warning-icon.svg';
import { ButtonLink } from '../../components/form';
import { useMerchantAccount } from '../../components/merchant-account';
import { Step, OnboardingFormContent, useOnboardingPage } from '../../components/onboarding';
import { usePayoutAccount } from '../../components/payout-account';
import { Strong } from '../../components/text';
import EmptyState from '../../components/EmptyState';
import { Message, useMessage } from '../../components/intl';
import { goToShootProofAcceptPayments, isUnverified } from '../../services/onboarding';

const IGNORED_MERCHANT_ACCOUNT_REQUIREMENTS = ['tos_acceptance.ip'];

const MERCHANT_ACCOUNT_REQUIREMENT_TRANSLATIONS_MAP = {
    'business_profile.mcc': 'merchantAccount.requirement.businessProfile.mcc',
    'business_profile.url': 'merchantAccount.requirement.businessProfile.url',
    'company.address.city': 'merchantAccount.requirement.company.address.city',
    'company.address.line1': 'merchantAccount.requirement.company.address.line1',
    'company.address.postal_code': 'merchantAccount.requirement.company.address.postalCode',
    'company.address.state': 'merchantAccount.requirement.company.address.state',
    'company.phone': 'merchantAccount.requirement.company.phone',
    'company.tax_id': 'merchantAccount.requirement.company.taxId',
    external_account: 'merchantAccount.requirement.externalAccount',
    'individual.address.city': 'merchantAccount.requirement.individual.address.city',
    'individual.address.line1': 'merchantAccount.requirement.individual.address.line1',
    'individual.address.postal_code': 'merchantAccount.requirement.individual.address.postalCode',
    'individual.address.state': 'merchantAccount.requirement.individual.address.state',
    'individual.dob.day': 'merchantAccount.requirement.individual.dob',
    'individual.dob.month': 'merchantAccount.requirement.individual.dob',
    'individual.dob.year': 'merchantAccount.requirement.individual.dob',
    'individual.email': 'merchantAccount.requirement.individual.email',
    'individual.first_name': 'merchantAccount.requirement.individual.firstName',
    'individual.id_number': 'merchantAccount.requirement.individual.idNumber',
    'individual.last_name': 'merchantAccount.requirement.individual.lastName',
    'individual.phone': 'merchantAccount.requirement.individual.phone',
    'individual.ssn_last_4': 'merchantAccount.requirement.individual.ssnLast4',
    'relationship.owner': 'merchantAccount.requirement.relationship.owner',
    'relationship.representative': 'merchantAccount.requirement.relationship.representative',
    'tos_acceptance.date': 'merchantAccount.requirement.tosAcceptance.date'
};

export const WarningImage = styled(TriangleWarningIcon)`
    height: 80px;
    width: 100px;
`;

export const MissingRequirementsBanner = styled(Banner)(
    ({ theme }) => `
        border-left-width: 1px;
        margin-bottom: 20px;
        width: 100%;

        ul {
            color: ${theme.colors.gray[600]};
            font-size: 10px;
            list-style-position: inside;
            margin-top: 4px;
            padding-left: 8px;
        }
    `
);

const OnboardingIncompletePage: React.FC = () => {
    const dedupeRequirementTranslations = (translations) =>
        translations.filter(
            (translation, index, translations) => translations.indexOf(translation) === index
        );
    const { merchantAccount } = useMerchantAccount();
    const { id, requirements = [] } = merchantAccount;
    const isNotIgnoredRequirement = (requirement) =>
        !IGNORED_MERCHANT_ACCOUNT_REQUIREMENTS.includes(requirement);
    const { payoutAccounts, payoutAccountsLoading } = usePayoutAccount();
    const { showHeaderClose } = useOnboardingPage();
    const t = useMessage();
    const toRequirementTranslation = (requirement) =>
        t(
            MERCHANT_ACCOUNT_REQUIREMENT_TRANSLATIONS_MAP[requirement] ||
                'merchantAccount.requirement.other'
        );

    useEffect(() => {
        showHeaderClose();
    }, [showHeaderClose]);

    if (payoutAccountsLoading) {
        return null;
    }

    if (payoutAccounts.length === 0 && !isUnverified(merchantAccount)) {
        return <Redirect to={`/payment-account/${id}/onboarding/payout-account/new`} />;
    }

    return (
        <OnboardingFormContent currentStep={Step.Second}>
            <EmptyState
                description={<Message id="onboarding.incomplete.emptyState.description" />}
                heading="onboarding.incomplete.emptyState.heading"
                image={<WarningImage />}>
                {requirements.length > 0 && (
                    <MissingRequirementsBanner variant={BannerVariant.Warning}>
                        <Box textAlign="left">
                            <Strong>
                                <Message id="onboarding.incomplete.emptyState.missingRequirementsBanner.leadIn" />
                            </Strong>
                            <ul>
                                {dedupeRequirementTranslations(
                                    requirements
                                        .filter(isNotIgnoredRequirement)
                                        .map(toRequirementTranslation)
                                )
                                    .sort()
                                    .map((translation, index) => (
                                        <li key={index}>
                                            <Span>{translation}</Span>
                                        </li>
                                    ))}
                            </ul>
                        </Box>
                    </MissingRequirementsBanner>
                )}
                <ButtonLink
                    message="onboarding.incomplete.emptyState.button"
                    to={`/payment-account/${id}/verify`}
                />
                <A marginTop="16px" onClick={goToShootProofAcceptPayments}>
                    <Message id="onboarding.incomplete.emptyState.closeLink" />
                </A>
            </EmptyState>
        </OnboardingFormContent>
    );
};

export default OnboardingIncompletePage;
