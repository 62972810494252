import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import { Card, Form, styled, yup } from 'sp-ui';
import { FormField } from '../form';
import { useMerchantAccount } from '../merchant-account';
import { IPayoutAccount } from '../payout-account';
import { useMessage } from '../intl';
import { usePaymentsApiFetch, usePaymentsApiMerchantAccountPath } from '../../hooks';

export const ErrorMessage = styled.label(
    ({ theme }) => `
        color: ${theme.colors.red[500]};
        display: block;
        font-size: ${theme.fontSizes.sm};
        font-weight: ${theme.fontWeights.light};
        margin: -16px 0 16px;
    `
);

interface IPayoutAccountCardFormFieldsProps {
    stripeError?: string;
}

export const PayoutAccountCardFormFields: React.FC<IPayoutAccountCardFormFieldsProps> = ({
    stripeError
}) => {
    const t = useMessage();

    return (
        <>
            <FormField
                data-testid="cardholder-name-field"
                isRequired
                label="payoutAccountCardForm.nameLabel"
                name="name"
                placeholder={t('payoutAccountCardForm.namePlaceholder')}
            />
            <Card />
            {stripeError && (
                <ErrorMessage role="alert" data-testid="validation-error-label">
                    {stripeError}
                </ErrorMessage>
            )}
            <FormField
                data-testid="cardholder-zip-field"
                isRequired
                label="payoutAccountCardForm.addressZipLabel"
                name="address_zip"
                placeholder={t('payoutAccountCardForm.addressZipPlaceholder')}
            />
        </>
    );
};

interface IPayoutAccountCardFormProps {
    children?: React.ReactNode;
    onNoInstantPayoutDebitCardError?: () => void;
    onNotDebitCardError?: () => void;
    onStripeError: (stripeError: string) => void;
    onSubmit?: () => void;
    onSuccess: (payoutAccount: IPayoutAccount, resetForm: () => void) => void;
    render?: (handleSubmit: () => void, isSubmitting: boolean) => React.ReactElement;
}

const PayoutAccountCardForm: React.FC<IPayoutAccountCardFormProps> = ({
    children,
    onNoInstantPayoutDebitCardError = () => {},
    onNotDebitCardError = () => {},
    onStripeError,
    onSubmit = () => {},
    onSuccess,
    ...props
}) => {
    const createPayoutAccountPath = usePaymentsApiMerchantAccountPath('/payout-account');
    const { 2: createPayoutAccount } = usePaymentsApiFetch(createPayoutAccountPath, {
        defer: true,
        method: 'post'
    });
    const createStripeTokenAndPayoutAccount = async (formValues, { resetForm }) => {
        const card = elements?.getElement(CardNumberElement);

        onSubmit();
        setStripeError(undefined);

        if (card && stripe) {
            const { address_zip, name } = formValues;
            const { error, token } = await stripe.createToken(card, {
                address_zip,
                currency: defaultCurrency,
                name
            });

            if (error) {
                const { message } = error;

                setStripeError(message);
            } else if (token) {
                const { id } = token;
                const { isDefaultForCurrency } = formValues;
                const { data: payoutAccount, errors } = await createPayoutAccount({
                    data: { isDefaultForCurrency, token: id }
                });

                if (errors) {
                    const { code } = errors;

                    if (code === 'payment-card-no-instant-payout') {
                        onNoInstantPayoutDebitCardError();
                    } else if (code === 'payment-card-no-debit') {
                        onNotDebitCardError();
                    }
                } else {
                    await onSuccess(payoutAccount, resetForm);
                }
            }
        }
    };
    const { merchantAccount } = useMerchantAccount();
    const { defaultCurrency } = merchantAccount;
    const elements = useElements();
    const initialValues = {
        address_zip: '',
        isDefaultForCurrency: undefined,
        name: ''
    };
    const stripe = useStripe();
    const [stripeError, setStripeError] = useState<string | undefined>();
    const validationSchema = yup.object().shape({
        address_zip: yup
            .string()
            .matches(/^[0-9]{5}(?:-?[0-9]{4})?$/, ({ path }) => `${path} must be 5 or 9 digits`)
            .required(),
        isDefaultForCurrency: yup.boolean(),
        name: yup.string().min(2).trim().required()
    });

    useEffect(() => {
        if (stripeError) {
            onStripeError(stripeError);
        }
    }, [onStripeError, stripeError]);

    return (
        <Form
            initialValues={initialValues}
            onSubmit={createStripeTokenAndPayoutAccount}
            validationSchema={validationSchema}
            {...props}>
            {children}
        </Form>
    );
};

export default PayoutAccountCardForm;
