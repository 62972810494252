import {
    InputLeftAddon,
    NumberInput,
    NumberInputField as ChakraUiNumberInputField
} from '@chakra-ui/react';
import React from 'react';
import { Box, BoxProps, Flex, LabelError, styled, useField } from 'sp-ui';
import { useCurrencySymbol } from '../intl';

interface ICurrencyInputProps {
    currency: string;
    name: string;
}

const CurrencyInput: React.FC<ICurrencyInputProps & BoxProps> = ({ currency, name, ...props }) => {
    const currencySymbol = useCurrencySymbol(currency);
    const [field, meta, helpers] = useField(name);
    const { error } = meta;
    const { onChange, value } = field;
    const { setValue } = helpers;
    const setValueAsCurrency = (value) => setValue(Number(value).toFixed(2));

    return (
        <>
            <Box marginBottom="4px" {...props}>
                <NumberInput
                    clampValueOnBlur={false}
                    keepWithinRange={false}
                    onBlur={({ target }) => setValueAsCurrency(target.value)}
                    precision={2}
                    step={0}
                    value={value}>
                    <Flex flexDirection="row-reverse" width="100%">
                        <NumberInputField
                            hasError={Boolean(error)}
                            name={name}
                            onChange={onChange}
                        />
                        <CurrencyInputLeftAddon>{currencySymbol}</CurrencyInputLeftAddon>
                    </Flex>
                </NumberInput>
            </Box>
            {error && <LabelError>{error}</LabelError>}
        </>
    );
};

export const CurrencyInputLeftAddon = styled(InputLeftAddon)(
    ({ theme }) => `
        background: transparent;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-color: ${theme.colors.gray[200]};
        border-right: none;
        color: ${theme.colors.gray[400]};
        padding-left: 8px;
        padding-right: 8px;
    `
);

export const NumberInputField = styled(ChakraUiNumberInputField)<{ hasError?: boolean }>(
    ({ hasError, theme }) => {
        const { colors } = theme;
        const { gray, red } = colors;
        const { 400: errorColor } = red;
        const { 200: borderColor, 300: focusColor } = gray;

        return `
            border-color: ${borderColor};
            border-left: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            flex: 1;
            outline: none;
            padding-left: 0;

            ${
                hasError
                    ? `
                        border-color: ${errorColor};

                        + * {
                            border-color: ${errorColor}
                        }
                    `
                    : ''
            }

            &:focus, &:hover {
                border-color: ${hasError ? errorColor : focusColor};
                box-shadow: none;

                + * {
                    border-color: ${hasError ? errorColor : focusColor};
                    box-shadow: none;
                }
            }
        `;
    }
);

export default CurrencyInput;
