import React from 'react';
import { Box, Table as SpUiTable, styled } from 'sp-ui';

const Table: React.FC<any> = (props) => (
    <TableContainer>
        <SpUiTable {...props} />
    </TableContainer>
);

const TableContainer = styled(Box)`
    [name='tableSearch'] + div svg:not([cursor='pointer']) {
        display: none;
    }
`;

export default Table;
