import React, { useCallback, useEffect } from 'react';
import { A, BoxProps, Flex, H2, Label, P, smallScreenOnly, styled } from 'sp-ui';
import { ReactComponent as BooksImage } from '../../assets/svg/books.svg';
import { ReactComponent as CalendarImage } from '../../assets/svg/calendar.svg';
import { ReactComponent as InTransitImage } from '../../assets/svg/in-transit.svg';
import { ReactComponent as PiggyBankImage } from '../../assets/svg/piggy-bank.svg';
import { useBalance } from './Context';
import { Button } from '../form';
import { MerchantAccountTier, useMerchantAccount } from '../merchant-account';
import { InstantPayoutAction, IPayout, usePayout } from '../payout';
import { Permission, usePermissions, withPermission } from '../permissions';
import { useToast } from '../toast';
import Hr from '../Hr';
import { Currency, Message, useCurrency, useMessage } from '../intl';

interface IAvailableInstantBalanceTileProps {
    hideImage?: boolean;
    onInstantPayoutCreated?: (payout: IPayout) => void;
}

export const AvailableInstantBalanceTile: React.FC<IAvailableInstantBalanceTileProps> = withPermission(
    Permission.Admin,
    ({ hideImage = false, onInstantPayoutCreated }) => {
        const { balance, loading } = useBalance();
        const { availableInstant, currency } = balance;
        const availableInstantPayout =
            availableInstant && availableInstant > 0 ? availableInstant / 100 : 0;
        const { hasPermission } = usePermissions();
        const hasInstantPayoutPermission = hasPermission(Permission.InstantPayout);
        const t = useMessage();
        const {
            merchantAccount: { tier }
        } = useMerchantAccount();
        const tooltipLabel =
            tier === MerchantAccountTier.Level3
                ? t('instantPayoutTooltipLabel.level3')
                : t('instantPayoutTooltipLabel.default');

        return (
            <FeaturedBalanceSummary
                data-testid="balance-summary-instant-payout"
                centerContent={hideImage}>
                {!hideImage && <BooksImage />}
                {hasInstantPayoutPermission ? (
                    <Flex
                        flexGrow={1}
                        justifyContent={hideImage ? 'space-around' : 'initial'}
                        wrap="wrap">
                        <Label tooltipLabel={tooltipLabel}>
                            <Message id="balanceSummary.availableInstantPayout.heading" />
                        </Label>
                        <FeaturedBalanceSummaryBalance centerContent={hideImage}>
                            <H2 marginRight="32px">
                                <Currency
                                    currency={currency}
                                    value={loading ? null : availableInstantPayout}
                                />
                            </H2>
                            {onInstantPayoutCreated && (
                                <InstantPayoutAction
                                    onInstantPayoutCreated={onInstantPayoutCreated}
                                />
                            )}
                        </FeaturedBalanceSummaryBalance>
                    </Flex>
                ) : (
                    <NoInstantPayoutPermissionContainer>
                        <P>
                            {availableInstantPayout === 0 ? (
                                <Message id="balanceSummary.availableInstantPayout.noPermission.noBalanceDescription" />
                            ) : (
                                <Message
                                    id="balanceSummary.availableInstantPayout.noPermission.description"
                                    values={{
                                        balance: useCurrency(currency)(availableInstantPayout)
                                    }}
                                />
                            )}
                        </P>
                        <A href={`${process.env.REACT_APP_SP_ENABLE_MFA_URL}`}>
                            <Button message="balanceSummary.availableInstantPayout.noPermission.enable2faButton" />
                        </A>
                    </NoInstantPayoutPermissionContainer>
                )}
            </FeaturedBalanceSummary>
        );
    }
);

interface IBalanceProps {
    onInstantPayoutCreated?: () => void;
    onTotalBalanceViewTransactionsClick?: () => void;
    showFuturePayout?: boolean;
    showInTransitBalance?: boolean;
    showTotalBalance?: boolean;
}

const Balance: React.FC<IBalanceProps & BoxProps> = ({
    onInstantPayoutCreated = () => {},
    onTotalBalanceViewTransactionsClick,
    showFuturePayout,
    showInTransitBalance,
    showTotalBalance,
    ...props
}) => {
    const { createToast } = useToast();
    const { balance, getBalance, loading } = useBalance();
    const { available, currency, pending } = balance;
    const {
        getPayouts,
        getNextPayoutBalanceTransactions,
        nextPayoutBalanceTransactions,
        loading: payoutsLoading,
        payoutsMeta
    } = usePayout();
    const { inTransitAmount } = payoutsMeta;
    const { merchantAccount } = useMerchantAccount();
    const { instantPayoutsEnabled } = merchantAccount;
    const t = useMessage();
    const refreshData = useCallback(() => {
        getBalance();
        getPayouts();
        onInstantPayoutCreated();
        createToast({
            text: t('instantPayout.toast.text'),
            subText: t('instantPayout.toast.subText')
        });
    }, [createToast, getBalance, getPayouts, onInstantPayoutCreated, t]);

    useEffect(() => {
        getBalance();
    }, [getBalance]);

    useEffect(() => {
        getPayouts();
    }, [getPayouts]);

    useEffect(() => {
        if (showTotalBalance) {
            getNextPayoutBalanceTransactions();
        }
    }, [getNextPayoutBalanceTransactions, showTotalBalance]);

    return (
        <BalanceSummaryContainer {...props}>
            {instantPayoutsEnabled && (
                <AvailableInstantBalanceTile onInstantPayoutCreated={refreshData} />
            )}
            <SecondaryBalanceSummaries>
                {showInTransitBalance && (
                    <SecondaryBalanceSummary data-testid="balance-summary-in-transit">
                        <InTransitImage />
                        <BalanceSummaryBalance flexWrap="wrap">
                            <Label tooltipLabel={t('balanceSummary.inTransitBalance.tooltipLabel')}>
                                <Message id="balanceSummary.inTransitBalance.heading" />
                            </Label>
                            <H2>
                                <Currency
                                    currency={currency}
                                    value={payoutsLoading ? null : inTransitAmount / 100}
                                />
                            </H2>
                        </BalanceSummaryBalance>
                    </SecondaryBalanceSummary>
                )}
                {showInTransitBalance && (showFuturePayout || showTotalBalance) && (
                    <SmallScreenHr marginBottom="10px" marginTop="10px" />
                )}
                {showFuturePayout && (
                    <SecondaryBalanceSummary data-testid="balance-summary-future-payout">
                        <CalendarImage />
                        <BalanceSummaryBalance flexWrap="wrap">
                            <Label
                                tooltipLabel={t(
                                    'balanceSummary.estimatedFuturePayout.tooltipLabel'
                                )}>
                                <Message id="balanceSummary.estimatedFuturePayout.heading" />
                            </Label>
                            <H2>
                                <Currency
                                    currency={currency}
                                    value={
                                        loading ? null : (Number(available) + Number(pending)) / 100
                                    }
                                />
                            </H2>
                        </BalanceSummaryBalance>
                    </SecondaryBalanceSummary>
                )}
                {(showInTransitBalance || showFuturePayout) && showTotalBalance && (
                    <SmallScreenHr marginBottom="10px" marginTop="10px" />
                )}
                {showTotalBalance && (
                    <SecondaryBalanceSummary data-testid="balance-total-balance">
                        <PiggyBankImage />
                        <BalanceSummaryBalance flexWrap="wrap">
                            <Label tooltipLabel={t('balanceSummary.totalBalance.tooltipLabel')}>
                                <Message id="balanceSummary.totalBalance.heading" />
                            </Label>
                            <H2>
                                <Currency
                                    currency={currency}
                                    value={
                                        loading ? null : (Number(available) + Number(pending)) / 100
                                    }
                                />
                            </H2>
                            {onTotalBalanceViewTransactionsClick && (
                                <ViewTransactionLink
                                    isDisabled={nextPayoutBalanceTransactions.length === 0}
                                    onClick={onTotalBalanceViewTransactionsClick}>
                                    <Message id="balanceSummary.totalBalance.viewTransactionsLink" />
                                </ViewTransactionLink>
                            )}
                        </BalanceSummaryBalance>
                    </SecondaryBalanceSummary>
                )}
            </SecondaryBalanceSummaries>
        </BalanceSummaryContainer>
    );
};

const BalanceSummary = styled(Flex, {
    shouldForwardProp: (prop) => prop !== 'centerContent'
})(
    ({ centerContent, theme }) => `
        flex: 0;
        margin-bottom: 5px;
        padding: 16px;

        h2 {
            color: ${theme.colors.gray[700]};
            font-size: 32px;
            font-weight: ${theme.fontWeights.medium};
            white-space: nowrap;
        }

        label {
            flex-basis: ${centerContent ? 'initial' : '100%'};
            margin-bottom: 0;
            overflow: hidden;
            white-space: nowrap;
        }

        > svg {
            flex: 0 0 60px;
            height: 60px;
            margin-right: 16px;
        }

        ${theme.responsive.smallScreenOnly`
            margin-bottom: 0;
            max-width: initial;

            label {
                flex: initial;
            }

            > svg {
                display: none;
            }
        `}
    `
);

const BalanceSummaryContainer = styled(Flex)(
    ({ theme }) => `
        > div:not(:first-of-type) {
            margin-left: 10px;
        }

        ${theme.responsive.smallScreenOnly`
            flex-direction: column;

            > div:not(:first-of-type) {
                margin-left: 0;
            }
        `}
    `
);

const BalanceSummaryBalance = styled(Flex)(
    ({ theme }) => `
        position: relative;

        ${theme.responsive.smallScreenOnly`
            align-items: center;
            flex-flow: row wrap;
            justify-content: space-between;
            width: 100%;

            label {
                margin: 0;
            }
        `}
    `
);

const FeaturedBalanceSummary = styled(BalanceSummary)(
    ({ theme }) => `
        background-color: ${theme.utility.convertHexToRgba(theme.colors.blue[200], 0.2)};

        ${theme.responsive.smallScreenOnly`
            label {
                margin: 0 auto;
            }
        `}
    `
);

const FeaturedBalanceSummaryBalance = styled(Flex, {
    shouldForwardProp: (prop) => prop !== 'centerContent'
})(
    ({ centerContent, theme }) => `
        align-items: center;
        justify-content: ${centerContent ? 'space-around' : 'space-between'};
        width: 100%;

        ${
            centerContent
                ? `
                    h2 {
                        margin-right: 0;
                    }
                `
                : ''
        }

        ${theme.responsive.smallScreenOnly`
            flex-direction: column;

            h2 {
                margin-bottom: 5px;
                margin-right: 0;

                + div {
                    width: 100%;

                    button {
                        width: 100%;
                    }
                }
            }
        `}
    `
);

export const NoInstantPayoutPermissionContainer = styled(Flex)(
    ({ theme }) => `
        a {
            align-self: center;
        }

        p {
            width: 300px;
        }

        ${theme.responsive.mediumScreenUp`
            p {
                margin-right: 12px;
            }
        `}

        ${theme.responsive.smallScreenOnly`
            flex-wrap: wrap;
            width: 100%;

            a, button {
                width: 100%;
            }

            p {
                margin-bottom: 8px;
                text-align: center;
                width: 100%;
            }
        `}
    `
);

const SecondaryBalanceSummary = styled(BalanceSummary)(
    ({ theme }) => `
        ${theme.responsive.smallScreenOnly`
            padding: 0;

            h2 {
                font-size: 20px;
            }
        `}
    `
);

const SecondaryBalanceSummaries = styled(Flex)(
    ({ theme }) => `
        border-color: ${theme.colors.gray[200]};

        ${theme.responsive.smallScreenOnly`
            border-radius: 4px;
            border-width: 1px;
            flex-direction: column;
            margin-top: 8px;
            padding: 14px 16px;
        `}
    `
);

const SmallScreenHr = smallScreenOnly(Hr);

export const ViewTransactionLink = styled(A)(
    ({ theme }) => `
        white-space: nowrap;

        ${theme.responsive.mediumScreenUp`
            position: absolute;
            top: 100%;
        `}

        ${theme.responsive.smallScreenOnly`
            width: 100%;
        `}
    `
);

export default Balance;
