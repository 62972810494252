import { P, styled } from 'sp-ui';

const GlobalErrorBanner = styled(P)(
    ({ theme }) => `
        background: ${theme.colors.red[700]};
        color: ${theme.colors.white};
        text-align: center;
        padding: 8px;
    `
);

export default GlobalErrorBanner;
