import { parse } from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { A, Box, Flex, LoadingDialog, Span, styled } from 'sp-ui';
import { ReactComponent as TransactionEmptyStateImage } from '../assets/svg/transaction-empty-state-image.svg';
import { Balance, BalanceTransactionsDetails } from '../components/balance';
import { Label } from '../components/form';
import { PayoutSchedule, useMerchantAccount } from '../components/merchant-account';
import {
    TransactionDetails,
    TransactionSource,
    TransactionStatus,
    TransactionStatusPill,
    ITransaction
} from '../components/transaction';
import EmptyState from '../components/EmptyState';
import HelpLink from '../components/HelpLink';
import Table from '../components/Table';
import { Currency, Date as FormattedDate, Message, useMessage } from '../components/intl';
import withPaymentsApiList from '../components/withPaymentsApiList';
import ListWithDetailsView from '../layouts/ListWithDetailsView';
import { getStartOfDayFromThreeMonthsAgo, getStartOfDayToday } from '../date';
import { usePaymentsApiFetch, usePaymentsApiMerchantAccountPath } from '../hooks';
import { convertLowerCamelToSnake } from '../string';

const CreatedCardFieldSpan = styled(Span)(
    ({ theme }) => `
        font-size: ${theme.fontSizes.sm};
        line-height: 16px;
    `
);

interface ITransactionFromQueryStringProps {
    whenPresent: (transaction: ITransaction) => void;
}

export const TransactionFromQueryString: React.FC<ITransactionFromQueryStringProps> = ({
    whenPresent
}) => {
    const { search } = useLocation();
    const { paymentId: transactionId } = parse(search);
    const transactionPath = usePaymentsApiMerchantAccountPath(`/transaction/${transactionId}`);
    const { 0: transactionResponse, 2: getTransaction } = usePaymentsApiFetch(transactionPath, {
        defer: true
    });

    useEffect(() => {
        if (transactionId) {
            getTransaction();
        }
    }, [getTransaction, transactionId]);

    useEffect(() => {
        if (transactionResponse?.data) {
            const { data: transaction } = transactionResponse;

            whenPresent(transaction);
        }
    }, [transactionResponse, whenPresent]);

    return null;
};

const TransactionPage: React.FC = withPaymentsApiList(
    {
        initialCreatedDateRange: [getStartOfDayFromThreeMonthsAgo(), getStartOfDayToday()],
        listPath: '/transaction'
    },
    ({
        getList: getTransactions,
        list: transactions,
        loading,
        initialCreatedDateRange,
        initialSearch,
        isZeroState,
        setCreatedDateRange,
        setFilter,
        setPageIndex,
        setSearch,
        setSort,
        pageCount
    }) => {
        const AmountCardField = ({ amount, currency }) => (
            <Label>
                <Currency currency={currency} value={amount / 100} />
            </Label>
        );
        const CreatedCardField = ({ created }) => (
            <CreatedCardFieldSpan>
                <FormattedDate includeTime shortFormat value={created} />
            </CreatedCardFieldSpan>
        );
        const t = useMessage();
        const checklistFilterOptions = [
            {
                label: t('transactionStatus.succeeded'),
                value: TransactionStatus.Succeeded
            },
            {
                label: t('transactionStatus.disputed'),
                value: TransactionStatus.Disputed
            },
            {
                label: t('transactionStatus.preDispute'),
                value: TransactionStatus.PreDispute
            },
            {
                label: t('transactionStatus.pending'),
                value: TransactionStatus.Pending
            },
            {
                label: t('transactionStatus.refunded'),
                value: TransactionStatus.Refunded
            },
            {
                label: t('transactionStatus.partiallyRefunded'),
                value: TransactionStatus.PartiallyRefunded
            },
            {
                label: t('transactionStatus.failedRefund'),
                value: TransactionStatus.FailedRefund
            },
            {
                label: t('transactionStatus.failed'),
                value: TransactionStatus.Failed
            },
            {
                label: t('transactionStatus.blocked'),
                value: TransactionStatus.Blocked
            }
        ];
        const columns = [
            {
                Cell: ({ value }) => <FormattedDate includeTime shortFormat value={value} />,
                Header: <Message id="transactionTableHeader.created" />,
                id: 'created'
            },
            {
                Cell: ({ value }) => <TransactionSource transactionMetadata={value} />,
                Header: <Message id="transactionTableHeader.source" />,
                disableSortBy: true,
                id: 'metadata'
            },
            {
                Header: <Message id="transactionTableHeader.contactName" />,
                collapsible: true,
                disableSortBy: true,
                id: 'contactName'
            },
            {
                Cell: ({ value }) => <TransactionStatusPill transaction={value} />,
                Header: <Message id="transactionTableHeader.status" />,
                alignment: 'center',
                getCellValue: (transaction) => transaction,
                disableSortBy: true,
                id: 'status'
            },
            {
                Cell: ({ value }) =>
                    value ? <FormattedDate ignoreTime shortFormat value={value} /> : '–',
                Header: <Message id="transactionTableHeader.availableOn" />,
                collapsible: true,
                id: 'availableOn'
            },
            {
                Cell: ({ value }) => (
                    <Currency currency={value.currency} value={value.amount / 100} />
                ),
                Header: <Message id="transactionTableHeader.amount" />,
                alignment: 'right',
                getCellValue: ({ amount, currency }) => ({ amount, currency }),
                id: 'amount'
            },
            {
                Cell: ({ value }) => (
                    <Currency currency={value.currency} value={value.netAmount / 100} />
                ),
                Header: <Message id="transactionTableHeader.netAmount" />,
                alignment: 'right',
                getCellValue: ({ netAmount, currency }) => ({ netAmount, currency }),
                id: 'netAmount'
            },
            {
                Cell: ({ value }) => (
                    <Flex alignItems="flex-end">
                        <A onClick={() => setTransaction(value)}>
                            <Message id="listViewTableActions.view" />
                        </A>
                    </Flex>
                ),
                Header: '',
                alignment: 'right',
                getCellValue: (transaction) => transaction,
                disableSortBy: true,
                id: 'actions'
            }
        ];
        const { merchantAccount } = useMerchantAccount();
        const { id: merchantId } = merchantAccount;
        const [currentMerchantId, setCurrentMerchantId] = useState<string>(merchantId);
        const [
            showTotalBalanceBalanceTransactions,
            setShowTotalBalanceBalanceTransactions
        ] = useState<boolean>(false);
        const [transaction, setTransaction] = useState<ITransaction | null>(null);
        const details = showTotalBalanceBalanceTransactions ? (
            <BalanceTransactionsDetails />
        ) : transaction ? (
            <TransactionDetails
                onRefunded={(transaction) => {
                    getTransactions();
                    setTimeout(() => {
                        setTransaction(transaction);
                    });
                }}
                transaction={transaction}
            />
        ) : null;
        const handleOnChecklistFilterApply = useCallback(
            async (selectedValues, doneApplyingChecklistFilter) => {
                const filterParams = selectedValues.map((item) => ['filter_result', item.value]);

                await setFilter(filterParams);
                doneApplyingChecklistFilter();
            },
            [setFilter]
        );
        const handlePagination = useCallback(
            async ({ pageIndex }, donePaginating) => {
                await setPageIndex(pageIndex);
                donePaginating();
            },
            [setPageIndex]
        );
        const handleSort = useCallback(
            async ([sortBy], doneSorting) => {
                if (sortBy) {
                    const { desc, id } = sortBy;
                    const sortByColumn = convertLowerCamelToSnake(id);

                    await setSort({ by: sortByColumn, direction: desc ? 'desc' : 'asc' });
                } else {
                    await setSort({ by: 'created', direction: 'desc' });
                }

                doneSorting();
            },
            [setSort]
        );
        const [hasLoadedTransactions, setHasLoadedTransactions] = useState<boolean>(false);

        useEffect(() => {
            if (!loading && !hasLoadedTransactions) {
                setHasLoadedTransactions(true);
            }
        }, [hasLoadedTransactions, loading]);

        useEffect(() => {
            if (loading && currentMerchantId !== merchantId) {
                setCurrentMerchantId(merchantId);
                setHasLoadedTransactions(false);
            }
        }, [currentMerchantId, hasLoadedTransactions, loading, merchantId]);

        return (
            <ListWithDetailsView
                details={details}
                heading={t('transactionList.heading')}
                onDetailsClose={() => {
                    setShowTotalBalanceBalanceTransactions(false);
                    setTransaction(null);
                }}>
                <PayoutSchedule data-testid="list-view-subtext" marginBottom="20px" />
                {!hasLoadedTransactions ? (
                    <LoadingDialog description="" />
                ) : isZeroState ? (
                    <EmptyState
                        description={
                            <>
                                <Message id="transaction.emptyState.description" />
                                <br />
                                <HelpLink
                                    content="transaction.emptyState.learnMoreLink"
                                    path="/articles/360062684993"
                                />
                            </>
                        }
                        heading="transaction.emptyState.heading"
                        image={
                            <Box maxWidth="320px" width="320px">
                                <TransactionEmptyStateImage />
                            </Box>
                        }
                    />
                ) : (
                    <>
                        <Balance
                            marginBottom="24px"
                            onTotalBalanceViewTransactionsClick={() => {
                                setShowTotalBalanceBalanceTransactions(true);
                            }}
                            showTotalBalance
                        />
                        <Table
                            cardFields={[
                                'contactName',
                                AmountCardField,
                                CreatedCardField,
                                'status'
                            ]}
                            columns={columns}
                            data={transactions || []}
                            checklistFilterOptions={checklistFilterOptions}
                            checklistFilterPlaceholder={t(
                                'transaction.table.checklistFilterPlaceholder'
                            )}
                            enableChecklistFilter
                            enablePagination
                            initialDateRangeFilterValue={initialCreatedDateRange}
                            initialSearchValue={initialSearch}
                            isRowSelected={({ id }) => id === transaction?.id}
                            isStickyHeader
                            onCardClick={setTransaction}
                            onChecklistFilterApply={handleOnChecklistFilterApply}
                            onDateRangeFilterApplied={async (dateRange, doneApplyingDateRange) => {
                                await setCreatedDateRange(dateRange);
                                doneApplyingDateRange();
                            }}
                            onPagination={handlePagination}
                            onSearch={async (search, doneSearching) => {
                                await setSearch(search);
                                doneSearching();
                            }}
                            onSearchClear={async (doneClearing) => {
                                await setSearch('');
                                doneClearing();
                            }}
                            onSort={handleSort}
                            pageCount={pageCount}
                            searchPlaceholder={t('transaction.table.searchPlaceholder')}
                        />
                        <TransactionFromQueryString whenPresent={setTransaction} />
                    </>
                )}
            </ListWithDetailsView>
        );
    }
);

export default TransactionPage;
