import React from 'react';
import { Box, Modal, P } from 'sp-ui';
import { ReactComponent as AddBankImage } from '../../assets/svg/add-bank.svg';
import { Message } from '../intl';

interface INewBankAccountModalContentProps {
    hideBackButton?: boolean;
    onCancel: () => void;
    onNewBankAccount: () => void;
}

const NewBankAccountModalContent: React.FC<INewBankAccountModalContentProps> = ({
    hideBackButton,
    onCancel,
    onNewBankAccount
}) => (
    <>
        <Modal.Header onBack={hideBackButton ? undefined : onCancel} />
        <Modal.Body>
            <Box w="120px" margin="32px auto 12px">
                <AddBankImage />
            </Box>
            <P mb="68px" textAlign="center">
                <Message id="payoutAccount.newBankAccountModal.description" />
            </P>
        </Modal.Body>
        <Modal.PrimaryButton onClick={onNewBankAccount}>
            <Message id="continueButton" />
        </Modal.PrimaryButton>
        <Modal.SecondaryButton onClick={onCancel}>
            <Message id="cancelButton" />
        </Modal.SecondaryButton>
    </>
);

export default NewBankAccountModalContent;
