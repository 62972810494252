import React from 'react';
import { TransactionDisputeStatus, TransactionStatus } from './common';
import { ITransaction } from './types';

export interface IWithTransactionStatusProps {
    transaction: ITransaction;
    transactionDisputeStatus: TransactionDisputeStatus;
    transactionStatus: TransactionStatus;
}

const withTransactionStatus = <T extends IWithTransactionStatusProps>(
    Component: React.ComponentType<T>
): React.FC<Omit<Omit<T, 'transactionStatus'>, 'transactionDisputeStatus'>> => {
    return ({ transaction, ...props }) => {
        const { disputes, refunded, result: transactionStatus } = transaction;
        const [dispute] = disputes;
        const { isInquiry, status: disputeStatus } = dispute || {};
        let transactionDisputeStatus =
            dispute && !isInquiry && !refunded
                ? TransactionDisputeStatus.Pending
                : TransactionDisputeStatus.None;

        if (disputeStatus === TransactionDisputeStatus.Lost) {
            transactionDisputeStatus = TransactionDisputeStatus.Lost;
        } else if (
            [TransactionDisputeStatus.WarningClosed, TransactionDisputeStatus.Won].includes(
                disputeStatus
            )
        ) {
            transactionDisputeStatus = TransactionDisputeStatus.Won;
        }

        return (
            <Component
                {...(props as T)}
                transaction={transaction}
                transactionDisputeStatus={transactionDisputeStatus}
                transactionStatus={transactionStatus}
            />
        );
    };
};

export default withTransactionStatus;
