import { IMerchantAccount } from '../components/merchant-account';

export const goToShootProofAcceptPayments = () => {
    window.location.assign(`${process.env.REACT_APP_SP_ACCOUNT_ACCEPT_PAYMENTS_URL}`);
};

export const isUnverified = (merchantAccount: IMerchantAccount) => {
    return [
        'hasCurrentlyDueRequirements',
        'hasEventuallyDueRequirements',
        'hasPastDueRequirements'
    ].some((verificationProp) => merchantAccount[verificationProp]);
};
