import React from 'react';
import { Stepper as SpUiStepper, styled } from 'sp-ui';
import { useMessage } from '../intl';

export enum Step {
    First = 0,
    Second = 1,
    Third = 2,
    Fourth = 3
}

interface IStepperProps {
    currentStep: number;
    isCurrentStepComplete?: boolean;
}

const Stepper: React.FC<IStepperProps> = ({ currentStep, isCurrentStepComplete = false }) => {
    const t = useMessage();
    const steps = [
        { label: t('onboarding.stepper.stepOne') },
        { label: t('onboarding.stepper.stepTwo') },
        { label: t('onboarding.stepper.stepThree') },
        { label: t('onboarding.stepper.stepFour') }
    ];

    return (
        <StyledSpUiStepper
            currentStep={steps[currentStep]}
            isCurrentStepComplete={isCurrentStepComplete}
            steps={steps}
        />
    );
};

export const StyledSpUiStepper = styled(SpUiStepper)(
    ({ theme }) => theme.responsive.mediumScreenUp`
        margin-bottom: 30px;
    `
);

export default Stepper;
