import React from 'react';
import { Redirect } from 'react-router-dom';
import { usePaymentsApiFetch } from '../hooks';

const IndexPage: React.FC = () => {
    const [merchantAccountsResponse] = usePaymentsApiFetch('/merchant-account');
    const { data: merchantAccounts } = merchantAccountsResponse || {};

    if (merchantAccounts?.length === 0) {
        return <Redirect to="/payment-account/new" />;
    }

    if (merchantAccounts?.length > 0) {
        const [merchantAccount] = merchantAccounts;
        const { id } = merchantAccount;

        return <Redirect to={`/payment-account/${id}`} />;
    }

    return null;
};

export default IndexPage;
