import React, { createContext, useCallback, useContext, useState } from 'react';
import { Toast } from 'sp-ui';

export interface IToastContext {
    createToast: (toastConfig: object) => void;
}

export const ToastContext = createContext<IToastContext>({
    createToast: () => {}
});

export const ToastProvider: React.FC = ({ children, ...props }) => {
    const [{ createToast }, setCreateToast] = useState<{
        createToast: (toastConfig: object) => void;
    }>({ createToast: () => {} });
    const subscribeToast = useCallback((createToast) => {
        setCreateToast({ createToast });
    }, []);

    return (
        <ToastContext.Provider value={{ createToast }} {...props}>
            {children}
            <Toast subscribe={subscribeToast} />
        </ToastContext.Provider>
    );
};

export function useToast(): IToastContext {
    const { createToast } = useContext(ToastContext);

    return { createToast };
}
