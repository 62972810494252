import React, { ReactNode } from 'react';
import { Box, BoxProps, Flex, H1, P, styled } from 'sp-ui';
import { Button } from './form';
import { Message } from './intl';

interface IEmptyStateProps {
    buttonOnClick?: () => void;
    buttonText?: string;
    description: ReactNode;
    heading: string;
    image: ReactNode;
}

const EmptyState: React.FC<BoxProps & IEmptyStateProps> = ({
    buttonOnClick,
    buttonText,
    children,
    description,
    heading,
    image,
    ...props
}) => (
    <EmptyStateContainer maxWidth="550px" {...props}>
        <Box marginBottom="48px" marginTop="48px">
            {image}
        </Box>
        <H1 marginBottom="18px">
            <Message id={heading} />
        </H1>
        <P lineHeight="24px" marginBottom="12px">
            {description}
        </P>
        {buttonOnClick && buttonText && (
            <Button onClick={buttonOnClick}>
                <Message id={buttonText} />
            </Button>
        )}
        {children}
    </EmptyStateContainer>
);

const EmptyStateContainer = styled(Flex)(
    ({ theme }) => `
        align-items: center;
        flex-direction: column;
        margin: 0 auto;
        text-align: center;

        button {
            min-width: 173px;
        }

        ${theme.responsive.smallScreenOnly`
            padding-left: 16px;
            padding-right: 16px;

            button {
                min-width: 227px;
            }
        `}
    `
);

export default EmptyState;
