import React from 'react';
import { Box, Flex, Form, FormikHelpers, FormikValues, styled, yup } from 'sp-ui';
import Stepper, { Step } from './Stepper';

export const FormContainer = styled(Box)`
    height: 100%;

    form {
        height: 100%;
    }
`;

export const FormContent = styled(Box)(
    ({ theme }) => `
        margin-left: auto;
        margin-right: auto;
        max-width: 554px;

        h2,
        h3 {
            margin-bottom: 12px;
        }

        > p {
            margin-bottom: 12px;
        }

        select {
            margin-bottom: 40px;
        }

        ${theme.responsive.mediumScreenUp`
            > p:first-of-type {
                margin-bottom: 34px;
            }
        `}
    `
);

export const FormContentWrapper = styled(Box)(
    ({ theme }) => `
        height: 100%;
        overflow-y: auto;
        padding: 20px 20px 40px;

        ${theme.responsive.smallScreenOnly`
            padding: 34px 16px 16px;
        `}
    `
);

interface IOnboardingFormContentProps {
    children: React.ReactNode;
    currentStep: Step;
}

export const OnboardingFormContent: React.FC<IOnboardingFormContentProps> = ({
    children,
    currentStep
}) => (
    <>
        <Stepper currentStep={currentStep} />
        <FormContentWrapper>
            <FormContent>{children}</FormContent>
        </FormContentWrapper>
    </>
);

interface IOnboardingFormProps {
    children?: React.ReactNode;
    enableReinitialize?: boolean;
    initialValues: object;
    onSubmit?: (formValues: FormikValues, formActions: FormikHelpers<FormikValues>) => void;
    validationSchema?: yup.ObjectSchema<any>;
}

const OnboardingForm: React.FC<IOnboardingFormProps> = ({
    children,
    enableReinitialize = false,
    initialValues,
    onSubmit = () => {},
    validationSchema
}) => (
    <FormContainer>
        <Form
            enableReinitialize={enableReinitialize}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validateOnMount
            validationSchema={validationSchema}>
            <Flex flexDirection="column" height="100%">
                {children}
            </Flex>
        </Form>
    </FormContainer>
);

export default OnboardingForm;
