import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { RefObject, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import {
    Flex,
    Card,
    FormElementVariant,
    Form,
    FormikValues,
    IModal,
    styled,
    useFormikContext,
    useModal,
    yup
} from 'sp-ui';
import { v4 as uuidv4 } from 'uuid';
import { DevButton, FormField, Label } from '../components/form';
import { usePaymentsApiFetch, usePaymentsApiMerchantAccountPath } from '../hooks';

interface IPaymentIntentMetadata {
    app: 'tave' | 'shootproof';
    appId: string;
    contactName: string;
    contactEmail: string;
    contextGalleryPhotoCount?: number;
    contextTitle?: string;
    brandName: string;
    labCostAmount?: number;
    labName?: string;
    labCreditAmount?: number;
    paymentTrigger: 'client' | 'studio' | 'system';
    sourceId: string;
    sourceNumber: string;
    sourceType: 'invoice' | 'order';
    studioCreatedDate: string;
    studioGalleryCount?: number;
    studioInvoiceCount?: number;
    studioJobCount?: number;
    studioLicense: 'paying' | 'trial';
    studioOrderCount?: number;
}

interface IDestinationChargeToolProps {
    modal: IModal;
}

const DestinationChargeTool: React.FC<IDestinationChargeToolProps> = ({ modal: Modal }) => {
    const destinationChargeToolFormRef = useRef<FormikValues>(null);

    return (
        <DestinationChargeToolForm innerRef={destinationChargeToolFormRef}>
            {({ isSubmitting, isValid }) => (
                <>
                    <Modal
                        heading="Destination Charge Tool"
                        subheading="Use to create a payment intent and test destination charges"
                        closeOnOverlayClick={false}>
                        <Modal.Body>
                            <FormField
                                isRequired
                                label="tools.contactNameLabel"
                                name="contactName"
                                placeholder="Contact name"
                            />
                            <FormField
                                isRequired
                                label="tools.destinationChargeTool.paymentAmountLabel"
                                name="paymentAmount"
                                placeholder="Amount (whole number e.g 1200)"
                            />
                            <FormField
                                isRequired
                                label="tools.contactEmailLabel"
                                name="contactEmail"
                                placeholder="Contact email"
                            />
                            <Card />
                            <FormField
                                isRequired
                                label="tools.destinationChargeTool.descriptionLabel"
                                name="description"
                                placeholder="Description"
                            />
                            <FormField
                                label="tools.destinationChargeTool.labCostAmountLabel"
                                name="labCostAmount"
                                placeholder="Lab Cost Amount (whole number e.g 1200)"
                            />
                            <FormField
                                label="tools.destinationChargeTool.labCreditAmountLabel"
                                name="labCreditAmount"
                                placeholder="Lab Credit Amount (whole number e.g 1200)"
                            />
                            <FormField
                                label="tools.destinationChargeTool.labNameLabel"
                                name="labName"
                            />
                            <FormField
                                isRequired
                                label="tools.destinationChargeTool.studioLicenseLabel"
                                name="studioLicense"
                                selectOptions={[
                                    {
                                        label: 'Trial',
                                        value: 'trial'
                                    },
                                    {
                                        label: 'Paying',
                                        value: 'paying'
                                    }
                                ]}
                                variant={FormElementVariant.Select}
                            />
                            <FormField
                                isRequired
                                label="tools.destinationChargeTool.studioCreatedDateLabel"
                                name="studioCreatedDate"
                                variant={FormElementVariant.DatePicker}
                            />
                            <FormField
                                label="tools.destinationChargeTool.contextGalleryPhotoCountLabel"
                                name="contextGalleryPhotoCount"
                            />
                            <FormField
                                isRequired
                                label="tools.destinationChargeTool.paymentTriggerLabel"
                                name="paymentTrigger"
                                selectOptions={[
                                    {
                                        label: 'Client',
                                        value: 'client'
                                    },
                                    {
                                        label: 'Studio',
                                        value: 'studio'
                                    },
                                    {
                                        label: 'System',
                                        value: 'system'
                                    }
                                ]}
                                variant={FormElementVariant.Select}
                            />
                            <FormField
                                isRequired
                                label="tools.destinationChargeTool.sourceIdLabel"
                                name="sourceId"
                            />
                            <FormField
                                isRequired
                                label="tools.destinationChargeTool.sourceNumberLabel"
                                name="sourceNumber"
                            />
                            <FormField
                                isRequired
                                label="tools.destinationChargeTool.sourceTypeLabel"
                                name="sourceType"
                                selectOptions={[
                                    {
                                        label: 'Invoice',
                                        value: 'invoice'
                                    },
                                    {
                                        label: 'Order',
                                        value: 'order'
                                    }
                                ]}
                                variant={FormElementVariant.Select}
                            />
                            <FormField
                                label="tools.destinationChargeTool.studioGalleryCountLabel"
                                name="studioGalleryCount"
                            />
                            <FormField
                                isRequired
                                label="tools.destinationChargeTool.studioInvoiceCountLabel"
                                name="studioInvoiceCount"
                            />
                            <FormField
                                label="tools.destinationChargeTool.studioJobCountLabel"
                                name="studioJobCount"
                            />
                            <FormField
                                isRequired
                                label="tools.destinationChargeTool.studioOrderCountLabel"
                                name="studioOrderCount"
                            />
                            <FormField
                                isRequired
                                label="tools.destinationChargeTool.brandNameLabel"
                                name="brandName"
                            />
                            <FormField label="tools.contextTitleLabel" name="contextTitle" />
                            <FormField
                                isRequired
                                label="tools.destinationChargeTool.appLabel"
                                name="app"
                                selectOptions={[
                                    {
                                        label: 'ShootProof',
                                        value: 'shootproof'
                                    },
                                    {
                                        label: 'Táve',
                                        value: 'tave'
                                    }
                                ]}
                                variant={FormElementVariant.Select}
                            />
                            <FormMessages />
                        </Modal.Body>
                        <Modal.PrimaryButton
                            onClick={() => destinationChargeToolFormRef?.current?.handleSubmit()}
                            isDisabled={isSubmitting || !isValid}>
                            Create Payment
                        </Modal.PrimaryButton>
                    </Modal>
                </>
            )}
        </DestinationChargeToolForm>
    );
};

interface IDestinationChargeToolFormProps {
    innerRef: RefObject<FormikValues>;
}

const DestinationChargeToolForm: React.FC<IDestinationChargeToolFormProps> = ({
    innerRef,
    ...props
}) => {
    const paymentIntentPath = usePaymentsApiMerchantAccountPath('/payment-intent');
    const { 2: createPaymentIntent } = usePaymentsApiFetch(paymentIntentPath, {
        defer: true,
        method: 'post'
    });
    const elements = useElements();
    const fourMonthsAgo = new Date();

    fourMonthsAgo.setMonth(fourMonthsAgo.getMonth() - 4);

    const handlePaymentIntent = async ({
        app,
        brandName,
        contactName,
        contactEmail,
        contextGalleryPhotoCount,
        contextTitle,
        currency,
        description,
        labCostAmount,
        labCreditAmount,
        labName,
        paymentAmount,
        paymentMethod,
        paymentTrigger,
        sourceId,
        sourceNumber,
        sourceType,
        studioCreatedDate,
        studioGalleryCount,
        studioInvoiceCount,
        studioJobCount,
        studioLicense,
        studioOrderCount
    }) => {
        const isNumber = (number: string) => !isNaN(parseInt(number));
        const metadata: IPaymentIntentMetadata = {
            app,
            appId: 'app-id',
            brandName,
            contactName,
            contactEmail,
            contextGalleryPhotoCount: contextGalleryPhotoCount
                ? Number(contextGalleryPhotoCount)
                : undefined,
            contextTitle,
            paymentTrigger,
            sourceId,
            sourceNumber,
            sourceType,
            studioCreatedDate: studioCreatedDate.toISOString(),
            studioGalleryCount: isNumber(studioGalleryCount)
                ? Number(studioGalleryCount)
                : undefined,
            studioInvoiceCount: isNumber(studioInvoiceCount)
                ? Number(studioInvoiceCount)
                : undefined,
            studioJobCount: isNumber(studioJobCount) ? Number(studioJobCount) : undefined,
            studioLicense,
            studioOrderCount: isNumber(studioOrderCount) ? Number(studioOrderCount) : undefined
        };

        if (labCostAmount) {
            metadata.labCostAmount = Number(labCostAmount);

            if (labCreditAmount) {
                metadata.labCreditAmount = Number(labCreditAmount);
            }

            metadata.labName = labName;
        }

        return await createPaymentIntent({
            data: {
                paymentAmount: Number(paymentAmount),
                currency,
                description,
                metadata,
                paymentMethod
            }
        });
    };
    const handleSubmit = async (formValues, { resetForm, setErrors, setStatus }) => {
        const card = elements?.getElement(CardNumberElement);

        if (!card) {
            return;
        }

        setStatus(null);

        if (stripe) {
            const { paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card
            });

            if (!paymentMethod) {
                return;
            }

            const { data, status } = await handlePaymentIntent({
                ...formValues,
                paymentMethod: paymentMethod.id
            });

            if (status === 402) {
                const { userMessage } = data;

                setErrors({ form: userMessage });
            } else {
                resetForm({ values: formValues });
                setTimeout(() => {
                    setStatus('Succeeded');
                });
            }
        }
    };
    const initialValues = {
        app: 'shootproof',
        brandName: "Mike's Mugs",
        contactName: '',
        contactEmail: '',
        contextGalleryPhotoCount: '100',
        contextTitle: "Jane and John's Wedding",
        currency: 'USD',
        description: '',
        labCostAmount: '',
        labCreditAmount: '',
        labName: "Posey's Poses",
        paymentAmount: '',
        paymentTrigger: 'client',
        sourceId: '12345',
        sourceNumber: '12345',
        sourceType: 'order',
        studioCreatedDate: fourMonthsAgo,
        studioGalleryCount: '',
        studioInvoiceCount: '0',
        studioJobCount: '',
        studioLicense: 'paying',
        studioOrderCount: '0'
    };
    const stripe = useStripe();
    const validationSchema = yup.object().shape({
        app: yup.string().required().oneOf(['shootproof', 'tave']),
        brandName: yup.string().required(),
        contactName: yup.string().required(),
        contactEmail: yup.string().required(),
        contextGalleryPhotoCount: yup.number(),
        contextTitle: yup.string(),
        description: yup.string().required().min(1).max(200),
        paymentAmount: yup.number().required().min(1).max(99999999),
        paymentTrigger: yup.string().required().oneOf(['client', 'studio', 'system']),
        labCostAmount: yup.number().min(1).max(999999),
        labCreditAmount: yup.number().min(1).max(999999),
        labName: yup.string(),
        sourceId: yup.string().required(),
        sourceNumber: yup.string().required(),
        sourceType: yup.string().required().oneOf(['invoice', 'order']),
        studioCreatedDate: yup.date().required(),
        studioGalleryCount: yup.number().min(0).max(99999999),
        studioInvoiceCount: yup.number().required().min(0).max(99999999),
        studioJobCount: yup.number().min(0).max(99999999),
        studioLicense: yup.string().required().oneOf(['paying', 'trial']),
        studioOrderCount: yup.number().required().min(0).max(99999999)
    });

    return (
        <Form<typeof initialValues>
            initialValues={initialValues}
            // @ts-ignore
            innerRef={innerRef}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            {...props}
        />
    );
};

const FormMessages: React.FC = () => {
    const { errors, status } = useFormikContext<{ form: string }>();

    return (
        <>
            <StyledSuccessLabel role="alert">{status}</StyledSuccessLabel>
            <StyledErrorLabel role="alert">{errors.form}</StyledErrorLabel>
        </>
    );
};

interface IPopUpShopPayoutToolProps {
    modal: IModal;
}

const PopUpShopPayoutTool: React.FC<IPopUpShopPayoutToolProps> = ({ modal: Modal }) => {
    const popUpShopPayoutToolFormRef = useRef<FormikValues>(null);

    return (
        <PopUpShopPayoutToolForm innerRef={popUpShopPayoutToolFormRef}>
            {({ isSubmitting, isValid }) => (
                <Modal
                    heading="Print Store Payout Tool"
                    subheading="Use to create a transfer and test print store payouts"
                    closeOnOverlayClick={false}>
                    <Modal.Body>
                        <FormField
                            isRequired
                            label="tools.popUpShopPayoutTool.amountLabel"
                            name="amount"
                            placeholder="Amount (whole number e.g 1200)"
                            type="number"
                        />
                        <FormField
                            isRequired
                            label="tools.contactNameLabel"
                            name="contactName"
                            placeholder="Contact name"
                        />
                        <FormField
                            isRequired
                            label="tools.contactEmailLabel"
                            name="contactEmail"
                            placeholder="Contact email"
                        />
                        <FormField
                            isRequired
                            label="tools.popUpShopPayoutTool.sourceIdLabel"
                            name="sourceId"
                        />
                        <FormField
                            isRequired
                            label="tools.popUpShopPayoutTool.sourceNumberLabel"
                            name="sourceNumber"
                        />
                        <FormField isRequired label="tools.contextTitleLabel" name="contextTitle" />
                        <FormField
                            isRequired
                            label="tools.popUpShopPayoutTool.orderSubtotalLabel"
                            name="orderSubtotal"
                            placeholder="Collage.com Subtotal (whole number e.g 1200)"
                            type="number"
                        />
                        <FormField
                            isRequired
                            label="tools.popUpShopPayoutTool.digitalsSubtotalLabel"
                            name="digitalsSubtotal"
                            placeholder="Digitals Subtotal (whole number e.g 1200)"
                            type="number"
                        />
                        <FormField
                            isRequired
                            label="tools.popUpShopPayoutTool.shippingLabel"
                            name="shipping"
                            placeholder="Shipping (whole number e.g 1200)"
                            type="number"
                        />
                        <FormField
                            isRequired
                            label="tools.popUpShopPayoutTool.taxesFeesLabel"
                            name="taxesFees"
                            type="number"
                            placeholder="Taxes and Fees (whole number e.g 1200)"
                        />
                        <FormField
                            isRequired
                            label="tools.popUpShopPayoutTool.commissionPercentLabel"
                            name="commissionPercent"
                            type="number"
                            placeholder="Commission Percent (percentage e.g 12.375)"
                        />
                        <FormField
                            isRequired
                            label="tools.popUpShopPayoutTool.orderGrandTotalLabel"
                            name="orderGrandTotal"
                            type="number"
                            placeholder="Order Grand Total (whole number e.g 1200)"
                        />
                        <FormMessages />
                    </Modal.Body>
                    <Modal.PrimaryButton
                        onClick={() => popUpShopPayoutToolFormRef?.current?.handleSubmit()}
                        isDisabled={isSubmitting || !isValid}>
                        Create Payout
                    </Modal.PrimaryButton>
                </Modal>
            )}
        </PopUpShopPayoutToolForm>
    );
};

interface IPopUpShopPayoutToolFormProps {
    innerRef: RefObject<FormikValues>;
}

const PopUpShopPayoutToolForm: React.FC<IPopUpShopPayoutToolFormProps> = ({
    innerRef,
    ...props
}) => {
    const transferPath = usePaymentsApiMerchantAccountPath('/transfer');
    const { 2: createTransfer } = usePaymentsApiFetch(transferPath, {
        defer: true,
        method: 'post'
    });
    const handleSubmit = async (formValues, { resetForm, setErrors, setStatus }) => {
        const {
            amount,
            commissionPercent,
            contactEmail,
            contactName,
            contextTitle,
            digitalsSubtotal,
            orderGrandTotal,
            orderSubtotal,
            shipping,
            sourceId,
            sourceNumber,
            taxesFees
        } = formValues;

        setErrors({ form: null });
        setStatus(null);

        const { description, status } = await createTransfer({
            data: {
                amount: Number(amount),
                idempotencyKey: uuidv4(),
                reason: 'pop_up_shop_payout',
                transactionMetadata: {
                    app: 'shootproof',
                    brandName: "Topher's Tags",
                    commissionPercent: Number(commissionPercent),
                    contactName,
                    contactEmail,
                    contextTitle,
                    digitalsSubtotal: Number(digitalsSubtotal),
                    orderGrandTotal: Number(orderGrandTotal),
                    orderSubtotal: Number(orderSubtotal),
                    shipping: Number(shipping),
                    sourceId,
                    sourceNumber,
                    sourceType: 'pop-up-shop',
                    taxesFees: Number(taxesFees)
                },
                type: 'to-merchant-account'
            }
        });

        if (status === 201) {
            setErrors({ form: null });
            resetForm({ values: formValues });
            setTimeout(() => {
                setStatus('Succeeded');
            });
        } else {
            setErrors({ form: JSON.stringify(description) });
        }
    };
    const initialValues = {
        amount: '',
        commissionPercent: 0,
        contactEmail: '',
        contactName: '',
        contextTitle: '',
        digitalsSubtotal: '',
        orderGrandTotal: '',
        orderSubtotal: '',
        shipping: '',
        sourceId: '',
        sourceNumber: '',
        taxesFees: ''
    };
    const validationSchema = yup.object().shape({
        amount: yup.number().required().min(1).max(99999999),
        commissionPercent: yup.number().required().min(0).max(100),
        contactEmail: yup.string().required(),
        contactName: yup.string().required(),
        contextTitle: yup.string().required(),
        digitalsSubtotal: yup.number().required().min(0).max(99999999),
        orderGrandTotal: yup.number().required().min(0).max(99999999),
        orderSubtotal: yup.number().required().min(0).max(99999999),
        shipping: yup.number().required().min(0).max(99999999),
        sourceId: yup.string().required(),
        sourceNumber: yup.string().required(),
        taxesFees: yup.number().required().min(0).max(99999999)
    });

    return (
        <Form<typeof initialValues>
            initialValues={initialValues}
            // @ts-ignore
            innerRef={innerRef}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            {...props}
        />
    );
};

const StyledErrorLabel = styled(Label)(
    ({ theme }) => `
        color: ${theme.colors.red[500]};
        margin-top: 8px;
    `
);

const StyledSuccessLabel = styled(Label)(
    ({ theme }) => `
        color: ${theme.colors.green[500]};
        margin-top: 8px;
    `
);

const Tools: React.FC = () => {
    const { Modal: DestinationChargeToolModal, open: openDestinationChargeToolModal } = useModal();
    const { Modal: PopUpShopPayoutModal, open: openPopUpShopPayoutModal } = useModal();

    if (!process.env.REACT_APP_SHOW_DEV_TOOLS) {
        return <Redirect to="/" />;
    }

    return (
        <Flex flexDirection="column" padding="20px" width="240px">
            <DevButton
                marginBottom="20px"
                onClick={(event) => {
                    event.preventDefault();
                    openDestinationChargeToolModal();
                }}>
                Destination Charge
            </DevButton>
            <DevButton
                onClick={(event) => {
                    event.preventDefault();
                    openPopUpShopPayoutModal();
                }}>
                Print Store Payout
            </DevButton>
            <DestinationChargeTool modal={DestinationChargeToolModal} />
            <PopUpShopPayoutTool modal={PopUpShopPayoutModal} />
        </Flex>
    );
};

export default Tools;
