import React from 'react';
import { IBalanceTransaction } from '../types';
import { Message, useMessage } from '../../intl';

interface IBalanceTransactionTypeProps {
    balanceTransaction: IBalanceTransaction;
}

const BalanceTransactionType: React.FC<IBalanceTransactionTypeProps> = ({ balanceTransaction }) => {
    const { metadata, type } = balanceTransaction;
    const {
        internalAssociatedTransactionApp = 'shootproof',
        internalAssociatedTransactionId,
        internalAssociatedTransactionSourceNumber: sourceNumber = '',
        internalAssociatedTransactionSourceType = 'invoice'
    } = metadata;
    const { [internalAssociatedTransactionApp]: app } = { shootproof: 'ShootProof', tave: 'Táve' };
    const { [internalAssociatedTransactionSourceType]: sourceType } = {
        invoice: 'transaction.source.invoice',
        order: 'transaction.source.order',
        'pop-up-shop': 'payoutDetails.balanceTransactionType.popUpShopPayout'
    };
    const t = useMessage();
    const typeTranslation = {
        dispute: 'payoutDetails.balanceTransactionType.dispute',
        dispute_fee: 'payoutDetails.balanceTransactionType.disputeFee',
        dispute_reversal: 'payoutDetails.balanceTransactionType.disputeReversal',
        failed_payout: 'payoutDetails.balanceTransactionType.failedPayout',
        instant_payout: 'payoutDetails.balanceTransactionType.instantPayout',
        instant_payout_fee: 'payoutDetails.balanceTransactionType.instantPayoutFee',
        lab_expense: 'payoutDetails.balanceTransactionType.labExpense',
        manual_payout: 'payoutDetails.balanceTransactionType.manualPayout',
        other: 'payoutDetails.balanceTransactionType.other',
        payment: 'payoutDetails.balanceTransactionType.payment',
        payment_refund: 'transaction.refund',
        refund_of_processing_fee: 'payoutDetails.balanceTransactionType.refundOfProcessingFee',
        support_adjustment: 'payoutDetails.balanceTransactionType.supportAdjustment'
    }[type];

    return internalAssociatedTransactionSourceType === 'pop-up-shop' ? (
        <Message id={sourceType} />
    ) : internalAssociatedTransactionId ? (
        <Message
            id="payoutDetails.balanceTransactionType.transaction"
            values={{
                app,
                sourceNumber,
                sourceType: t(sourceType),
                type: t(typeTranslation)
            }}
        />
    ) : (
        <Message id={typeTranslation} />
    );
};

export default BalanceTransactionType;
