import React, { useEffect, useRef, useState } from 'react';
import { A, Box, ButtonVariant, Flex, H3, P, styled } from 'sp-ui';
import { ReactComponent as DebitCardImage } from '../../../assets/svg/debit-card.svg';
import BalanceTransactionCards from './BalanceTransactionCards';
import { IBalanceTransaction } from '../types';
import { Button } from '../../form';
import { Message, useMessage } from '../../intl';
import { DetailsViewContent } from '../../../layouts/DetailsView';
import { usePaymentsApiFetch, usePaymentsApiMerchantAccountPath } from '../../../hooks';

export const BalanceTransactionsDetailsViewContent = styled(DetailsViewContent)`
    padding: 0;
    width: 100%;
`;

const InstantPayoutBalanceTransactionsEmptyState = styled(Flex)`
    flex-direction: column;

    svg {
        flex: 0 0 60px;
        height: 60px;
        margin-bottom: 18px;
    }
`;

interface IPayoutDetailsBalanceTransactionsProps {
    balanceTransactions?: IBalanceTransaction[];
    currency: string;
    isInstantPayout: boolean;
    isVisible?: boolean;
    onLoaded?: (hasLoaded: boolean) => void;
    payoutId?: string;
    showHeading?: boolean;
}

const PayoutDetailsBalanceTransactions: React.FC<IPayoutDetailsBalanceTransactionsProps> = ({
    balanceTransactions: initialBalanceTransactions,
    currency,
    isInstantPayout,
    isVisible = true,
    onLoaded = () => {},
    payoutId = '',
    showHeading = true
}) => {
    const [balanceTransactions, setBalanceTransactions] = useState<IBalanceTransaction[]>(
        initialBalanceTransactions || []
    );
    const balanceTransactionsPath = usePaymentsApiMerchantAccountPath('/balance-transaction');
    const defaultLimit = 10;
    const [pagingLimit, setPagingLimit] = useState<number>(defaultLimit);
    const { 0: balanceTransactionsResponse, 2: getBalanceTransactions } = usePaymentsApiFetch(
        `${balanceTransactionsPath}?payout_id=${payoutId}&limit=${pagingLimit}`,
        {
            defer: true
        }
    );

    const [hideShowMore, setHideShowMore] = useState<boolean>(true);
    const incrementLimitBy = 10;
    const [loadingMore, setLoadingMore] = useState<boolean>(false);
    const loadMore = () => {
        setPagingLimit(pagingLimit + incrementLimitBy);
        setLoadingMore(true);

        return new Promise<void>((resolve) => {
            setTimeout(() => {
                resolveLoadMore.current = resolve;
            });
        });
    };
    const resolveLoadMore = useRef<(() => void) | null>();
    const t = useMessage();

    useEffect(() => {
        if (balanceTransactionsResponse) {
            const { data: balanceTransactions } = balanceTransactionsResponse;
            const filteredBalanceTransactions = balanceTransactions.filter(
                ({ type }) => type !== 'automatic_payout'
            );

            onLoaded(true);
            setHideShowMore(balanceTransactions.length < pagingLimit);
            setBalanceTransactions(filteredBalanceTransactions);

            if (loadingMore && resolveLoadMore.current) {
                resolveLoadMore.current();

                resolveLoadMore.current = null;

                setLoadingMore(false);
            }
        }
    }, [loadingMore, onLoaded, pagingLimit, balanceTransactionsResponse]);

    useEffect(() => {
        if (isInstantPayout) {
            onLoaded(true);
        } else if (!initialBalanceTransactions) {
            getBalanceTransactions();
        }
    }, [initialBalanceTransactions, getBalanceTransactions, isInstantPayout, onLoaded]);

    return isInstantPayout ? (
        <BalanceTransactionsDetailsViewContent data-testid="payout-transactions-list">
            {showHeading && (
                <TransactionDetailsViewHeading>
                    <H3>
                        <Message id="payoutDetails.balanceTransactionsHeading" />
                    </H3>
                </TransactionDetailsViewHeading>
            )}
            <InstantPayoutBalanceTransactionsEmptyState>
                <DebitCardImage />
                <P textAlign="center" padding="0 24px 10px">
                    <Message id="payoutDetails.balanceTransactionsEmptyMessage" />
                </P>
            </InstantPayoutBalanceTransactionsEmptyState>
        </BalanceTransactionsDetailsViewContent>
    ) : (
        <Box
            height={isVisible ? 'initial' : 0}
            overflow={isVisible ? 'initial' : 'hidden'}
            visibility={isVisible ? 'visible' : 'hidden'}>
            <BalanceTransactionsDetailsViewContent data-testid="payout-transactions-list">
                {showHeading && (
                    <TransactionDetailsViewHeading>
                        <H3>
                            <Message id="payoutDetails.balanceTransactionsHeading" />
                        </H3>
                    </TransactionDetailsViewHeading>
                )}
                <BalanceTransactionCards
                    balanceTransactions={balanceTransactions}
                    currency={currency}
                />
            </BalanceTransactionsDetailsViewContent>
            <ShowMoreBalanceTransactionsButtonContainer
                visibility={!hideShowMore || loadingMore ? 'visible' : 'hidden'}>
                <Button
                    loadingText={t('payoutDetails.balanceTransactionsShowMoreButtonLoading')}
                    onClick={loadMore}
                    variant={ButtonVariant.Dismissive}>
                    <A>
                        <Message id="payoutDetails.balanceTransactionsShowMoreButton" />
                    </A>
                </Button>
            </ShowMoreBalanceTransactionsButtonContainer>
        </Box>
    );
};

export const ShowMoreBalanceTransactionsButtonContainer = styled(Flex)(
    ({ theme }) => `
        justify-content: center;
        margin-bottom: 20px;

        a {
            color: ${theme.colors.blue[400]};
        }

        button {
            transition: visibility 0s;
        }
    `
);

const TransactionDetailsViewHeading = styled(Flex)`
    align-items: center;
    justify-content: space-between;
    padding: 21px 24px 0;
    margin-bottom: 15px;
`;

export default PayoutDetailsBalanceTransactions;
