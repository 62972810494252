import { parse } from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { A, Box, LoadingDialog, Span, styled, useModal } from 'sp-ui';
import { ReactComponent as PayoutEmptyStateImage } from '../assets/svg/payout-empty-state-image.svg';
import { AvailableInstantBalanceTile, Balance, useBalance } from '../components/balance';
import { ButtonLink, Label } from '../components/form';
import { PayoutSchedule, useMerchantAccount } from '../components/merchant-account';
import {
    InstantPayoutAction,
    IPayout,
    PayoutDetails,
    PayoutStatus,
    PayoutStatusPill
} from '../components/payout';
import {
    NewPayoutAccountModal,
    PayoutAccount,
    usePayoutAccount
} from '../components/payout-account';
import { Permission, usePermissions, withPermission } from '../components/permissions';
import EmptyState from '../components/EmptyState';
import HelpLink from '../components/HelpLink';
import Table from '../components/Table';
import { Currency, Date as FormattedDate, Message, useMessage } from '../components/intl';
import ListWithDetailsView from '../layouts/ListWithDetailsView';
import { getStartOfDayFromThreeMonthsAgo, getStartOfDayToday } from '../date';
import { usePaymentsApiFetch, usePaymentsApiMerchantAccountPath } from '../hooks';
import { convertLowerCamelToSnake } from '../string';
import withPaymentsApiList from '../components/withPaymentsApiList';

const ArrivalDateCardFieldSpan = styled(Span)(
    ({ theme }) => `
        font-size: ${theme.fontSizes.sm};
        line-height: ${theme.lineHeights.sm};
    `
);

export const ButtonLinkWithReadPermission = withPermission(Permission.Read, ButtonLink);

interface INoAvailableInstantPayoutEmptyStateProps {
    learnMoreLink: React.ReactNode;
}

export const NoAvailableInstantPayoutEmptyState: React.FC<INoAvailableInstantPayoutEmptyStateProps> = ({
    learnMoreLink
}) => {
    const { Modal, close, open } = useModal();
    const { payoutAccounts } = usePayoutAccount();
    const hasPayoutAccount = payoutAccounts.length > 0;
    const { merchantAccount } = useMerchantAccount();
    const { id } = merchantAccount;
    const payoutEmptyStateImage = (
        <Box maxWidth="398px" width="398px">
            <PayoutEmptyStateImage />
        </Box>
    );

    return hasPayoutAccount ? (
        <EmptyState
            description={<Message id="payoutEmptyState.description" />}
            heading="payoutEmptyState.heading"
            image={payoutEmptyStateImage}>
            <ButtonLinkWithReadPermission
                message="payoutEmptyState.payoutSettingsLink"
                to={`/payment-account/${id}/settings/payout`}
            />
            {learnMoreLink}
        </EmptyState>
    ) : (
        <EmptyState
            buttonOnClick={open}
            buttonText="payoutEmptyState.hasNoPayoutAccount.button"
            description={<Message id="payoutEmptyState.hasNoPayoutAccount.description" />}
            heading="payoutEmptyState.hasNoPayoutAccount.heading"
            image={payoutEmptyStateImage}>
            {learnMoreLink}
            <NewPayoutAccountModal close={close} modalComponent={Modal} />
        </EmptyState>
    );
};

interface IPayoutEmptyStateProps {
    onInstantPayoutCreated: () => void;
}

export const PayoutEmptyState: React.FC<IPayoutEmptyStateProps> = ({ onInstantPayoutCreated }) => {
    const { balance, loading } = useBalance();
    const { availableInstant } = balance;
    const hasAvailableInstantBalance = availableInstant && availableInstant > 0;
    const { hasPermission } = usePermissions();
    const learnMoreLink = (
        <Box display="inline-block" marginTop="16px">
            <HelpLink content="payoutEmptyState.learnMoreLink" path="/articles/360061561054" />
        </Box>
    );

    if (loading) {
        return null;
    }

    return hasPermission(Permission.Admin) && hasAvailableInstantBalance ? (
        <EmptyState
            description={<Message id="payoutEmptyState.hasAvailableInstantBalance.description" />}
            heading="payoutEmptyState.hasAvailableInstantBalance.heading"
            image={<AvailableInstantBalanceTile hideImage />}>
            <InstantPayoutAction
                justifyContent="center"
                onInstantPayoutCreated={onInstantPayoutCreated}
            />
            {learnMoreLink}
        </EmptyState>
    ) : (
        <NoAvailableInstantPayoutEmptyState learnMoreLink={learnMoreLink} />
    );
};

interface IPayoutFromQueryStringProps {
    whenPresent: (payout: IPayout) => void;
}

export const PayoutFromQueryString: React.FC<IPayoutFromQueryStringProps> = ({ whenPresent }) => {
    const { listen } = useHistory();
    const { search } = useLocation();
    const { payoutId } = parse(search);
    const payoutPath = usePaymentsApiMerchantAccountPath(`/payout/${payoutId}`);
    const { 0: payoutResponse, 2: getPayout } = usePaymentsApiFetch(payoutPath, {
        defer: true
    });

    useEffect(() => {
        const unlisten = listen(() => {
            setTimeout(() => {
                if (isMounted && payoutId) {
                    getPayout();
                }
            });
        });
        let isMounted = true;

        return () => {
            isMounted = false;

            unlisten();
        };
    }, [listen, getPayout, payoutId]);

    useEffect(() => {
        if (payoutId) {
            getPayout();
        }
    }, [getPayout, payoutId]);

    useEffect(() => {
        if (payoutResponse?.data) {
            const { data: payout } = payoutResponse;

            whenPresent(payout);
        }
    }, [payoutResponse, whenPresent]);

    return null;
};

const PayoutPage: React.FC = withPaymentsApiList(
    {
        initialCreatedDateRange: [getStartOfDayFromThreeMonthsAgo(), getStartOfDayToday()],
        listPath: '/payout'
    },
    ({
        getList: getPayouts,
        initialCreatedDateRange,
        initialSearch,
        isZeroState,
        list: payouts,
        loading,
        setCreatedDateRange,
        setFilter,
        setPageIndex,
        setSearch,
        setSort,
        pageCount
    }) => {
        const ArrivalDateCardField = ({ arrivalDate, status }) => {
            const arrivalDateStatusTranslation = {
                [PayoutStatus.Canceled]: 'payoutTableCardField.arrivalDate.statusCanceled',
                [PayoutStatus.Failed]: 'payoutTableCardField.arrivalDate.statusFailed',
                [PayoutStatus.InTransit]: 'payoutTableCardField.arrivalDate.statusPending',
                [PayoutStatus.Paid]: 'payoutTableCardField.arrivalDate.statusPaid',
                [PayoutStatus.Pending]: 'payoutTableCardField.arrivalDate.statusPending',
                [PayoutStatus.Withdrawn]: 'payoutTableCardField.arrivalDate.statusWithdrawn'
            }[status];

            return (
                <ArrivalDateCardFieldSpan>
                    <Message id={arrivalDateStatusTranslation} />{' '}
                    <FormattedDate excludeYear ignoreTime shortFormat value={arrivalDate} />
                </ArrivalDateCardFieldSpan>
            );
        };
        const AmountCardField = ({ amount, currency }) => (
            <Label>
                <Currency currency={currency} value={amount / 100} />
            </Label>
        );
        const t = useMessage();
        const checklistFilterOptions = [
            {
                label: t('payoutStatus.canceled'),
                value: PayoutStatus.Canceled
            },
            {
                label: t('payoutStatus.failed'),
                value: PayoutStatus.Failed
            },
            {
                label: t('payoutStatus.inTransit'),
                value: PayoutStatus.InTransit
            },
            {
                label: t('payoutStatus.paid'),
                value: PayoutStatus.Paid
            },
            {
                label: t('payoutStatus.pending'),
                value: PayoutStatus.Pending
            },
            {
                label: t('payoutStatus.withdrawn'),
                value: PayoutStatus.Withdrawn
            }
        ];
        const columns = [
            {
                Cell: ({ value }) => <FormattedDate shortFormat value={value} />,
                Header: <Message id="payoutTableHeader.created" />,
                id: 'created'
            },
            {
                Cell: ({ value }) => <PayoutAccount payoutAccount={value} />,
                Header: <Message id="payoutTableHeader.bankCard" />,
                disableSortBy: true,
                id: 'payoutAccountResource'
            },
            {
                Cell: ({ value }) => <PayoutStatusPill payout={value} />,
                Header: <Message id="payoutTableHeader.status" />,
                alignment: 'center',
                disableSortBy: true,
                getCellValue: (payout) => payout,
                id: 'status'
            },
            {
                Cell: ({ value }) => <FormattedDate ignoreTime shortFormat value={value} />,
                Header: <Message id="payoutTableHeader.arrivalDate" />,
                id: 'arrivalDate'
            },
            {
                Cell: ({ value }) => {
                    const { amount, currency } = value;

                    return <Currency currency={currency} value={amount / 100} />;
                },
                Header: <Message id="payoutTableHeader.amount" />,
                alignment: 'right',
                getCellValue: ({ amount, currency }) => ({ amount, currency }),
                id: 'amount'
            },
            {
                Cell: ({ value }) => (
                    <A data-testid="see-details-action" onClick={() => setPayout(value)}>
                        <Message id="listViewTableActions.view" />
                    </A>
                ),
                Header: '',
                alignment: 'right',
                disableSortBy: true,
                getCellValue: (payout) => payout,
                id: 'actions'
            }
        ];
        const { merchantAccount } = useMerchantAccount();
        const { id: merchantId } = merchantAccount;
        const [currentMerchantId, setCurrentMerchantId] = useState<string>(merchantId);
        const [payout, setPayout] = useState<IPayout | null>(null);
        const details = payout && <PayoutDetails payout={payout} />;
        const [hasLoadedPayouts, setHasLoadedPayouts] = useState<boolean>(false);
        const onChecklistFilterApply = useCallback(
            async (selectedValues, doneApplyingChecklistFilter) => {
                const filterParams = selectedValues.map(({ value }) => ['filter_status', value]);

                await setFilter(filterParams);
                doneApplyingChecklistFilter();
            },
            [setFilter]
        );
        const onPagination = useCallback(
            async ({ pageIndex }, donePaginating) => {
                await setPageIndex(pageIndex);
                donePaginating();
            },
            [setPageIndex]
        );
        const onSort = useCallback(
            async ([nextSort], doneSorting) => {
                if (nextSort) {
                    const { desc, id } = nextSort;
                    const by = convertLowerCamelToSnake(id);

                    await setSort({ by, direction: desc ? 'desc' : 'asc' });
                } else {
                    await setSort({ by: 'created', direction: 'desc' });
                }

                doneSorting();
            },
            [setSort]
        );

        useEffect(() => {
            if (!loading && !hasLoadedPayouts) {
                setHasLoadedPayouts(true);
            }
        }, [hasLoadedPayouts, loading]);

        useEffect(() => {
            if (loading) {
                if (currentMerchantId !== merchantId) {
                    setCurrentMerchantId(merchantId);
                    setHasLoadedPayouts(false);
                }
            }
        }, [currentMerchantId, setHasLoadedPayouts, loading, merchantId]);

        return (
            <ListWithDetailsView
                details={details}
                heading={t('payoutList.heading')}
                onDetailsClose={() => setPayout(null)}>
                <PayoutSchedule data-testid="list-view-subtext" marginBottom="20px" />
                {!hasLoadedPayouts ? (
                    <LoadingDialog description="" />
                ) : isZeroState ? (
                    <PayoutEmptyState onInstantPayoutCreated={getPayouts} />
                ) : (
                    <>
                        <Balance
                            marginBottom="24px"
                            onInstantPayoutCreated={getPayouts}
                            showFuturePayout
                            showInTransitBalance
                        />
                        <Table
                            cardFields={[
                                'payoutAccountResource',
                                AmountCardField,
                                ArrivalDateCardField,
                                'status'
                            ]}
                            checklistFilterOptions={checklistFilterOptions}
                            checklistFilterPlaceholder={t(
                                'payout.table.checklistFilterPlaceholder'
                            )}
                            columns={columns}
                            data={payouts || []}
                            enableChecklistFilter
                            enablePagination
                            initialDateRangeFilterValue={initialCreatedDateRange}
                            initialSearchValue={initialSearch}
                            isRowSelected={({ id }) => id === payout?.id}
                            isStickyHeader
                            onCardClick={setPayout}
                            onChecklistFilterApply={onChecklistFilterApply}
                            onDateRangeFilterApplied={async (dateRange, doneApplyingDateRange) => {
                                await setCreatedDateRange(dateRange);
                                doneApplyingDateRange();
                            }}
                            onPagination={onPagination}
                            onSearch={async (search, doneSearching) => {
                                await setSearch(search);
                                doneSearching();
                            }}
                            onSearchClear={async (doneClearing) => {
                                await setSearch('');
                                doneClearing();
                            }}
                            onSort={onSort}
                            pageCount={pageCount}
                            searchPlaceholder={t('payout.table.searchPlaceholder')}
                        />
                        <PayoutFromQueryString whenPresent={setPayout} />
                    </>
                )}
            </ListWithDetailsView>
        );
    }
);

export default PayoutPage;
