import React from 'react';
import { Flex, H3, P } from 'sp-ui';
import { Message } from '../intl';
import { usePayout } from '../payout';
import { PayoutDetailsViewProvider, PayoutDetailsBalanceTransactions } from '../payout/details';

interface IBalanceTransactionsDetailsProps {}

const BalanceTransactionsDetails: React.FC<IBalanceTransactionsDetailsProps> = () => {
    const { nextPayoutBalanceTransactions } = usePayout();

    return (
        <Flex data-testid="balance-transactions-details" flexDirection="column">
            <PayoutDetailsViewProvider backToPayoutText="balance.balanceTransactionsDetails.payoutDetailsViewProvider.backToPayout">
                <H3 marginBottom="24px">
                    <Message id="balance.balanceTransactionsDetails.heading" />
                </H3>
                <P marginBottom="18px">
                    <Message id="balance.balanceTransactionsDetails.description" />
                </P>
                <PayoutDetailsBalanceTransactions
                    balanceTransactions={nextPayoutBalanceTransactions}
                    currency={nextPayoutBalanceTransactions[0].currency}
                    isInstantPayout={false}
                    showHeading={false}
                />
            </PayoutDetailsViewProvider>
        </Flex>
    );
};

export default BalanceTransactionsDetails;
