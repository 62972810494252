import React from 'react';
import { Banner, BannerVariant, Box, Flex, Span, styled, useFormikContext } from 'sp-ui';
import { PayoutMethod } from './common';
import { useBalance } from '../balance';
import { IPayoutAccount, usePayoutAccount } from '../payout-account';
import { Label } from '../form';
import { Strong } from '../text';
import { Currency, Message } from '../intl';
import { getDisplayBrand } from '../../services/card';
import { usePaymentsApiFetch, usePaymentsApiMerchantAccountPath } from '../../hooks';
import { fromDollarsToCents } from '../../number';

const Hr = styled.hr(
    ({ theme }) => `
        background: ${theme.colors.gray[200]};
        border: none;
        height: 1px;
        margin-bottom: 12px;
        margin-top: 12px;
    `
);

export const InstantPayoutPreview: React.FC = () => {
    const {
        balance: { available }
    } = useBalance();
    const { payoutAccounts } = usePayoutAccount();
    const { values } = useFormikContext<{ amount: number; payoutAccountId: string }>();
    const { amount, payoutAccountId } = values;
    const payoutPreviewPath = usePaymentsApiMerchantAccountPath('/payout/preview');
    const [payoutPreviewResponse, payoutPreviewLoading] = usePaymentsApiFetch(payoutPreviewPath, {
        method: 'post',
        data: {
            amount: fromDollarsToCents(amount),
            method: PayoutMethod.Instant,
            payoutAccount: payoutAccountId
        }
    });
    const { cardBrand, last4 } = payoutAccounts.find(
        ({ id }) => id === payoutAccountId
    ) as IPayoutAccount;
    const {
        currency,
        amount: payoutAmount,
        feeAmount,
        feeRate,
        netAmount: payoutNetAmount
    } = payoutPreviewResponse?.data || { feeRate: 0 };
    const showInsufficientAvailableBalanceBanner = feeAmount > Number(available);

    return (
        <Box data-testid="instant-payout-preview">
            <Label marginBottom="18px">
                <Message id="instantPayout.modal.previewSuperHeading" />
            </Label>
            <InstantPayoutPreviewField>
                <Span>
                    <Message id="instantPayout.modal.previewCardLabel" />
                </Span>
                <Span>
                    <Message
                        id="instantPayout.modal.previewCardMask"
                        values={{ brand: getDisplayBrand(cardBrand), last4 }}
                    />
                </Span>
            </InstantPayoutPreviewField>
            <InstantPayoutPreviewField>
                <Span>
                    <Message id="instantPayout.modal.previewTransferAmountLabel" />
                </Span>
                <Span>
                    {!payoutPreviewLoading && (
                        <Currency currency={currency} value={payoutAmount / 100} />
                    )}
                </Span>
            </InstantPayoutPreviewField>
            <InstantPayoutPreviewField>
                <Span>
                    <Message
                        id="instantPayout.modal.previewFeeLabel"
                        values={{ feeRate: Number(feeRate) * 100 }}
                    />
                </Span>
                <Span>
                    {!payoutPreviewLoading && (
                        <Currency currency={currency} value={-feeAmount / 100} />
                    )}
                </Span>
            </InstantPayoutPreviewField>
            <Hr />
            <InstantPayoutPreviewField>
                <Span>
                    <Message id="instantPayout.modal.previewTotalLabel" />
                </Span>
                <Strong>
                    {!payoutPreviewLoading && (
                        <Currency currency={currency} value={payoutNetAmount / 100} />
                    )}
                </Strong>
            </InstantPayoutPreviewField>
            {showInsufficientAvailableBalanceBanner && (
                <Banner variant={BannerVariant.Warning}>
                    <Span>
                        <Message id="instantPayout.modal.insufficientAvailableBalanceBanner" />
                    </Span>
                </Banner>
            )}
        </Box>
    );
};

export const InstantPayoutPreviewField = styled(Flex)(
    ({ theme }) => `
        justify-content: space-between;
        margin-bottom: 8px;

        * {
            color: ${theme.colors.gray[700]};
        }
    `
);

export default InstantPayoutPreview;
