import React from 'react';
import { A, Box } from 'sp-ui';
import { ITransactionMetadata } from './types';
import { useMessage } from '../intl';

interface ITransactionSourceProps {
    collapseSource?: boolean;
    transactionMetadata: ITransactionMetadata;
}

const TransactionSource: React.FC<ITransactionSourceProps> = ({
    collapseSource,
    transactionMetadata
}) => {
    const { app, sourceId, sourceNumber, sourceType } = transactionMetadata;
    const displaySourceId = sourceNumber || sourceId;
    const { [app]: appName } = { tave: 'Táve', shootproof: 'ShootProof' };
    const { [sourceType]: source } = {
        invoice: 'transaction.source.invoice',
        order: 'transaction.source.order',
        'pop-up-shop': 'transaction.source.popUpShop'
    };
    const isPopUpShopSourceType = sourceType === 'pop-up-shop';
    const t = useMessage();
    const sourceHref = (
        {
            'shootproof.invoice': `${process.env.REACT_APP_TRANSACTION_SOURCE_SP_INVOICE_URL}`,
            'shootproof.order': `${process.env.REACT_APP_TRANSACTION_SOURCE_SP_ORDER_URL}`,
            'shootproof.pop-up-shop': `${process.env.REACT_APP_TRANSACTION_SOURCE_SP_ORDER_URL}`,
            'tave.invoice': `${process.env.REACT_APP_TRANSACTION_SOURCE_TAVE_INVOICE_URL}`,
            'tave.order': ''
        }[`${app}.${sourceType}`] || ''
    ).replace(':id', sourceId);
    const sourceLink = (
        <A href={sourceHref} target="_blank">
            {collapseSource ? `${t(source)} – ${displaySourceId}` : displaySourceId}
        </A>
    );

    return collapseSource ? (
        sourceLink
    ) : (
        <Box>
            {sourceLink}
            <br />
            {!collapseSource && `${isPopUpShopSourceType ? t(source) : `${appName} ${t(source)}`}`}
        </Box>
    );
};

export default TransactionSource;
