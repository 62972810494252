import React from 'react';
import { useModal } from 'sp-ui';
import { DevButton } from '../form';
import RefundModal from './RefundModal';
import { ITransaction } from './types';
import { Message } from '../intl';
import { isRefundable } from '../../services/transaction';

interface IRefundActionProps {
    onRefunded: (object) => void;
    transaction: ITransaction;
}

const RefundAction: React.FC<IRefundActionProps> = ({ onRefunded, transaction }) => {
    const { Modal, close, open } = useModal();

    return isRefundable(transaction) ? (
        <>
            <DevButton onClick={open}>
                <Message id="transaction.refundTransaction" />
            </DevButton>
            <RefundModal
                close={close}
                modalComponent={Modal}
                onRefunded={onRefunded}
                transaction={transaction}
            />
        </>
    ) : null;
};

export default RefundAction;
