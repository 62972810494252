import { Image, ImageProps } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Box, BoxProps, ButtonVariant, Flex, LoadingDialog, P, Span, styled } from 'sp-ui';
import { ReactComponent as BankImage } from '../../assets/svg/bank.svg';
import { ReactComponent as BankPendingImage } from '../../assets/svg/bank-pending.svg';
import { ReactComponent as ClockImage } from '../../assets/svg/clock.svg';
import { Button } from '../form';
import { IBankAccount } from './types';
import PayoutAccountNewBankAccountButton from './NewBankAccountButton';
import { Message, useMessage } from '../intl';
import { usePaymentsApiFetch } from '../../hooks';

export const AutomaticVerificationCancelButton = styled(Button)(
    ({ theme }) => `
        border: 1px solid ${theme.colors.gray[200]};
        color: ${theme.colors.blue[400]};
        margin-top: 20px;
        padding: 8px 70px;
        
        &:hover {
            border-color: ${theme.colors.gray[300]};
            color: ${theme.colors.blue[700]};
        }
    `
);

interface IBankAccountProps {
    bankAccount: IBankAccount;
    onCancel: (plaidPendingBankAccountId) => void;
    onReauthenticated: () => void;
    onVerified: (bankAccount: IBankAccount) => void;
}

const BankAccount: React.FC<IBankAccountProps> = ({
    bankAccount,
    onCancel,
    onReauthenticated,
    onVerified
}) => {
    const {
        accountName,
        accountNumberMask,
        institutionId,
        plaidPendingBankAccountId,
        routingNumber,
        verificationStatus,
        wasMicrodepositVerified = false
    } = bankAccount;
    const awaitingAutomaticVerification = verificationStatus === 'pending_automatic_verification';
    const [hasVerifiedWithPlaid, setHasVerifiedWithPlaid] = useState<boolean>(false);
    const isManuallyVerified = verificationStatus === 'manually_verified';
    const needsManualVerification = verificationStatus === 'pending_manual_verification';
    const needsReauthentication = verificationStatus === 'needs_reauthentication';
    const t = useMessage();
    const { 2: updatePlaidPendingBankAccount } = usePaymentsApiFetch(
        `/plaid/link-token/pending-bank-account/${plaidPendingBankAccountId}`,
        {
            defer: true,
            method: 'put'
        }
    );

    return verificationStatus ? (
        <>
            <BankAccountNeedsVerificationBox isHidden={hasVerifiedWithPlaid}>
                <ClockImage />
                {awaitingAutomaticVerification && (
                    <Box>
                        <P>
                            <Message id="payoutAccount.bankAccount.awaitingAutomaticVerificationDescription" />
                        </P>
                        <AutomaticVerificationCancelButton
                            message="payoutAccount.pendingBankAccount.cancel"
                            onClick={() => onCancel(plaidPendingBankAccountId)}
                            variant={ButtonVariant.Tertiary}
                        />
                    </Box>
                )}
                {isManuallyVerified && (
                    <Box>
                        <P marginBottom="10px">
                            <Message id="payoutAccount.bankAccount.manuallyVerifiedDescription" />
                        </P>
                        <Button
                            loadingText={t(
                                'payoutAccount.bankAccount.addBankAccountButtonLoadingText'
                            )}
                            onClick={async () => {
                                await updatePlaidPendingBankAccount();
                                onVerified({
                                    accountName: t(
                                        'payoutAccount.bankAccount.temporaryVerifiedAccountName'
                                    ),
                                    accountNumberMask,
                                    verificationStatus: null,
                                    wasMicrodepositVerified: true
                                });
                            }}>
                            <Message id="payoutAccount.bankAccount.addBankAccountButton" />
                        </Button>
                    </Box>
                )}
                {plaidPendingBankAccountId && (
                    <>
                        {needsManualVerification && (
                            <Box>
                                <P marginBottom="10px">
                                    <Message id="payoutAccount.bankAccount.needsVerificationDescription" />
                                </P>
                                <PayoutAccountNewBankAccountButton
                                    onCreated={({ accountName, accountNumberMask }) => {
                                        onVerified({
                                            accountName: (accountName || '').split(' ')[0],
                                            accountNumberMask,
                                            wasMicrodepositVerified: true
                                        });
                                    }}
                                    onPlaidCreated={() => setHasVerifiedWithPlaid(true)}
                                    plaidPendingBankAccountId={plaidPendingBankAccountId}>
                                    <Message id="payoutAccount.bankAccount.verifyBankAccountButton" />
                                </PayoutAccountNewBankAccountButton>
                                <Button
                                    marginLeft="30px"
                                    message="payoutAccount.pendingBankAccount.cancel"
                                    onClick={() => onCancel(plaidPendingBankAccountId)}
                                    variant={ButtonVariant.Dismissive}
                                />
                            </Box>
                        )}
                        {needsReauthentication && (
                            <Box>
                                <P marginBottom="10px">
                                    <Message id="payoutAccount.bankAccount.needsReauthenticationDescription" />
                                </P>
                                <PayoutAccountNewBankAccountButton
                                    isReauthenticating
                                    onPlaidReauthenticated={onReauthenticated}
                                    plaidPendingBankAccountId={plaidPendingBankAccountId}>
                                    <Message id="payoutAccount.bankAccount.reauthenticateBankAccountButton" />
                                </PayoutAccountNewBankAccountButton>
                                <Button
                                    marginLeft="30px"
                                    message="payoutAccount.pendingBankAccount.cancel"
                                    onClick={() => onCancel(plaidPendingBankAccountId)}
                                    variant={ButtonVariant.Dismissive}
                                />
                            </Box>
                        )}
                    </>
                )}
            </BankAccountNeedsVerificationBox>
            {hasVerifiedWithPlaid && <LoadingDialog description="" />}
        </>
    ) : (
        <BankAccountBox>
            <BankAccountImage
                accountNumberMask={accountNumberMask}
                institutionId={institutionId ? institutionId : undefined}
                institutionName={institutionId ? undefined : accountName}
                isPending={wasMicrodepositVerified}
                routingNumber={routingNumber}
            />
            {accountName && (
                <BankAccountDetails
                    accountName={accountName}
                    accountNumberMask={accountNumberMask}
                />
            )}
        </BankAccountBox>
    );
};

export const BankAccountBox = styled(Flex)(
    ({ theme }) => `
        align-items: center;
        border: 1px solid ${theme.colors.gray[200]};
        padding: 16px;

        p {
            line-height: 24px;
        }

        ${theme.responsive.smallScreenOnly`
            img {
                margin-right: 16px;
            }
        `}
    `
);

interface IBankAccountDetailsProps {
    accountName: string;
    accountNumberMask: string;
}

export const BankAccountDetails: React.FC<IBankAccountDetailsProps> = ({
    accountName,
    accountNumberMask
}) => (
    <Box flex="1">
        <P>{accountName}</P>
        <Span>{`•••• ${accountNumberMask}`}</Span>
    </Box>
);

interface IBankAccountImageProps {
    accountNumberMask?: string;
    institutionId?: string;
    institutionName?: string;
    isPending?: boolean;
    routingNumber?: string;
}

export const BankAccountImage: React.FC<BoxProps & IBankAccountImageProps> = ({
    accountNumberMask,
    institutionId,
    institutionName,
    isPending = false,
    routingNumber,
    ...props
}) => {
    const { 0: institutionResponse, 2: postPlaidInstitution } = usePaymentsApiFetch(
        '/plaid/institution',
        {
            defer: true,
            method: 'post'
        }
    );
    const { data: institution } = institutionResponse || { data: null };

    useEffect(() => {
        if (!isPending) {
            postPlaidInstitution({
                data: {
                    institutionId,
                    institutionName,
                    routingNumber
                }
            });
        }
    }, [institutionName, institutionId, isPending, postPlaidInstitution, routingNumber]);

    return institutionResponse || isPending ? (
        <>
            {institution ? (
                <Image
                    marginRight="20px"
                    width="60px"
                    src={`data:image/png;base64, ${institution.logo}`}
                    {...(props as ImageProps)}
                />
            ) : (
                <Box marginRight="20px" width="60px" {...props}>
                    {isPending ? <BankPendingImage /> : <BankImage />}
                </Box>
            )}
            {accountNumberMask && routingNumber && (
                <BankAccountDetails
                    accountName={institution ? institution.name : ''}
                    accountNumberMask={accountNumberMask}
                />
            )}
        </>
    ) : (
        <BankAccountImageLoadingDialogContainer marginRight="20px" width="60px" {...props}>
            <LoadingDialog description="" />
        </BankAccountImageLoadingDialogContainer>
    );
};

const BankAccountImageLoadingDialogContainer = styled(Box)`
    svg {
        padding: 0;
        width: 100%;
    }
`;

export const BankAccountNeedsVerificationBox = styled(Flex, {
    shouldForwardProp: (prop) => prop !== 'isHidden'
})(
    ({ isHidden, theme }) => `
        align-items: center;
        border: 1px solid ${theme.colors.gray[200]};
        padding: 30px 20px 24px;

        ${isHidden ? 'display: none;' : ''};

        p {
            color: ${theme.colors.gray[600]};
            flex: 1;
            line-height: 24px;
        }

        svg {
            flex: 0 0 100px;
            height: 100px;
            margin-right: 12px;
        }

        ${theme.responsive.smallScreenOnly`
            flex-direction: column;
            padding: 16px;

            button {
                width: 100%;
            }

            p {
                text-align: center;
            }

            svg {
                margin-bottom: 22px;
                margin-right: 0;
            }
        `}
    `
);

export default BankAccount;
