import React from 'react';
import { Permission, usePermissions } from './Context';

const withPermission = <T extends {}>(
    permission: Permission,
    Component: React.ComponentType<T>
): React.FC<T> => {
    return (props) => {
        const { hasPermission } = usePermissions();

        return hasPermission(permission) ? <Component {...props} /> : null;
    };
};

export default withPermission;
