import React from 'react';
import { A, Tooltip } from 'sp-ui';
import { Message } from '../intl';

const AddCardDisabledTooltip: React.FC = (props) => {
    return (
        <Tooltip
            // @ts-ignore
            closeDelay={5000}
            // @ts-ignore
            modifiers={[
                {
                    enabled: true,
                    fn: ({
                        instance: {
                            state: { styles }
                        }
                    }) => {
                        styles.popper['pointer-events'] = 'initial';
                    },
                    name: 'clickable',
                    phase: 'write'
                }
            ]}
            tooltipLabel={
                <>
                    <Message id="payoutAccount.addCardDisabledTooltip.label" />
                    <br />
                    <A href="https://status.shootproof.com/" target="_blank">
                        <Message id="payoutAccount.addCardDisabledTooltip.link" />
                    </A>
                </>
            }
            {...props}
        />
    );
};

export default AddCardDisabledTooltip;
