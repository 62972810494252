import React, { createContext, useCallback, useContext, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export interface IOnboardingPageContext {
    hasHeaderClose: boolean;
    showHeaderClose: () => void;
}

export const OnboardingPageContext = createContext<IOnboardingPageContext>({
    hasHeaderClose: false,
    showHeaderClose: () => {}
});

export const OnboardingPageProvider: React.FC = (props) => {
    const [hasHeaderClose, setHasHeaderClose] = useState<boolean>(false);
    const { pathname } = useLocation();
    const showHeaderClose = useCallback(() => {
        setHasHeaderClose(true);
    }, []);

    useLayoutEffect(() => {
        setHasHeaderClose(false);
    }, [pathname]);

    return (
        <OnboardingPageContext.Provider
            value={{
                hasHeaderClose,
                showHeaderClose
            }}
            {...props}
        />
    );
};

export function useOnboardingPage(): IOnboardingPageContext {
    const context = useContext(OnboardingPageContext);

    return context;
}
