export const getStartOfDayFromThreeMonthsAgo: () => Date = () => {
    const startOfDayThreeMonthsAgo = getStartOfDayToday();

    startOfDayThreeMonthsAgo.setMonth(startOfDayThreeMonthsAgo.getMonth() - 3);

    return startOfDayThreeMonthsAgo;
};

export const getStartOfDayToday: () => Date = () => {
    const startOfDayToday = new Date();

    startOfDayToday.setHours(0);
    startOfDayToday.setMinutes(0);
    startOfDayToday.setSeconds(0);
    startOfDayToday.setMilliseconds(0);

    return startOfDayToday;
};
