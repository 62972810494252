import React, { useEffect } from 'react';
import {
    A,
    Banner,
    Box,
    CulDeSac,
    Flex,
    FormElementVariant,
    H5,
    IRadioOption,
    InfoIcon,
    LoadingDialog,
    P,
    RadioGroupVariant,
    Span,
    styled,
    useField,
    useFormikContext
} from 'sp-ui';
import { ReactComponent as AddIcon } from '../../assets/svg/add-icon.svg';
import { CurrencyInput, FormField, Label } from '../form';
import { MerchantAccountTier, useMerchantAccount } from '../merchant-account';
import {
    AddCardDisabledTooltip,
    NewDebitCardModalContent,
    PayoutAccountType,
    usePayoutAccount
} from '../payout-account';
import BannerInfoIcon from '../BannerInfoIcon';
import HelpLink from '../HelpLink';
import { Currency, Message, useCurrency, useMessage } from '../intl';
import { getDisplayBrand } from '../../services/card';
import { usePaymentsApiFetch, usePaymentsApiMerchantAccountPath } from '../../hooks';

interface IAmountTypeRadioGroupFormFieldProps {
    currency: string;
    maxAmount: number;
}

export const AmountTypeRadioGroupFormField: React.FC<IAmountTypeRadioGroupFormFieldProps> = ({
    currency,
    maxAmount
}) => {
    const $ = useCurrency(currency);
    const amountName = 'amount';
    const amountTypeName = 'amountType';
    const [amountTypeField] = useField(amountTypeName);
    const { value: payoutMaxAmount } = amountTypeField;
    const { setFieldValue } = useFormikContext();
    const t = useMessage();

    useEffect(() => {
        if (payoutMaxAmount === 'max') {
            setFieldValue(amountName, maxAmount);
        }
    }, [maxAmount, payoutMaxAmount, setFieldValue]);

    return (
        <FormField
            label="instantPayout.amountTypeRadioGroup.label"
            name={amountTypeName}
            radioOptions={[
                {
                    description: t('instantPayout.amountTypeRadioGroup.maxOption', {
                        maxAmount: $(maxAmount)
                    }),
                    value: 'max'
                },
                {
                    contentWhenSelected: (
                        <CurrencyInput currency={currency} name={amountName} width="156px" />
                    ),
                    description: t('instantPayout.amountTypeRadioGroup.customOption'),
                    value: 'custom'
                }
            ]}
            variant={FormElementVariant.RadioGroup}
        />
    );
};

export const InstantPayoutAddCardButton = styled(Flex, {
    shouldForwardProp: (prop) => prop !== 'isDisabled'
})(
    ({ isDisabled, theme }) => `
        align-items: center;
        border: 1px solid ${theme.colors.gray[200]};
        border-radius: 4px;
        cursor: pointer;
        margin-bottom: 64px;
        padding: 12px;

        ${isDisabled ? 'opacity: 0.5;' : ''}

        a {
            line-height: 16px;
        }

        svg {
            border: 1px solid ${theme.colors.blue[500]};
            border-radius: 12px;
            fill: ${theme.colors.blue[500]};
            height: 24px;
            margin-right: 8px;
            padding: 5px;
            width: 24px;
        }
    `
);

export const InstantPayoutAmountHeading = styled(Span)`
    display: block;
    font-size: 44px;
    font-weight: 300;
    line-height: 56px;
    margin-bottom: 5px;
    text-align: center;
`;

export const InstantPayoutAmountSuperheading = styled(H5)(
    ({ theme }) => `
        color: ${theme.colors.gray[700]};
        margin-bottom: 3px;
        text-align: center;
    `
);

interface IInstantPayoutCardAccountSelectModalContentProps {
    amount: number;
    canAddCards?: boolean;
    currency: string;
}

export const InstantPayoutCardAccountSelectModalContent: React.FC<IInstantPayoutCardAccountSelectModalContentProps> = ({
    amount,
    canAddCards = true,
    currency
}) => {
    const $ = useCurrency(currency);
    const { getPayoutAccounts, payoutAccounts } = usePayoutAccount();
    const debitCardPayoutAccounts = payoutAccounts.filter(
        ({ type }) => type === PayoutAccountType.DebitCard
    );
    const cardRadioOptions: IRadioOption[] = debitCardPayoutAccounts.map(
        ({ cardBrand, id, last4 }) => ({
            description: `${getDisplayBrand(cardBrand)} •••• ${last4}`,
            value: id
        })
    );
    const { 0: field, 2: helpers } = useField('payoutAccountId');
    const rateListPath = usePaymentsApiMerchantAccountPath('/rate-list');
    const [rateListResponse, rateListLoading] = usePaymentsApiFetch(rateListPath);
    const { instantPayoutMinimumFee, instantPayoutRate } =
        rateListResponse?.data.rates.find(
            ({ currency: rateCurrency }) => rateCurrency.toLowerCase() === currency.toLowerCase()
        ) || {};
    const { merchantAccount } = useMerchantAccount();
    const { name: merchantAccountName, tier } = merchantAccount;
    const { name, value } = field;
    const { setValue } = helpers;
    const t = useMessage();

    useEffect(() => {
        if (!value && debitCardPayoutAccounts.length > 0) {
            const [firstDebitCardPayoutAccount] = debitCardPayoutAccounts;
            const { id } = firstDebitCardPayoutAccount;

            setValue(id);
        }
    }, [debitCardPayoutAccounts, setValue, value]);

    return (
        <CulDeSac
            component={(exit) => (
                <NewDebitCardModalContent
                    inModalBody
                    onCancel={exit}
                    onSuccess={async () => {
                        await getPayoutAccounts();
                        exit();
                    }}
                />
            )}>
            {(enter) =>
                rateListLoading ? (
                    <LoadingDialog description="" />
                ) : (
                    <Box data-testid="instant-payout-card-account-select">
                        <InstantPayoutAmountSuperheading>
                            <Message id="instantPayout.modal.amountSuperheading" />
                            <SuperheadingInfoIcon
                                tooltipLabel={
                                    tier === MerchantAccountTier.Level3
                                        ? t('instantPayoutTooltipLabel.level3')
                                        : t('instantPayoutTooltipLabel.default')
                                }
                            />
                        </InstantPayoutAmountSuperheading>
                        <InstantPayoutAmountHeading>
                            <Currency currency={currency} value={amount / 100} />
                        </InstantPayoutAmountHeading>
                        <MerchantAccountSubheading>{merchantAccountName}</MerchantAccountSubheading>
                        <AmountTypeRadioGroupFormField
                            currency={currency}
                            maxAmount={amount / 100}
                        />
                        <Label>
                            <Message id="instantPayout.modal.selectCardLabel" />
                        </Label>
                        {cardRadioOptions.length > 0 && (
                            <FormField
                                defaultValue={value}
                                elementVariant={RadioGroupVariant.Bordered}
                                name={name}
                                radioOptions={cardRadioOptions}
                                variant={FormElementVariant.RadioGroup}
                            />
                        )}
                        {canAddCards ? (
                            <InstantPayoutAddCardButton onClick={enter}>
                                <AddIcon />
                                <A>
                                    <Message id="addDebitCard" />
                                </A>
                            </InstantPayoutAddCardButton>
                        ) : (
                            <AddCardDisabledTooltip>
                                <InstantPayoutAddCardButton isDisabled>
                                    <AddIcon />
                                    <A>
                                        <Message id="addDebitCard" />
                                    </A>
                                </InstantPayoutAddCardButton>
                            </AddCardDisabledTooltip>
                        )}
                        <Banner>
                            <BannerInfoIcon />
                            <P>
                                <Message
                                    id="instantPayout.modal.infoBannerDescription"
                                    values={{
                                        feeRate: Number(instantPayoutRate) * 100,
                                        minimumFee: $(instantPayoutMinimumFee / 100)
                                    }}
                                />{' '}
                                <HelpLink
                                    content="instantPayout.modal.infoBannerLink"
                                    path="/articles/360061561054"
                                />
                            </P>
                        </Banner>
                    </Box>
                )
            }
        </CulDeSac>
    );
};

export const MerchantAccountSubheading = styled(P)(
    ({ theme }) => `
        color: ${theme.colors.gray[500]};
        margin-bottom: 32px;
        text-align: center;
    `
);

export const SuperheadingInfoIcon = styled(InfoIcon)`
    display: inline-block;
    height: 12px;
    margin-left: 4px;
    margin-top: -3px;
    width: 12px;
`;

export default InstantPayoutCardAccountSelectModalContent;
