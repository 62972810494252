import React from 'react';
import { H5 } from 'sp-ui';
import { IPayoutAccount } from './types';
import { useMessage } from '../intl';
import { getDisplayBrand } from '../../services/card';

interface IPayoutAccountProps {
    multiline?: boolean;
    payoutAccount: IPayoutAccount;
}

const PayoutAccount: React.FC<IPayoutAccountProps> = ({ multiline, payoutAccount }) => {
    const { bankName, cardBrand, last4 } = payoutAccount;
    const getAccountSubtypeText = () => {
        const { accountSubtype } = payoutAccount;

        if (accountSubtype === 'checking') {
            return t('payoutAccount.accountSubtype.checking');
        } else if (accountSubtype === 'savings') {
            return t('payoutAccount.accountSubtype.savings');
        }

        return '';
    };
    const t = useMessage();
    const displayName = bankName || getAccountSubtypeText() || getDisplayBrand(cardBrand);

    return multiline ? (
        <>
            <H5>{displayName}</H5>
            {`•••• ${last4}`}
        </>
    ) : (
        <>{`${displayName} •••• ${last4}`}</>
    );
};

export default PayoutAccount;
