import React, { ComponentProps, useRef } from 'react';
import { Link as ReactRouterDomLink } from 'react-router-dom';
import { Button as SpUiButton, styled, useFormikContext } from 'sp-ui';
import { Message, useMessage } from '../intl';

interface IButtonProps {
    message?: string;
}

const Button: React.FC<IButtonProps & ComponentProps<typeof SpUiButton>> = ({
    children,
    isDisabled,
    message,
    ...props
}) => (
    <ButtonContainer isDisabled={isDisabled} {...props}>
        {message && <Message id={message} />}
        {children}
    </ButtonContainer>
);

const ButtonContainer = styled(SpUiButton)`
    font-size: 12px;
    font-weight: 400;
`;

interface IButtonLinkProps {
    message: string;
    to: string;
}

export const ButtonLink: React.FC<IButtonLinkProps & ComponentProps<typeof SpUiButton>> = ({
    disabled,
    message,
    to,
    ...props
}) => {
    const linkRef = useRef<ReactRouterDomLink & HTMLAnchorElement>(null);

    return (
        <Button disabled={disabled} onClick={() => linkRef.current?.click()} {...props}>
            <Link
                disabled={Boolean(disabled)}
                onClick={(event) => event.stopPropagation()}
                ref={linkRef}
                to={to}>
                <Message id={message} />
            </Link>
        </Button>
    );
};

export const DevButton = styled(Button)(({ theme }) => {
    // @ts-ignore
    const { backgroundColor, borderColor, color } = theme.components.DevButton.baseStyle;

    return `
        background-color: ${backgroundColor};
        border-color: ${borderColor};
        color: ${color};

        &:hover {
            background-color: ${backgroundColor};
            border-color: ${borderColor};
            color: ${color};
        }
    `;
});

export type IFormButtonProps = {
    disableUntilFormDirty?: boolean;
    disableUntilFormValid?: boolean;
} & IButtonProps &
    ComponentProps<typeof SpUiButton>;

export const FormButton: React.FC<IFormButtonProps> = ({
    disableUntilFormDirty = false,
    disableUntilFormValid = false,
    ...props
}) => {
    const { dirty, isSubmitting, isValid, submitForm } = useFormikContext();
    const isDisabled =
        (disableUntilFormValid && !isValid) || (disableUntilFormDirty && !dirty) || isSubmitting;
    const t = useMessage();

    return (
        <Button
            loadedText={t('button.form.loaded')}
            loadingText={t('button.form.loading')}
            onClick={submitForm}
            isDisabled={isDisabled}
            {...props}
        />
    );
};

export const Link = styled(ReactRouterDomLink)<{ disabled: boolean }>(({ disabled }) =>
    disabled
        ? `
            cursor: default;
            pointer-events: none;
        `
        : ''
);

export default Button;
