import React, { useEffect, useState } from 'react';
import {
    A,
    AlertDialog,
    Banner,
    BannerVariant,
    Box,
    LoadingDialog,
    P,
    styled,
    useModal,
    yup
} from 'sp-ui';
import { ReactComponent as ExclamationWarningIcon } from 'sp-ui/dist/assets/svg/exclamation-warning.svg';
import { ReactComponent as WarningIcon } from '../../assets/svg/warning-icon.svg';
import SettingsDetail from './Detail';
import PayoutAccountDetails, { BorderedPayoutAccountDetails } from './PayoutAccountDetails';
import PayoutScheduleIntervalField from './PayoutScheduleIntervalField';
import RemoveDefaultPayoutAccountModal from './RemoveDefaultPayoutAccountModal';
import { PayoutScheduleInterval } from './common';
import { useMerchantAccount } from '../merchant-account';
import {
    AddCardDisabledTooltip,
    BorderedPendingBankAccount,
    NewDebitCardModal,
    PendingBankAccount,
    usePayoutAccount
} from '../payout-account';
import { Message, useMessage } from '../intl';
import { Permission, usePermissions } from '../permissions';
import { useToast } from '../toast';
import { usePaymentsApiFetch, usePaymentsApiMerchantAccountPath } from '../../hooks';
import { convertSnakeToLowerCamel } from '../../string';
import { noop } from '../../utils';

interface IConfirmCancelPendingBankAccountProps {
    close: () => void;
    isSettings: boolean;
    pendingBankAccountId: string;
    onCanceled?: () => void;
}

export const ConfirmCancelPendingBankAccount: React.FC<IConfirmCancelPendingBankAccountProps> = ({
    close,
    isSettings,
    pendingBankAccountId,
    onCanceled
}) => {
    const { createToast } = useToast();
    const t = useMessage();
    const { pendingBankAccounts, removeFromPendingBankAccounts } = usePayoutAccount();
    const pendingBankAccount = pendingBankAccounts.find(({ id }) => id === pendingBankAccountId);
    const { 2: deletePlaidPendingBankAccount } = usePaymentsApiFetch(
        `/plaid/link-token/pending-bank-account/${pendingBankAccountId}`,
        {
            defer: true,
            method: 'delete'
        }
    );

    if (!pendingBankAccount) {
        return null;
    }

    return (
        <AlertDialog
            body={
                <>
                    <P marginBottom="12px">
                        <Message id="settings.confirmCancelPendingBankAccount" />
                    </P>
                    {isSettings && (
                        <BorderedPendingBankAccount pendingBankAccount={pendingBankAccount} />
                    )}
                </>
            }
            header="settings.cancelPendingBankAccountHeader"
            isDestructive
            isOpen
            onConfirm={async () => {
                await deletePlaidPendingBankAccount();
                close();
                removeFromPendingBankAccounts(pendingBankAccountId);
                if (isSettings) {
                    createToast({
                        text: t('settings.payoutSettings.pendingBankAccountCanceledToast.text'),
                        subText: t(
                            'settings.payoutSettings.pendingBankAccountCanceledToast.subText'
                        )
                    });
                } else {
                    if (onCanceled) {
                        onCanceled();
                    }
                }
            }}
            onClose={close}
            primaryButtonText="settings.cancelPendingBankAccount.confirm"
            secondaryButtonText="settings.cancelPendingBankAccount.back"
        />
    );
};

interface IConfirmRemovePayoutAccountProps {
    close: () => void;
    payoutAccountId: string;
}

export const ConfirmRemovePayoutAccount: React.FC<IConfirmRemovePayoutAccountProps> = ({
    close,
    payoutAccountId
}) => {
    const { createToast } = useToast();
    const t = useMessage();
    const { payoutAccounts, removeFromPayoutAccounts } = usePayoutAccount();
    const payoutAccount = payoutAccounts.find(({ id }) => id === payoutAccountId);
    const payoutAccountPath = usePaymentsApiMerchantAccountPath(
        `/payout-account/${payoutAccountId}`
    );
    const { 2: deletePayoutAccount } = usePaymentsApiFetch(payoutAccountPath, {
        defer: true,
        method: 'delete'
    });

    if (!payoutAccount) {
        return null;
    }

    return (
        <AlertDialog
            body={
                <>
                    <P marginBottom="12px">
                        <Message id="settings.confirmRemovePayoutAccount" />
                    </P>
                    <BorderedPayoutAccountDetails
                        marginBottom="32px"
                        payoutAccount={payoutAccount}
                    />
                </>
            }
            header="settings.removePayoutMethod"
            isDestructive
            isOpen
            onConfirm={async () => {
                await deletePayoutAccount();
                close();
                createToast({
                    text: t('settings.payoutSettings.payoutAccountDeletedToast.text'),
                    subText: t('settings.payoutSettings.payoutAccountDeletedToast.subText')
                });
                removeFromPayoutAccounts(payoutAccountId);
            }}
            onClose={close}
            primaryButtonLoadingText="settings.removingPayoutMethod"
            primaryButtonText="settings.removePayoutMethod"
        />
    );
};

export const NoPayoutAccountsBanner = styled(Banner)`
    svg {
        flex: 0 0 40px;
        height: 40px;
        margin-right: 16px;
    }
`;

type ScheduleIntervalFormValues = {
    payoutScheduleInterval: 'daily' | 'monthly' | 'weekly';
};

const PayoutSettings: React.FC = () => {
    const {
        Modal: RemoveDefaultPayoutAccountModalComponent,
        open: openRemoveDefaultPayoutAccountModal
    } = useModal();
    const {
        Modal: NewDebitCardModalComponent,
        close: closeNewDebitCardModal,
        open: openNewDebitCardModal
    } = useModal();

    const { payoutAccounts, payoutAccountsLoading, pendingBankAccounts } = usePayoutAccount();
    const bankAccounts = payoutAccounts.filter(({ type }) => type === 'bank_account');
    const cardAccounts = payoutAccounts.filter(({ type }) => type === 'card_account');
    const getPendingBankAccountBannerDetailedDescription = () => {
        if (pendingBankAccounts.length === 0) {
            return '';
        }

        const [{ verificationStatus }] = pendingBankAccounts;
        const camelizedVerificationStatus = convertSnakeToLowerCamel(verificationStatus);

        return `settings.payoutSettings.pendingBankAccountBanner.${camelizedVerificationStatus}Description`;
    };
    const { hasPermission } = usePermissions();
    const hasAddDebitCardPermission = hasPermission(Permission.AddDebitCard);
    const hasMultiplePayoutAccounts = payoutAccounts.length > 1;
    const hasMultipleBankAccounts = bankAccounts.length > 1;
    const { merchantAccount, updateMerchantAccount } = useMerchantAccount();
    const [payoutAccountIdToRemove, setPayoutAccountIdToRemove] = useState<string | null>(null);
    const [pendingBankAccountIdToRemove, setPendingBankAccountIdToRemove] = useState<string | null>(
        null
    );
    const { payoutScheduleInterval } = merchantAccount;
    const initialValues = { payoutScheduleInterval };
    const isBankAccountEmptyState = bankAccounts.length === 0;
    const isCardAccountEmptyState = cardAccounts.length === 0;
    const isEmptyState = payoutAccounts.length === 0;
    const onPayoutScheduleIntervalFormSubmit = async ({ payoutScheduleInterval }) => {
        await updateMerchantAccount({ payoutScheduleInterval });
    };
    const [showLoadingDialog, setShowLoadingDialog] = useState<boolean>(false);
    const t = useMessage();
    const validationSchema = yup.object().shape({
        payoutScheduleInterval: yup.string().oneOf(Object.values(PayoutScheduleInterval)).required()
    });

    const accountLinkPath = usePaymentsApiMerchantAccountPath('/account-link');
    const [accountLinkResponse] = usePaymentsApiFetch(accountLinkPath, {
        data: { type: 'update' },
        fetchOnUrlChange: true,
        handleForbidden: noop,
        method: 'post'
    });
    const accountLinkUrl = accountLinkResponse?.data?.url;

    const handleUpdateStripeAccount = () => {
        window.location.assign(accountLinkUrl);
    };

    useEffect(() => {
        if (!payoutAccountsLoading) {
            setShowLoadingDialog(false);
        }
    }, [payoutAccountsLoading]);

    return (
        <>
            {showLoadingDialog || payoutAccountsLoading ? (
                <LoadingDialog description="" />
            ) : (
                <>
                    {isEmptyState && (
                        <NoPayoutAccountsBanner
                            data-testid="payout-settings-payout-banner"
                            marginBottom="20px"
                            variant={BannerVariant.Warning}>
                            <WarningIcon />
                            <P>
                                <Message id="settings.payoutSettings.noPayoutAccountsBanner" />{' '}
                                <A>
                                    <Message id="settings.payoutSettings.noPayoutAccountsBannerLink" />
                                </A>
                            </P>
                        </NoPayoutAccountsBanner>
                    )}
                    {pendingBankAccounts.length > 0 && (
                        <PendingBankAccountBanner
                            data-testid="pending-bank-account-banner"
                            variant={BannerVariant.Warning}>
                            <ExclamationWarningIcon />
                            <P>
                                <Message id="settings.payoutSettings.pendingBankAccountBannerDescription" />
                                <br />
                                <Message id={getPendingBankAccountBannerDetailedDescription()} />
                            </P>
                        </PendingBankAccountBanner>
                    )}
                    <SettingsDetail
                        data-testid="payout-settings-automatic-payout-accounts"
                        heading={t('settings.payoutSettings.automaticPayoutAccountsHeading')}
                        subheading={t('settings.payoutSettings.automaticPayoutAccountsSubheading')}
                        headingAction={{
                            onClick: handleUpdateStripeAccount,
                            text: t('settings.payoutSettings.automaticPayoutAccountsHeadingAction')
                        }}
                        marginBottom="20px"
                        noChildrenPadding={!isBankAccountEmptyState}>
                        {isBankAccountEmptyState && (
                            <P>
                                <Message id="settings.payoutSettings.noAutomaticPayoutAccounts" />
                            </P>
                        )}
                        {bankAccounts.map((payoutAccount, index) => (
                            <PayoutAccountDetails
                                key={index}
                                onRemoveDefaultPayoutAccount={openRemoveDefaultPayoutAccountModal}
                                onRemovePayoutAccount={setPayoutAccountIdToRemove}
                                payoutAccount={payoutAccount}
                                showMenu={hasMultipleBankAccounts}
                            />
                        ))}
                        {pendingBankAccounts.map((pendingBankAccount, index) => (
                            <PendingBankAccountContainer key={index}>
                                <PendingBankAccount
                                    onCancelPendingBankAccount={setPendingBankAccountIdToRemove}
                                    pendingBankAccount={pendingBankAccount}
                                    showMenu
                                />
                            </PendingBankAccountContainer>
                        ))}
                    </SettingsDetail>
                    {merchantAccount.instantPayoutsEnabled && (
                        <SettingsDetail
                            data-testid="payout-settings-instant-payout-accounts"
                            heading={t('settings.payoutSettings.instantPayoutAccountsHeading')}
                            subheading={t(
                                'settings.payoutSettings.instantPayoutAccountsSubheading'
                            )}
                            headingAction={{
                                isDisabled: !hasAddDebitCardPermission,
                                onClick: openNewDebitCardModal,
                                text: t(
                                    'settings.payoutSettings.instantPayoutAccountsHeadingAction'
                                ),
                                tooltip: hasAddDebitCardPermission
                                    ? undefined
                                    : AddCardDisabledTooltip
                            }}
                            marginBottom="20px"
                            noChildrenPadding={!isCardAccountEmptyState}>
                            {isCardAccountEmptyState && (
                                <P>
                                    <Message id="settings.payoutSettings.noInstantPayoutAccounts" />
                                </P>
                            )}
                            {cardAccounts.map((payoutAccount, index) => (
                                <PayoutAccountDetails
                                    key={index}
                                    onRemovePayoutAccount={setPayoutAccountIdToRemove}
                                    payoutAccount={payoutAccount}
                                    showMenu={hasMultiplePayoutAccounts}
                                    allowSetDefault={false}
                                />
                            ))}
                        </SettingsDetail>
                    )}
                    <SettingsDetail<ScheduleIntervalFormValues>
                        data-testid="payout-settings-schedule-interval"
                        heading={t('settings.payoutScheduleInterval')}
                        initialValues={initialValues}
                        onSubmit={onPayoutScheduleIntervalFormSubmit}
                        subheading={t('settings.payoutScheduleIntervalDescription')}
                        validationSchema={validationSchema}>
                        <PayoutScheduleIntervalField omitLabels />
                    </SettingsDetail>
                    <NewDebitCardModal
                        close={closeNewDebitCardModal}
                        modalComponent={NewDebitCardModalComponent}
                    />
                    {pendingBankAccountIdToRemove && (
                        <ConfirmCancelPendingBankAccount
                            close={() => {
                                setPendingBankAccountIdToRemove(null);
                            }}
                            isSettings
                            pendingBankAccountId={pendingBankAccountIdToRemove}
                        />
                    )}
                    {payoutAccountIdToRemove && (
                        <ConfirmRemovePayoutAccount
                            close={() => {
                                setPayoutAccountIdToRemove(null);
                            }}
                            payoutAccountId={payoutAccountIdToRemove}
                        />
                    )}
                    {hasMultiplePayoutAccounts && (
                        <RemoveDefaultPayoutAccountModal
                            modalComponent={RemoveDefaultPayoutAccountModalComponent}
                        />
                    )}
                </>
            )}
        </>
    );
};

export const PendingBankAccountBanner = styled(Banner)`
    margin-bottom: 36px;

    svg {
        flex: 0 0 43px;
        margin-right: 16px;
    }
`;

const PendingBankAccountContainer = styled(Box)(
    ({ theme }) => `
        &:not(:last-of-type) {
            border-bottom: 1px solid ${theme.colors.gray[200]};
        }
    `
);

export default PayoutSettings;
