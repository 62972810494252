import { Radio, RadioGroup, RadioProps, useRadioGroup } from '@chakra-ui/react';
import React, { useRef } from 'react';
import { Box, Flex, P, StatusPill, styled, useField } from 'sp-ui';
import Label from './Label';
import { Message, useMessage } from '../intl';

const Choice = styled(Flex, {
    shouldForwardProp: (prop) => prop !== 'alwaysSmallScreen'
})(({ alwaysSmallScreen, theme }) => {
    const smallScreenOnlyStyles = `
        flex-direction: row;
        padding: 14px 16px;

        > div {
            text-align: left;
        }

        label {
            text-align: left;
        }

        svg {
            flex: 0 0 60px;
            height: 60px;
            margin-bottom: 0;
            margin-right: 16px;
        }
    `;

    return `
        align-items: center;
        border: 1px solid ${theme.colors.gray[200]};
        border-radius: 4px;
        cursor: pointer;
        flex-direction: column;
        padding: 24px 20px 14px;
        position: relative;
        transition: all 250ms ease-in-out;

        &:hover,
        &.selected {
            box-shadow: 0px 4px 16px rgba(42, 89, 89, 0.25);
        }

        &.selected {
            border: 2px solid ${theme.colors.blue[500]};
            margin-top: -2px;
        }

        > div {
            flex: 1;
            text-align: center;
        }

        label {
            margin-bottom: 4px;
            text-align: center;
        }

        p {
            line-height: 24px;
            margin-bottom: 0;
        }

        svg {
            flex: 0 0 100px;
            height: 100px;
            margin-bottom: 34px;
        }

        ${alwaysSmallScreen ? smallScreenOnlyStyles : ''}
        ${theme.responsive.smallScreenOnly(smallScreenOnlyStyles)}
    `;
});

type IChoiceRadioProps = {
    alwaysSmallScreen?: boolean;
    description: string;
    fieldName: string;
    image?: React.FC<React.SVGProps<SVGSVGElement>>;
    onChange?: (value: string) => void;
    recommended?: boolean;
    label: string;
} & RadioProps;

export const ChoiceRadio: React.FC<IChoiceRadioProps> = ({
    alwaysSmallScreen,
    description,
    fieldName,
    image: Image = () => null,
    isChecked,
    label,
    onChange,
    recommended,
    value
}) => {
    const clickRadio = () => ref.current?.click();
    const [field] = useField(fieldName);
    const { onChange: onFieldChange } = field;
    const ref = useRef<HTMLInputElement>(null);
    const t = useMessage();

    return (
        <Choice
            alwaysSmallScreen={alwaysSmallScreen}
            className={isChecked ? 'selected' : ''}
            onClick={clickRadio}>
            <Radio
                display="none"
                isChecked={isChecked}
                onChange={(event) => {
                    onFieldChange(event);

                    if (onChange) {
                        onChange(event.target.value);
                    }
                }}
                ref={ref}
                value={value}
            />
            {recommended && (
                <RecommendedStatusPill
                    alwaysSmallScreen={alwaysSmallScreen}
                    statusPillText={t('splitChoiceRadioGroup.recommendedStatusPill')}
                />
            )}
            <Image />
            <Box w="100%">
                <Label>
                    <Message id={label} />
                </Label>
                <P>
                    <Message id={description} />
                </P>
            </Box>
        </Choice>
    );
};

export const RecommendedStatusPill = styled(StatusPill, {
    shouldForwardProp: (prop) => prop !== 'alwaysSmallScreen'
})<{ alwaysSmallScreen?: boolean }>(({ alwaysSmallScreen, theme }) => {
    const smallScreenOnlyStyles = `
        right: 10px;
        top: 26px;
    `;

    return `
        background: ${theme.colors.blue[100]};
        position: absolute;
        top: 0;
        transform: translateY(-50%);

        ${alwaysSmallScreen ? smallScreenOnlyStyles : ''}
        ${theme.responsive.smallScreenOnly(smallScreenOnlyStyles)}
    `;
});

interface ISplitChoiceRadioGroupProps {
    alwaysSmallScreen?: boolean;
    choices: Omit<IChoiceRadioProps, 'fieldName'>[];
    name: string;
    onChange?: (value: string) => void;
}

const SplitChoiceRadioGroup: React.FC<ISplitChoiceRadioGroupProps> = ({
    alwaysSmallScreen,
    choices,
    name,
    onChange
}) => {
    const [field] = useField(name);
    const { value } = field;
    const { getRadioProps } = useRadioGroup({
        name,
        defaultValue: value,
        onChange
    });

    return (
        <SplitRadioGroup alwaysSmallScreen={alwaysSmallScreen} value={value} name={name}>
            {choices.map(({ description, label, image, recommended, value }, index) => {
                const radio = getRadioProps({ value });

                return (
                    <ChoiceRadio
                        alwaysSmallScreen={alwaysSmallScreen}
                        description={description}
                        fieldName={name}
                        image={image}
                        key={index}
                        label={label}
                        recommended={recommended}
                        value={value}
                        {...radio}
                    />
                );
            })}
        </SplitRadioGroup>
    );
};

const SplitRadioGroup = styled(RadioGroup, {
    shouldForwardProp: (prop) => prop !== 'alwaysSmallScreen'
})(({ theme, alwaysSmallScreen }) => {
    const smallScreenOnlyStyles = `
        flex-wrap: wrap;

        > div {
            margin-bottom: 16px;
            width: 100%;
        }
    `;

    return `
        align-items: stretch;
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;

        > div {
            margin-bottom: 0;
            width: calc(50% - 8px);
        }

        ${alwaysSmallScreen ? smallScreenOnlyStyles : ''}
        ${theme.responsive.smallScreenOnly(smallScreenOnlyStyles)}
    `;
});

export default SplitChoiceRadioGroup;
