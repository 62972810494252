import React from 'react';
import { useMerchantAccount } from '../merchant-account';
import { GlobalErrorBanner } from '../navigation';
import { usePayoutAccount } from './Context';
import { PayoutAccountStatus } from './common';
import Link from '../Link';
import { Message } from '../intl';

const PayoutAccountDisabledBanner: React.FC = () => {
    const {
        merchantAccount: { id }
    } = useMerchantAccount();
    const { payoutAccounts } = usePayoutAccount();
    const [defaultBankAccount = { last4: '', status: null }] =
        payoutAccounts.filter((payoutAccount) => payoutAccount.isDefaultForCurrency) || [];
    const { last4, status } = defaultBankAccount;

    if (status !== PayoutAccountStatus.Errored) {
        return null;
    }

    return (
        <GlobalErrorBanner>
            <Message
                id="payoutAccount.disabledBanner.description"
                values={{
                    last4,
                    link: (
                        <Link to={`/payment-account/${id}/settings/payout`}>
                            <Message id="payoutAccount.disabledBanner.link" />
                        </Link>
                    )
                }}
            />
        </GlobalErrorBanner>
    );
};

export default PayoutAccountDisabledBanner;
