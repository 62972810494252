import React, { useCallback, useMemo, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { ButtonVariant, Flex, ResponsiveNav, Span, styled } from 'sp-ui';
import { ReactComponent as HelpIcon } from '../../assets/svg/help.svg';
import { ReactComponent as LogoIcon } from '../../assets/svg/shootproof-pay-logo.svg';
import { ReactComponent as SettingsIcon } from '../../assets/svg/settings.svg';
import { ButtonLink } from '../form';
import { useMerchantAccount } from '../merchant-account';
import NavigationLink from './NavigationLink';
import { GetNotificationPreference } from '../notification-preference';
import { Permission, usePermissions, withPermission } from '../permissions';
import { useAuthenticationToken } from '../Authentication';
import HelpLink from '../HelpLink';
import { Message } from '../intl';

const Navigation: React.FC = () => {
    const { url: basePath } = useRouteMatch();
    const getRouteMatch = () => {
        if (basePath === pathname) {
            return navItemsMap.transaction;
        }

        return Object.values(navItemsMap).find((item) =>
            item.linkElement.props.to === pathname ? item : null
        );
    };
    const { hasPermission } = usePermissions();
    const { merchantAccount } = useMerchantAccount();
    const { id, name } = merchantAccount;
    const { pathname } = useLocation();
    const navItemsMap = useMemo(
        () => ({
            documents: {
                linkElement: (
                    <NavigationLink
                        label={<Message id="navigation.documentsLink" />}
                        to={`/payment-account/${id}/documents`}
                    />
                )
            },
            help: {
                linkElement: (
                    <HelpLink path="/sections/360012551873">
                        {(helpUrl) => (
                            <NavigationLink
                                href={helpUrl}
                                icon={<HelpIcon />}
                                label={<Message id="navigation.helpLink" />}
                            />
                        )}
                    </HelpLink>
                )
            },
            merchant: {
                linkElement: <NavigationLink label={name} />
            },
            payout: {
                linkElement: (
                    <NavigationLink
                        label={<Message id="navigation.payoutLink" />}
                        to={`/payment-account/${id}/payout`}
                    />
                )
            },
            settings: {
                linkElement: (
                    <NavigationLinkWithAdminPermission
                        icon={<SettingsIcon />}
                        label={<Message id="navigation.settingsLink" />}
                        to={`/payment-account/${id}/settings`}
                    />
                )
            },
            settingsAccount: {
                linkElement: (
                    <NavigationLinkWithAdminPermission
                        label={<Message id="settings.accountInformation" />}
                        to={`/payment-account/${id}/settings/account`}
                    />
                )
            },
            settingsPayout: {
                linkElement: (
                    <NavigationLinkWithAdminPermission
                        label={<Message id="settings.payoutSettings" />}
                        to={`/payment-account/${id}/settings/payout`}
                    />
                )
            },
            transaction: {
                linkElement: (
                    <NavigationLink
                        label={<Message id="navigation.transactionLink" />}
                        to={`/payment-account/${id}/payment`}
                    />
                )
            }
        }),
        [id, name]
    );
    const navItems = useMemo(
        () => [navItemsMap.transaction, navItemsMap.payout, navItemsMap.documents],
        [navItemsMap]
    );
    const rightNav = useMemo(() => [navItemsMap.settings, navItemsMap.help, navItemsMap.merchant], [
        navItemsMap
    ]);
    const shootProofPayLogo = <LogoIcon />;
    const smallScreenNavItems = useMemo(
        () =>
            hasPermission(Permission.Admin)
                ? [
                      navItemsMap.merchant,
                      navItemsMap.transaction,
                      navItemsMap.payout,
                      navItemsMap.documents,
                      {
                          smallScreenMenu: [
                              {
                                  icon: <SettingsIcon />,
                                  title: <Message id="navigation.settingsLink" />,
                                  menuItems: [
                                      navItemsMap.settingsAccount,
                                      navItemsMap.settingsPayout
                                  ]
                              }
                          ]
                      },
                      navItemsMap.help
                  ]
                : [
                      navItemsMap.merchant,
                      navItemsMap.transaction,
                      navItemsMap.payout,
                      navItemsMap.help
                  ],
        [hasPermission, navItemsMap]
    );

    return (
        <>
            <UnbecomeBanner />
            <ResponsiveNav
                logo={shootProofPayLogo}
                initialActiveNavItem={getRouteMatch()}
                navItems={navItems}
                rightNav={rightNav}
                smallScreenNavItems={smallScreenNavItems}
            />
        </>
    );
};

export const NavigationLinkWithAdminPermission = withPermission(Permission.Admin, NavigationLink);

export const UnbecomeBanner: React.FC = () => {
    const { isDelegated } = useAuthenticationToken();
    const [name, setName] = useState<string>('');
    const setNameFromNotificationPreference = useCallback(({ firstName, lastName }) => {
        setName(`${firstName} ${lastName}`);
    }, []);

    return isDelegated ? (
        <UnbecomeBannerContainer>
            <Span>
                <Message id="navigation.unbecomeBanner.description" values={{ name }} />
            </Span>
            <ButtonLink
                to="/auth/login"
                marginLeft="8px"
                message="navigation.unbecomeBanner.link"
                variant={ButtonVariant.Destructive}
            />
            <GetNotificationPreference onGot={setNameFromNotificationPreference} />
        </UnbecomeBannerContainer>
    ) : null;
};

const UnbecomeBannerContainer = styled(Flex)(
    ({ theme }) => `
        align-items: center;
        background: ${theme.colors.blue[700]};
        justify-content: center;
        padding: 8px 32px;

        span {
            color: ${theme.colors.white};
        }
    `
);

export default Navigation;
