import React from 'react';
import { Box, Flex, Span, styled } from 'sp-ui';
import { ReactComponent as CloseIcon } from '../../assets/svg/close-icon.svg';
import { ReactComponent as ShootProofPayLogo } from '../../assets/svg/shootproof-pay-logo.svg';
import { UnbecomeBanner } from '../navigation';
import { useOnboardingPage } from './PageContext';
import { Message } from '../intl';
import { goToShootProofAcceptPayments } from '../../services/onboarding';

interface IOnboardingPageHeaderProps {
    hasCancel?: boolean;
}

const OnboardingPageHeader: React.FC<IOnboardingPageHeaderProps> = ({ hasCancel }) => {
    const { hasHeaderClose } = useOnboardingPage();

    return (
        <>
            <UnbecomeBanner />
            <PageHeader>
                <Box height="28px" width="174px">
                    <ShootProofPayLogo />
                </Box>
                {(hasCancel || hasHeaderClose) && (
                    <Span onClick={goToShootProofAcceptPayments}>
                        {hasHeaderClose ? (
                            <Flex
                                alignItems="center"
                                height="24px"
                                justifyContent="space-around"
                                width="24px">
                                <CloseIcon fill="currentColor" height="11px" width="11px" />
                            </Flex>
                        ) : (
                            <Message id="cancelButton" />
                        )}
                    </Span>
                )}
            </PageHeader>
        </>
    );
};

const PageHeader = styled(Flex)(
    ({ theme }) => `
        align-items: center;
        border-bottom: 1px solid ${theme.colors.gray[200]};
        justify-content: space-between;
        padding: 20px 32px 16px;

        span {
            color: ${theme.colors.gray[600]};
            cursor: pointer;
        }
    `
);

export default OnboardingPageHeader;
