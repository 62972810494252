import React from 'react';
import { Box, FormElement, styled } from 'sp-ui';

const FormFieldWrapper = styled(Box)`
    input[type='text'] + div svg:not([cursor='pointer']) {
        display: none;
    }
`;

const FormField: React.FC<any> = ({ className, mb, ...props }) => (
    <FormFieldWrapper className={className} mb={mb || '16px'}>
        <FormElement {...props} />
    </FormFieldWrapper>
);

export default FormField;
