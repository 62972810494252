import React, { createContext, useContext, useEffect, useState } from 'react';
import { useMerchantAccount } from '../merchant-account/Context';
import { usePaymentsApiFetch, usePaymentsApiMerchantAccountPath } from '../../hooks';

export type IBalance = {
    available: number | null;
    availableInstant: number | null;
    currency: string;
    pending: number | null;
};

export interface IBalanceContext {
    balance: IBalance;
    getBalance: () => void;
    loading: boolean;
}

const BalanceContext = createContext<IBalanceContext>({
    balance: {
        available: null,
        availableInstant: null,
        currency: '',
        pending: null
    },
    getBalance: () => {},
    loading: false
});

export const BalanceProvider: React.FC = ({ ...props }) => {
    const balancePath = usePaymentsApiMerchantAccountPath('/balance');
    const { merchantAccount } = useMerchantAccount();
    const { defaultCurrency: currency } = merchantAccount;
    const [balance, setBalance] = useState<IBalance>({
        available: null,
        availableInstant: null,
        currency,
        pending: null
    });
    const [response, loading, getBalance] = usePaymentsApiFetch(balancePath);

    useEffect(() => {
        setBalance({
            available: null,
            availableInstant: null,
            currency: merchantAccount.defaultCurrency,
            pending: null
        });
    }, [merchantAccount]);

    useEffect(() => {
        if (response) {
            const [balance] = response.data;

            setBalance(balance);
        }
    }, [response]);

    return (
        <BalanceContext.Provider
            value={{
                balance,
                loading,
                getBalance
            }}
            {...props}
        />
    );
};

export function useBalance(): IBalanceContext {
    const context = useContext(BalanceContext);
    const { balance } = context;
    const { currency } = balance;

    if (!currency) {
        throw new Error('useBalance must be used within a BalanceProvider');
    }

    return context;
}
