import { useTheme as useSpUiTheme } from 'sp-ui';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMerchantAccount } from './components/merchant-account/Context';
import { usePermissions } from './components/permissions/Context';
import { useAuthenticationToken } from './components/Authentication';
import { convertLowerCamelToSnake, convertSnakeToLowerCamel } from './string';

function transformKeys(object: any, transform: (key: string) => string): any {
    if (!object || typeof object !== 'object') {
        return object;
    }

    return Object.keys(object).reduce((camelized, key) => {
        const transformedKey = transform(key);
        const value = object[key];

        camelized[transformedKey] =
            value !== null && typeof value === 'object'
                ? value.length === undefined
                    ? transformKeys(value, transform)
                    : value.map((value) => transformKeys(value, transform))
                : value;
        return camelized;
    }, {});
}

const defaultOptions: IFetchHookOptions = {
    defer: false
};
const inProgressGetRequestPromises = {};

type IFetchHookOptions = {
    data?: object;
    defer?: boolean;
    fetchOnUrlChange?: boolean;
    search?: Record<string, string>;
} & RequestInit;
type IFetchHook = [any, boolean, (fetchOptions?: IFetchHookOptions) => any];

export function useFetch(
    url: string,
    fetchOptions: IFetchHookOptions = defaultOptions
): IFetchHook {
    let { defer } = fetchOptions;

    delete fetchOptions.defer;

    const [doFetch, setDoFetch] = useState(!defer);
    const fetchResponse = useCallback(
        async (options: IFetchHookOptions) => {
            const { method = 'get' } = options;
            const hasBody = ['patch', 'post', 'put'].some(
                (methodWithBody) => methodWithBody === method.toLowerCase()
            );

            if (hasBody) {
                const { data } = options;
                const defaultPostHeaders = { 'Content-Type': 'application/json' };

                if (data) {
                    const snakeCaseKeyedData = transformKeys(data, convertLowerCamelToSnake);

                    options.body = JSON.stringify(snakeCaseKeyedData);

                    delete options.data;
                }

                options.headers = {
                    ...defaultPostHeaders,
                    ...(options.headers || {})
                };
            }

            if (method.toLowerCase() === 'patch') {
                options.method = 'PATCH';
            }

            let fetchResponse;
            const { search } = options;
            let fetchUrl = url;

            if (search) {
                const searchParams: [string, string][] = Object.entries(
                    transformKeys(search, convertLowerCamelToSnake)
                );
                const parsedUrl = new URL(url);

                searchParams.forEach(([searchParam, value]) =>
                    parsedUrl.searchParams.set(searchParam, value)
                );

                fetchUrl = String(parsedUrl);

                delete options.search;
            }

            try {
                fetchResponse = await fetch(fetchUrl, options);
            } catch (ex) {
                fetchResponse = { status: 500 };
            }

            const { status } = fetchResponse;

            if ([204, 401, 403, 404, 500].includes(status)) {
                let title = null;

                try {
                    title = (await fetchResponse.json()).title || null;
                } catch (ex) {
                    // Do nothing
                }

                return { data: null, status, title };
            }

            const responseJson = await fetchResponse.json();
            const response = transformKeys(responseJson, convertSnakeToLowerCamel);

            return { ...response, status };
        },
        [url]
    );
    const { fetchOnUrlChange = false } = fetchOptions;
    const [hasLoaded, setHasLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState({ ...defaultOptions, ...fetchOptions });
    const peformFetch = useCallback(async (fetchOptions = {}) => {
        const responsePromise = new Promise((resolve) => {
            responseResolve.current = resolve;
        });

        setTimeout(() => {
            setOptions((options) => {
                return { ...options, ...fetchOptions };
            });
            setDoFetch(true);
            setHasLoaded(true);
        });

        return await responsePromise;
    }, []);
    const [response, setResponse] = useState(null);
    const responseResolve = useRef<(response: any) => void>();

    defer = defer && !hasLoaded;

    useEffect(() => {
        let unmounted = false;

        if (doFetch) {
            const { method = 'get' } = options;
            const isGetRequest = method.toLowerCase() === 'get';
            let fetchPromise;

            delete options.fetchOnUrlChange;
            setLoading(true);
            setResponse(null);

            if (isGetRequest) {
                fetchPromise = inProgressGetRequestPromises[url];

                if (!fetchPromise) {
                    fetchPromise = fetchResponse(options);
                    inProgressGetRequestPromises[url] = fetchPromise;
                }
            } else {
                fetchPromise = fetchResponse(options);
            }

            fetchPromise
                .then((response) => {
                    if (!unmounted) {
                        setDoFetch(false);
                        setResponse(response);
                        setLoading(false);

                        if (responseResolve.current) {
                            responseResolve.current(response);
                        }
                    }
                })
                .catch(() => {
                    if (!unmounted) {
                        setDoFetch(false);
                        setResponse(null);
                        setLoading(false);

                        if (responseResolve.current) {
                            responseResolve.current(null);
                        }
                    }
                })
                .finally(() => {
                    if (isGetRequest) {
                        delete inProgressGetRequestPromises[url];
                    }
                });
        }

        return () => {
            unmounted = true;
        };
    }, [doFetch, fetchResponse, options, url]);

    useEffect(() => {
        setDoFetch(!defer);
    }, [defer]);

    useEffect(() => {
        const { method } = options;

        if (!method || method.toLowerCase() === 'get' || fetchOnUrlChange) {
            setDoFetch(!defer);
        }
    }, [defer, fetchOnUrlChange, options, url]);

    return [response, loading, peformFetch];
}

type IPaymentsApiFetchOptions = IFetchHookOptions & {
    excludeAuthorizationHeader?: boolean;
    handleForbidden?: () => void;
};

export function usePaymentsApiFetch(
    path: string,
    options: IPaymentsApiFetchOptions = defaultOptions
): IFetchHook {
    const {
        excludeAuthorizationHeader,
        handleForbidden = () => window.location.assign('/forbidden')
    } = options;
    const { setPermissions } = usePermissions();

    delete options.excludeAuthorizationHeader;

    const fetchOptions = { ...options };
    const { invalidateToken, token } = useAuthenticationToken();

    if (!excludeAuthorizationHeader) {
        fetchOptions.headers = {
            ...(fetchOptions.headers || {}),
            Authorization: `Bearer ${token}`
        };
    }

    const [response, fetchLoading, performFetch] = useFetch(
        `${process.env.REACT_APP_API_BASE_URL}${path}`,
        fetchOptions
    );
    let loading = fetchLoading;

    if (response && response?.permissions) {
        setTimeout(() => {
            setPermissions(response.permissions);
        });
    }

    if (response?.status === 401) {
        loading = true;

        if (response.title === 'MFA verification is required') {
            window.location.assign(
                `${process.env.REACT_APP_SP_MFA_VERIFICATION_URL}`.replace(
                    ':nextUrl',
                    encodeURIComponent(`${process.env.REACT_APP_BASE_URL}`)
                )
            );
        }

        setTimeout(() => invalidateToken());
    }

    if (response?.status === 403) {
        handleForbidden();
    }

    return [response, loading, performFetch];
}

export function usePaymentsApiMerchantAccountPath(path: string = ''): string {
    const { merchantAccount } = useMerchantAccount();
    const { id } = merchantAccount;

    return `/merchant-account/${id}${path}`;
}

export const useTheme = () => {
    const theme = useSpUiTheme();

    return theme;
};
