import React from 'react';
import { ITransaction } from './types';
import { getDisplayBrand } from '../../services/card';
import { usePaymentsApiFetch, usePaymentsApiMerchantAccountPath } from '../../hooks';

type Card = {
    brand: string;
    last4: string;
};

export interface ITransactionCardProps {
    card: Card | null;
    isTransactionCardLoading?: boolean;
    transaction: ITransaction;
}

interface IWithTransactionCardProps {
    transaction: ITransaction;
}

const withTransactionCard = <T extends object>(
    Component: React.ComponentType<T & ITransactionCardProps>
): React.FC<T & IWithTransactionCardProps> => {
    return ({ transaction, ...props }) => {
        const { id, metadata } = transaction;
        const { sourceType } = metadata;

        if (sourceType === 'pop-up-shop') {
            return (
                <Component
                    card={null}
                    isTransactionCardLoading={false}
                    transaction={transaction}
                    {...(props as T)}
                />
            );
        }

        const transactionPath = usePaymentsApiMerchantAccountPath(
            `/transaction/${id}?expand_payment_method=true`
        );
        const [response, loading] = usePaymentsApiFetch(transactionPath);
        const getCard = () => {
            const card = response?.data?.paymentMethod?.card;

            if (!card) {
                return null;
            }

            const { brand, last4 } = card;

            return {
                brand: getDisplayBrand(brand),
                last4
            };
        };
        const card = getCard();

        return (
            <Component
                card={card}
                isTransactionCardLoading={loading}
                transaction={transaction}
                {...(props as T)}
            />
        );
    };
};

export default withTransactionCard;
