import React from 'react';
import { useParams } from 'react-router-dom';
import { usePaymentsApiFetch } from '../../hooks';

const MerchantAccountVerify: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [response, loading] = usePaymentsApiFetch(`/merchant-account/${id}/account-link`, {
        data: { type: 'verification' },
        method: 'post'
    });

    if (!loading && response) {
        window.location.replace(response.data.url);
    }

    return null;
};

export default MerchantAccountVerify;
