import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { IBalanceTransaction, IPayout } from './types';
import { usePaymentsApiFetch, usePaymentsApiMerchantAccountPath } from '../../hooks';

export interface IPayoutContext {
    getPayouts: () => void;
    getNextPayoutBalanceTransactions: () => void;
    loading: boolean;
    nextPayoutBalanceTransactions: IBalanceTransaction[];
    payouts: IPayout[];
    payoutsMeta: IPayoutsResponseMeta;
}

interface IPayoutsResponseMeta {
    inTransitAmount: number;
}

const PayoutContext = createContext<IPayoutContext>({
    getPayouts: () => {},
    getNextPayoutBalanceTransactions: () => {},
    loading: false,
    nextPayoutBalanceTransactions: [],
    payouts: [],
    payoutsMeta: { inTransitAmount: 0 }
});

export const PayoutProvider: React.FC = ({ ...props }) => {
    const balanceTransactionPath = usePaymentsApiMerchantAccountPath('/balance-transaction');
    const { 0: balanceTransactionResponse, 2: getBalanceTransactions } = usePaymentsApiFetch(
        balanceTransactionPath,
        {
            defer: true
        }
    );
    const payoutPath = usePaymentsApiMerchantAccountPath('/payout?limit=100');
    const [payoutsResponse, loading, getPayouts] = usePaymentsApiFetch(payoutPath, { defer: true });
    const getNextPayoutBalanceTransactions = useCallback(() => {
        getPayouts();
        setIsUpdatingNextPayoutBalanceTransactions(true);
    }, [getPayouts]);
    const [
        isUpdatingNextPayoutBalanceTransactions,
        setIsUpdatingNextPayoutBalanceTransactions
    ] = useState<boolean>(false);
    const [nextPayoutBalanceTransactions, setNextPayoutBalanceTransactions] = useState<
        IBalanceTransaction[]
    >([]);
    const payouts = loading ? [] : payoutsResponse.data;
    const payoutsMeta = loading ? { inTransitAmount: 0 } : payoutsResponse.meta;

    useEffect(() => {
        if (balanceTransactionResponse) {
            const { data: balanceTransaction } = balanceTransactionResponse;

            setNextPayoutBalanceTransactions(balanceTransaction);
        }
    }, [balanceTransactionResponse]);

    useEffect(() => {
        if (!loading && isUpdatingNextPayoutBalanceTransactions) {
            const [mostRecentStandardPayout] = payouts;

            getBalanceTransactions(
                mostRecentStandardPayout
                    ? {
                          search: {
                              endingBefore: mostRecentStandardPayout.id,
                              limit: '100'
                          }
                      }
                    : {}
            );
            setIsUpdatingNextPayoutBalanceTransactions(false);
        }
    }, [getBalanceTransactions, isUpdatingNextPayoutBalanceTransactions, loading, payouts]);

    return (
        <PayoutContext.Provider
            value={{
                getPayouts,
                getNextPayoutBalanceTransactions,
                loading,
                nextPayoutBalanceTransactions,
                payouts,
                payoutsMeta
            }}
            {...props}
        />
    );
};

export function usePayout(): IPayoutContext {
    const context = useContext(PayoutContext);

    return context;
}
