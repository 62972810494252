import React, { useState } from 'react';
import { A, Box, Flex, H5, Span, styled } from 'sp-ui';
import { BankAccountImage } from './BankAccount';
import { usePayoutAccount } from './Context';
import PayoutAccountNewBankAccountButton from './NewBankAccountButton';
import { IPendingBankAccount } from './types';
import { Message } from '../intl';
import { usePaymentsApiFetch } from '../../hooks';
import Menu, { IMenuItem } from '../Menu';

export const BorderedBox = styled(Box)(
    ({ theme }) => `
        border: 1px solid ${theme.colors.gray[200]};
        border-radius: 4px;
    `
);

interface IBorderedPendingBankAccountProps {
    pendingBankAccount: IPendingBankAccount;
}

export const BorderedPendingBankAccount: React.FC<IBorderedPendingBankAccountProps> = ({
    pendingBankAccount,
    ...props
}) => (
    <BorderedBox {...props}>
        <PendingBankAccount pendingBankAccount={pendingBankAccount} showMenu={false} />
    </BorderedBox>
);

interface IPendingBankAccountProps {
    onCancelPendingBankAccount?: (pendingBankAccountId: string) => void;
    pendingBankAccount: IPendingBankAccount;
    showMenu: boolean;
}

const PendingBankAccount: React.FC<IPendingBankAccountProps> = ({
    onCancelPendingBankAccount,
    pendingBankAccount,
    showMenu
}) => {
    const { getPayoutAccounts } = usePayoutAccount();
    const { id, last4, verificationStatus } = pendingBankAccount;
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const { 2: updatePlaidPendingBankAccount } = usePaymentsApiFetch(
        `/plaid/link-token/pending-bank-account/${id}`,
        {
            defer: true,
            method: 'put'
        }
    );

    return (
        <Flex alignItems="center" padding="16px 20px">
            <BankAccountImage flexShrink={0} isPending marginRight="20px" width="40px" />
            <PendingBankAccountContent>
                <PendingBankAccountDetails>
                    <H5>
                        {verificationStatus === 'verification_expired' ? (
                            <Message id="payoutAccount.pendingBankAccount.expiredBankName" />
                        ) : (
                            <Message id="payoutAccount.pendingBankAccount.bankName" />
                        )}
                    </H5>
                    <Span>{`•••• ${last4}`}</Span>
                </PendingBankAccountDetails>
                {verificationStatus === 'manually_verified' && (
                    <A
                        isDisabled={isUpdating}
                        onClick={() => {
                            setIsUpdating(true);
                            setTimeout(async () => {
                                await updatePlaidPendingBankAccount();
                                getPayoutAccounts();
                            });
                        }}>
                        {isUpdating ? (
                            <Message id="payoutAccount.pendingBankAccount.updating" />
                        ) : (
                            <Message id="payoutAccount.pendingBankAccount.verifyLink" />
                        )}
                    </A>
                )}
                {verificationStatus === 'needs_reauthentication' && (
                    <Box flexShrink={0}>
                        <PayoutAccountNewBankAccountButton
                            isLink
                            isReauthenticating
                            onPlaidReauthenticated={getPayoutAccounts}
                            plaidPendingBankAccountId={id}>
                            <Message id="payoutAccount.pendingBankAccount.reauthenticateLink" />
                        </PayoutAccountNewBankAccountButton>
                    </Box>
                )}
                {verificationStatus === 'pending_manual_verification' && showMenu && (
                    <Box flexShrink={0} pointerEvents={isUpdating ? 'none' : undefined}>
                        <PayoutAccountNewBankAccountButton
                            isLink
                            onCreated={() => {
                                getPayoutAccounts();
                            }}
                            onPlaidCreated={() => setIsUpdating(true)}
                            plaidPendingBankAccountId={id}>
                            {isUpdating ? (
                                <Span>
                                    <Message id="payoutAccount.pendingBankAccount.updating" />
                                </Span>
                            ) : (
                                <Message id="payoutAccount.pendingBankAccount.verifyLink" />
                            )}
                        </PayoutAccountNewBankAccountButton>
                    </Box>
                )}
                {verificationStatus === 'verification_expired' && showMenu && (
                    <Box flexShrink={0} pointerEvents={isUpdating ? 'none' : undefined}>
                        <PayoutAccountNewBankAccountButton
                            deleteExpiredOnCreate
                            isLink
                            onCreated={() => {
                                getPayoutAccounts();
                            }}
                            onPlaidCreated={() => setIsUpdating(true)}>
                            {isUpdating ? (
                                <Span>
                                    <Message id="payoutAccount.pendingBankAccount.updating" />
                                </Span>
                            ) : (
                                <Message id="payoutAccount.pendingBankAccount.addBankLink" />
                            )}
                        </PayoutAccountNewBankAccountButton>
                    </Box>
                )}
            </PendingBankAccountContent>
            {showMenu && (
                <PendingBankAccountMenu
                    onCancelPendingBankAccount={onCancelPendingBankAccount}
                    pendingBankAccountId={id}
                />
            )}
        </Flex>
    );
};

const PendingBankAccountContent = styled(Flex)(
    ({ theme }) => `
        flex: 1;
        justify-content: space-between;
        width: 100%;

        ${theme.responsive.mediumScreenUp`
            align-items: center;
        `}

        ${theme.responsive.smallScreenOnly`
            align-items: flex-start;
            flex-direction: column;
        `}
    `
);

const PendingBankAccountDetails = styled(Box)(
    ({ theme }) => `
        ${theme.responsive.smallScreenOnly`
            margin-bottom: 5px;
        `}
    `
);

interface IPendingBankAccountMenuProps {
    onCancelPendingBankAccount?: (pendingBankAccountId: string) => void;
    pendingBankAccountId: string;
}

export const PendingBankAccountMenu: React.FC<IPendingBankAccountMenuProps> = ({
    onCancelPendingBankAccount,
    pendingBankAccountId
}) => {
    const menuItems: IMenuItem[] = [
        {
            isDestructive: true,
            onClick: async () => {
                if (onCancelPendingBankAccount) {
                    onCancelPendingBankAccount(pendingBankAccountId);
                }
            },
            text: 'settings.payoutSettings.cancelPendingBankAccountMenuItem'
        }
    ];

    return <Menu items={menuItems} marginLeft="8px" />;
};

export default PendingBankAccount;
