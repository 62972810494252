import { yup } from 'sp-ui';

export enum PayoutScheduleInterval {
    Daily = 'daily',
    Monthly = 'monthly',
    Weekly = 'weekly'
}

export const businessNameValidation = yup
    .string()
    .max(200, 'settings.businessNameMaxValidationError')
    .min(5, 'settings.businessNameMinValidationError')
    .required('settings.businessNameRequiredValidationError');

export const phoneValidation = yup
    .string()
    .required('settings.notificationPhoneRequiredValidationError')
    .min(10, 'settings.notificationPhoneMinValidationError')
    .max(20, 'settings.notificationPhoneMaxValidationError')
    .phoneNumber('form.phoneInput.invalidValidationError');

export const statementDescriptorValidation = yup
    .string()
    .matches(/^[A-Za-z0-9-_.]{5,22}$/, {
        excludeEmptyString: true,
        message: 'settings.statementDescriptorInvalid'
    })
    .required('settings.statementDescriptorRequiredValidationError');
