import React, { useCallback } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumberParts, useIntl } from 'react-intl';

interface ICurrencyProps {
    currency: string;
    value: null | number;
}

export const Currency: React.FC<ICurrencyProps> = ({ currency, value }) => {
    const isEmptyValue = value === null;

    return (
        <FormattedNumberParts
            currency={currency}
            // eslint-disable-next-line
            style="currency"
            value={isEmptyValue ? 0 : Number(value)}>
            {(parts) => (
                <>
                    {parts
                        .map(({ type, value }) => {
                            if (isEmptyValue) {
                                if (type === 'integer') {
                                    return '—';
                                } else if (type === 'fraction') {
                                    return '——';
                                }
                            }

                            return value;
                        })
                        .join('')}
                </>
            )}
        </FormattedNumberParts>
    );
};

interface IDateProps {
    excludeYear?: boolean;
    ignoreTime?: boolean;
    includeTime?: boolean;
    shortFormat?: boolean;
    value: string;
}

export const Date: React.FC<IDateProps> = ({
    excludeYear = false,
    ignoreTime = false,
    includeTime = false,
    shortFormat = false,
    value
}) => {
    const month = shortFormat ? 'short' : 'long';
    const timeFormat = includeTime ? 'numeric' : undefined;
    const timeZone = ignoreTime ? 'utc' : undefined;
    const yearFormat = excludeYear ? undefined : 'numeric';

    return (
        <FormattedDate
            day="2-digit"
            hour={timeFormat}
            minute={timeFormat}
            month={month}
            timeZone={timeZone}
            value={value}
            year={yearFormat}
        />
    );
};

interface IMessageProps {
    id?: string;
    values?: Record<string, React.ReactElement | boolean | number | string>;
}

export const Message: React.FC<IMessageProps> = ({ id, values }) => {
    if (!id) {
        return <>?</>;
    }

    return <FormattedMessage id={id} values={values} />;
};

export function useCurrency(currency: string): (value: number) => string {
    const { formatNumber } = useIntl();

    return (value) => formatNumber(value, { currency, style: 'currency' });
}

export function useCurrencySymbol(currency: string): string {
    const { formatNumberToParts } = useIntl();

    return (
        formatNumberToParts(0, { style: 'currency', currency }).find(
            ({ type }) => type === 'currency'
        )?.value || '?'
    );
}

export function useMessage(): (
    id?: string,
    values?: Record<string, boolean | number | string>
) => string {
    const { formatMessage } = useIntl();
    const t = useCallback(
        (id, values = {}) => {
            if (!id) {
                return '?';
            }

            return formatMessage({ id }, values);
        },
        [formatMessage]
    );

    return t;
}
