import React, { ReactNode } from 'react';
import { Box, Flex, styled } from 'sp-ui';
import { NavLink, Route, Switch } from 'react-router-dom';
import { useMerchantAccount } from './merchant-account';
import { RouteProps } from 'react-router';

interface ISideMenuTabs {
    component: () => ReactNode;
    exact: boolean;
    icon?: ReactNode;
    path: string;
    text: string;
}

interface ISideMenuTabsProps {
    tabs: ISideMenuTabs[];
}

const SideMenu = styled(Box)(
    ({ theme }) => `
        flex: 0 1 265px;
        margin-right: 20px;
        padding-top: 40px;
    
        ${theme.responsive.smallScreenOnly`
            display: none;
        `}
    `
);

const SideMenuDetail = styled(Box)(
    ({ theme }) => `
        flex: 4;
        height: 100%;
        overflow-y: auto;
        padding-top: 40px;
    
        ${theme.responsive.smallScreenOnly`
            padding-top: 0;
        `}
    `
);

const SideMenuTab = styled(NavLink)(
    ({ theme }) => `
        color: ${theme.colors.gray[600]};
        background-color: transparent;
        display: block;
        font-size: ${theme.fontSizes.md};
        font-weight: ${theme.fontWeights.medium};
        padding: 10px 20px;
        
        svg {
            fill: ${theme.colors.gray[600]};
            margin-right: 10px;
        }
        
        &.active {
            color: ${theme.colors.blue[600]};
            background-color: #edf9fc;
            
            svg {
                fill: ${theme.colors.blue[600]};
            }
        }
        
        &:hover {
            color: ${theme.colors.blue[600]};
            
                svg {
                    fill: ${theme.colors.blue[600]};
                }
        }
    `
);

const SideMenuTabsContainer = styled(Flex)(
    ({ theme }) => `
        height: 100%;
    
        ${theme.responsive.smallScreenOnly`
            flex-direction: column;
        `}
    `
);

const SideMenuTabs: React.FC<ISideMenuTabsProps & RouteProps> = ({ tabs, ...props }) => {
    const { merchantAccount } = useMerchantAccount();
    const { id } = merchantAccount;

    return (
        <SideMenuTabsContainer tabs={tabs} {...props}>
            <SideMenu data-testid="side-menu">
                {tabs.map((tab, index) => {
                    const { icon, path, text } = tab;

                    return (
                        <SideMenuTab
                            data-testid="side-menu-tab"
                            key={index}
                            to={`/payment-account/${id}/${path}`}>
                            <Flex alignItems="center">
                                {icon} {text}
                            </Flex>
                        </SideMenuTab>
                    );
                })}
            </SideMenu>
            <SideMenuDetail data-testid="side-menu-detail">
                <Switch>
                    {tabs.map(({ component, exact, path }, index) => (
                        <Route
                            render={component}
                            path={`/payment-account/${id}/${path}`}
                            key={index}
                            exact={exact}
                        />
                    ))}
                </Switch>
            </SideMenuDetail>
        </SideMenuTabsContainer>
    );
};

export default SideMenuTabs;
