import React, { useEffect } from 'react';
import { Box, Flex, FormElementVariant, styled, useField, useFormikContext } from 'sp-ui';
import { ReactComponent as ChevronRight } from 'sp-ui/dist/assets/svg/chevron-right.svg';
import { ReactComponent as CompanyImage } from '../../assets/svg/business-type/company.svg';
import { ReactComponent as IndividualImage } from '../../assets/svg/business-type/individual.svg';
import { MerchantAccountBusinessType } from './common';
import { FormButton, FormField, SplitChoiceRadioGroup } from '../form';
import { IFormButtonProps } from '../form/Button';
import { useMessage } from '../intl';

export const MerchantAccountFormBusinessTypeRadioGroup: React.FC = () => {
    const choices = [
        {
            description: 'merchantAccountForm.individualOptionDescription',
            image: IndividualImage,
            label: 'merchantAccountForm.individualOptionLabel',
            value: MerchantAccountBusinessType.Individual
        },
        {
            description: 'merchantAccountForm.companyOptionDescription',
            image: CompanyImage,
            label: 'merchantAccountForm.companyOptionLabel',
            value: MerchantAccountBusinessType.Company
        }
    ];

    return <SplitChoiceRadioGroup choices={choices} name="businessType" />;
};

export const MerchantAccountFormCountrySelect: React.FC = (props) => (
    <FormField
        label="merchantAccountForm.countryLabel"
        name="country"
        selectOptions={[{ label: 'United States', value: 'US' }]}
        variant={FormElementVariant.Select}
        {...props}
    />
);

const SplitFlex = styled(Flex)`
    justify-content: space-between;

    > div {
        width: calc(50% - 8px);
    }
`;

export const MerchantAccountFormNameFields: React.FC = () => {
    const [field] = useField('businessType');
    const { value } = field;
    const isCompany = value === MerchantAccountBusinessType.Company;
    const isIndividual = value === MerchantAccountBusinessType.Individual;
    const t = useMessage();

    return (
        <>
            {isCompany && (
                <FormField
                    isRequired
                    label="merchantAccountForm.companyNameLabel"
                    name="companyLegalName"
                    placeholder={t('merchantAccountForm.companyNamePlaceholder')}
                />
            )}
            {isIndividual && (
                <SplitFlex>
                    <FormField
                        isRequired
                        label="merchantAccountForm.individualFirstNameLabel"
                        name="individualFirstName"
                        placeholder={t('merchantAccountForm.individualFirstNamePlaceholder')}
                    />
                    <FormField
                        isRequired
                        label="merchantAccountForm.individualLastNameLabel"
                        name="individualLastName"
                        placeholder={t('merchantAccountForm.individualLastNamePlaceholder')}
                    />
                </SplitFlex>
            )}
        </>
    );
};

interface IMerchantAccountFormSubmitButtonProps {
    onClick?: (event: React.MouseEvent, values: unknown) => void;
    validateFormOnRender?: boolean;
}

export const MerchantAccountFormSubmitButton: React.FC<
    IMerchantAccountFormSubmitButtonProps & Omit<IFormButtonProps, 'onClick'>
> = ({ onClick = () => {}, validateFormOnRender, ...props }) => {
    const { validateForm, values } = useFormikContext();

    useEffect(() => {
        if (validateFormOnRender) {
            validateForm();
        }
    }, [validateForm, validateFormOnRender]);

    return (
        <FormButton
            aria-disabled={false}
            disableUntilFormValid
            loadedText={undefined}
            loadingText={undefined}
            message="continueButton"
            onClick={(event) => {
                onClick(event, values);
            }}
            {...props}>
            <Box height="10px" marginLeft="10px" width="6px">
                <ChevronRight fill="currentColor" />
            </Box>
        </FormButton>
    );
};
