export interface ITransactionFailureCode {
    status: string;
    statusTooltip: string;
    statusBanner: string;
}

const TRANSACTION_FAILURE_CODE_MAP = {
    card_declined: {
        status: 'transactionStatus.failed',
        statusBanner: 'transactionStatusBannerFailed.cardDeclined.genericDecline',
        statusTooltip: 'transactionStatusTooltipFailed.cardDeclined.genericDecline'
    },
    'card_declined.generic_decline': {
        status: 'transactionStatus.failed',
        statusBanner: 'transactionStatusBannerFailed.cardDeclined.genericDecline',
        statusTooltip: 'transactionStatusTooltipFailed.cardDeclined.genericDecline'
    },
    'card_declined.insufficient_funds': {
        status: 'transactionStatus.failed',
        statusBanner: 'transactionStatusBannerFailed.cardDeclined.insufficientFunds',
        statusTooltip: 'transactionStatusTooltipFailed.cardDeclined.insufficientFunds'
    },
    'card_declined.lost_card': {
        status: 'transactionStatus.failed',
        statusBanner: 'transactionStatusBannerFailed.cardDeclined.lostCard',
        statusTooltip: 'transactionStatusTooltipFailed.cardDeclined.lostCard'
    },
    'card_declined.stolen_card': {
        status: 'transactionStatus.failed',
        statusBanner: 'transactionStatusBannerFailed.cardDeclined.stolenCard',
        statusTooltip: 'transactionStatusTooltipFailed.cardDeclined.stolenCard'
    },
    expired_card: {
        status: 'transactionStatus.failed',
        statusBanner: 'transactionStatusBannerFailed.expiredCard',
        statusTooltip: 'transactionStatusTooltipFailed.expiredCard'
    },
    incorrect_cvc: {
        status: 'transactionStatus.failed',
        statusBanner: 'transactionStatusBannerFailed.incorrectCvc',
        statusTooltip: 'transactionStatusTooltipFailed.incorrectCvc'
    },
    incorrect_zip: {
        status: 'transactionStatus.failed',
        statusBanner: 'transactionStatusBannerFailed.incorrectZip',
        statusTooltip: 'transactionStatusTooltipFailed.incorrectZip'
    },
    processing_error: {
        status: 'transactionStatus.failed',
        statusBanner: 'transactionStatusBannerFailed.processingError',
        statusTooltip: 'transactionStatusTooltipFailed.processingError'
    }
};

export function getCardFailureTranslation(
    failureCode: string | null,
    declineCode: string | null
): ITransactionFailureCode {
    const unexpectedError = {
        status: 'transactionStatus.failed',
        statusBanner: 'transactionStatusBannerFailed.unexpectedError',
        statusTooltip: 'transactionStatusTooltipFailed.unexpectedError'
    };

    if (failureCode && declineCode && declineCode !== 'fraudulent') {
        return (
            TRANSACTION_FAILURE_CODE_MAP[`${failureCode}.${declineCode}`] ||
            TRANSACTION_FAILURE_CODE_MAP[failureCode] ||
            unexpectedError
        );
    }

    return failureCode
        ? TRANSACTION_FAILURE_CODE_MAP[failureCode] || unexpectedError
        : TRANSACTION_FAILURE_CODE_MAP['card_declined.generic_decline'];
}

export function getDisplayBrand(brand: string | null): string {
    return (
        {
            amex: 'American Express',
            diners: 'Diners Club',
            discover: 'Discover',
            jcb: 'JCB',
            mastercard: 'MasterCard',
            unionpay: 'UnionPay',
            unknown: 'Unknown',
            visa: 'Visa'
        }[brand || 'unknown'] || ''
    );
}
