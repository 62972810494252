import { parsePhoneNumber } from 'libphonenumber-js';
import React, { useState } from 'react';
import { A, Banner, FormElementVariant, InfoIcon, LoadingDialog, P, styled, yup } from 'sp-ui';
import { businessNameValidation, statementDescriptorValidation } from './common';
import SettingsDetail from './Detail';
import { FormField } from '../form';
import { useMerchantAccount } from '../merchant-account';
import { GetNotificationPreference } from '../notification-preference';
import { Message, useMessage } from '../intl';
import { phoneValidation } from '../settings';
import { usePaymentsApiFetch, usePaymentsApiMerchantAccountPath } from '../../hooks';
import { noop } from '../../utils';

type BusinessInformationFormValues = {
    businessName: string;
    statementDescriptor: string;
};

type NotifiationPreferenceFormValues = {
    notificationPhone: string;
};

const AccountInformation: React.FC = () => {
    const accountLinkPath = usePaymentsApiMerchantAccountPath('/account-link');
    const [accountLinkResponse] = usePaymentsApiFetch(accountLinkPath, {
        data: { type: 'update' },
        fetchOnUrlChange: true,
        handleForbidden: noop,
        method: 'post'
    });
    const accountLinkUrl = accountLinkResponse?.data?.url;
    const { merchantAccount, updateMerchantAccount } = useMerchantAccount();
    const { name: businessName, statementDescriptor } = merchantAccount;
    const initialValues = {
        businessName,
        statementDescriptor
    };
    const [notificationPhone, setNotificationPhone] = useState<string>('');
    const [notificationPreferenceLoaded, setNotificationPreferenceLoaded] = useState<boolean>(
        false
    );
    const notificationPreferenceInitialValues = {
        notificationPhone
    };
    const notificationPreferenceValidationSchema = yup.object().shape({
        notificationPhone: phoneValidation
    });
    const onNotificationPreferenceGot = ({ phone }) => {
        setNotificationPhone(phone);
        setNotificationPreferenceLoaded(true);
    };
    const { 2: patchNotificationPreference } = usePaymentsApiFetch('/notification-preference', {
        defer: true,
        method: 'patch'
    });
    const [redirectToAccountUpdate, setRedirectToAccountUpdate] = useState<boolean>(false);
    const t = useMessage();
    const updateBusinessInformation = async (
        { businessName, statementDescriptor },
        { setErrors }
    ) => {
        const { status } = await updateMerchantAccount({ businessName, statementDescriptor });

        if (status === 409) {
            setErrors({
                businessName: 'merchantAccountNew.businessNameDuplicateError'
            });
        }
    };
    const updateNotificationPreference = async (
        { notificationPhone },
        { resetForm, setErrors }
    ) => {
        const phone = parsePhoneNumber(notificationPhone, 'US').number;
        const { status } = await patchNotificationPreference({
            data: { phone }
        });

        if (status !== 200) {
            setErrors({
                notificationPhone: 'settings.accountInformation.notificationPhoneError'
            });
        } else {
            setTimeout(() => {
                resetForm({ values: { notificationPhone } });
            });
        }
    };
    const validationSchema = yup.object().shape({
        businessName: businessNameValidation,
        statementDescriptor: statementDescriptorValidation
    });

    if (redirectToAccountUpdate && accountLinkUrl) {
        window.location.assign(accountLinkUrl);
    }

    return notificationPreferenceLoaded ? (
        <>
            {accountLinkUrl && (
                <AccountInformationBanner data-testid="account-information-banner">
                    <InfoIcon />
                    <P>
                        <Message id="settings.accountInformation.bannerDescription" />
                        <A onClick={() => setRedirectToAccountUpdate(true)}>
                            <Message id="settings.accountInformation.bannerLink" />
                        </A>
                    </P>
                </AccountInformationBanner>
            )}
            <SettingsDetail<BusinessInformationFormValues>
                data-testid="account-information-business-information"
                heading={t('settings.accountInformation.businessInformationHeading')}
                initialValues={initialValues}
                marginBottom="20px"
                onSubmit={updateBusinessInformation}
                validationSchema={validationSchema}>
                <FormField
                    isRequired
                    label="settings.accountInformation.doingBusinessAsLabel"
                    labelSubtext={t('settings.accountInformation.doingBusinessAsLabelSubtext')}
                    name="businessName"
                />
                <FormField
                    isRequired
                    label="settings.accountInformation.statementDescriptorLabel"
                    labelSubtext={t('settings.accountInformation.statementDescriptorSubtext')}
                    name="statementDescriptor"
                />
            </SettingsDetail>
            <SettingsDetail<NotifiationPreferenceFormValues>
                data-testid="account-information-notification-preferences"
                heading={t('settings.accountInformation.notificationPreferencesHeading')}
                initialValues={notificationPreferenceInitialValues}
                marginBottom="20px"
                onSubmit={updateNotificationPreference}
                validationSchema={notificationPreferenceValidationSchema}>
                <FormField
                    isRequired
                    label="settings.accountInformation.notificationPhoneLabel"
                    labelSubtext={t('settings.accountInformation.notificationPhoneSubtext')}
                    name="notificationPhone"
                    variant={FormElementVariant.PhoneInput}
                />
            </SettingsDetail>
        </>
    ) : (
        <>
            <GetNotificationPreference onGot={onNotificationPreferenceGot} />
            <LoadingDialog description="" />
        </>
    );
};

export const AccountInformationBanner = styled(Banner)`
    margin-bottom: 20px;

    svg {
        flex: 0 0 24px;
        margin-right: 16px;
    }
`;

export default AccountInformation;
