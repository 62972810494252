import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Banner, BannerVariant, Box, Flex, H3, LoadingDialog, P, styled } from 'sp-ui';

import { ReactComponent as WarningIcon } from 'sp-ui/dist/assets/svg/warning.svg';
import { ReactComponent as AddBankImage } from '../../assets/svg/add-bank.svg';
import { MerchantAccountFormSubmitButton } from '../../components/merchant-account';
import {
    OnboardingForm,
    OnboardingFormContent,
    OnboardingPageFooter,
    Step
} from '../../components/onboarding';
import {
    BankAccount,
    IBankAccount,
    IPayoutAccount,
    IPendingBankAccount,
    usePayoutAccount
} from '../../components/payout-account';
import { ConfirmCancelPendingBankAccount } from '../../components/settings';
import { PayoutAccountNewBankAccountButton } from '../../components/payout-account';
import { Message, useMessage } from '../../components/intl';

export const AddBankBox = styled(Flex, {
    shouldForwardProp: (prop) => prop !== 'isHidden'
})(
    ({ isHidden, theme }) => `
        border: 1px solid ${theme.colors.gray[200]};
        border-radius: 4px;
        padding: 14px 20px 13px 10px;

        ${isHidden ? 'display: none;' : ''}

        div {
            flex: 1;
        }

        p {
            line-height: 24px;
        }

        svg {
            flex: 0 0 142px;
            height: 133px;
            margin-right: 12px;
        }

        ${theme.responsive.smallScreenOnly`
            align-items: center;
            flex-direction: column;
            padding: 10px 16px 16px;

            [type="submit"] {
                width: 100%;
            }

            div {
                text-align: center;
            }

            p {
                margin-top: 0;
            }

            svg {
                margin-bottom: 12px;
                margin-right: 0;
            }
        `}
    `
);

interface IGetBankAccountProps {
    withBankAccount: (bankAccount: IPayoutAccount | null) => void;
    withPendingBankAccount: (pendingBankAccount: IPendingBankAccount) => void;
}

export const GetBankAccount: React.FC<IGetBankAccountProps> = ({
    withBankAccount,
    withPendingBankAccount
}) => {
    const { payoutAccounts, pendingBankAccounts } = usePayoutAccount();

    useEffect(() => {
        const [bankAccount = null] = payoutAccounts.filter(({ type }) => type === 'bank_account');
        const [pendingBankAccount] = pendingBankAccounts;

        if (bankAccount || !pendingBankAccount) {
            withBankAccount(bankAccount);
        } else {
            withPendingBankAccount(pendingBankAccount);
        }
    }, [payoutAccounts, pendingBankAccounts, withBankAccount, withPendingBankAccount]);

    return null;
};

interface IPayoutAccountBankAccountFormSubmitButtonProps {
    bankAccountAdded: boolean;
    isPendingBankAccountChosen: boolean;
}

export const PayoutAccountBankAccountFormSubmitButton: React.FC<IPayoutAccountBankAccountFormSubmitButtonProps> = ({
    bankAccountAdded,
    isPendingBankAccountChosen
}) => {
    const { id } = useParams<{ id: string }>();
    const onboardingSettingsEditPath = `/payment-account/${id}/onboarding/settings/edit`;
    const { push } = useHistory();

    return (
        <MerchantAccountFormSubmitButton
            disabled={!bankAccountAdded || isPendingBankAccountChosen}
            onClick={() => {
                push(onboardingSettingsEditPath);
            }}
        />
    );
};

const OnboardingPayoutAccountNewBankAccountPage: React.FC = () => {
    const [bankAccount, setBankAccount] = useState<IBankAccount | null>(null);
    const [hasErroredPendingBankAccount, setHasErroredPendingBankAccount] = useState<boolean>(
        false
    );
    const [hasExpiredPendingBankAccount, setHasExpiredPendingBankAccount] = useState<boolean>(
        false
    );
    const [pendingBankAccountIdToRemove, setPendingBankAccountIdToRemove] = useState<string | null>(
        null
    );
    const [showLoading, setShowLoading] = useState<boolean>(true);
    const isPageLoading = showLoading;
    const isPendingBankAccountChosen = Boolean(bankAccount?.verificationStatus);
    const { getPayoutAccounts, payoutAccountsLoading } = usePayoutAccount();
    const t = useMessage();
    const setBankAccountFromBankAccount = useCallback(
        (existingBankAccount: IPayoutAccount | null) => {
            setShowLoading(false);

            if (!existingBankAccount) {
                return;
            }

            const { accountSubtype, bankName, last4 } = existingBankAccount;
            const getDisplayAccountSubtype = (accountSubtype) => {
                if (accountSubtype === 'checking') {
                    return t('payoutAccount.accountSubtype.checking');
                } else if (accountSubtype === 'savings') {
                    return t('payoutAccount.accountSubtype.savings');
                }

                return '';
            };
            const accountName = bankName || getDisplayAccountSubtype(accountSubtype);
            const bankAccount = {
                accountNumberMask: last4,
                accountName,
                verificationStatus: null,
                wasMicrodepositVerified: !bankName
            };

            setBankAccount(bankAccount);
        },
        [t]
    );
    const setBankAccountFromPendingBankAccount = useCallback(
        (pendingBankAccount: IPendingBankAccount) => {
            const { id, last4, verificationStatus } = pendingBankAccount;
            const bankAccount = {
                accountNumberMask: last4,
                plaidPendingBankAccountId: id,
                verificationStatus
            };

            if (verificationStatus === 'errored') {
                setHasErroredPendingBankAccount(true);
            } else if (verificationStatus === 'verification_expired') {
                setHasExpiredPendingBankAccount(true);
            } else {
                setBankAccount(bankAccount);
            }

            setShowLoading(false);
        },
        []
    );
    const [showNewBankAccountLoading, setShowNewBankAccountLoading] = useState<boolean>(false);

    return (
        <>
            <OnboardingForm initialValues={{}}>
                <OnboardingFormContent currentStep={Step.Third}>
                    <H3>
                        <Message id="onboarding.payoutAccountNewBankAccount.heading" />
                    </H3>
                    <P>
                        <Message id="onboarding.payoutAccountNewBankAccount.description" />
                    </P>
                    {isPageLoading ? (
                        <LoadingDialog description="" />
                    ) : (
                        <>
                            {hasErroredPendingBankAccount && (
                                <Banner marginBottom="20px" variant={BannerVariant.Warning}>
                                    <Box height="30px" marginRight="12px" width="30px">
                                        <WarningIcon height="100%" width="100%" />
                                    </Box>
                                    <P>
                                        <Message id="onboarding.payoutAccountNewBankAccount.hasErroredPlaidBankAccountBanner" />
                                    </P>
                                </Banner>
                            )}
                            {hasExpiredPendingBankAccount && (
                                <Banner marginBottom="20px" variant={BannerVariant.Warning}>
                                    <Box height="30px" marginRight="12px" width="30px">
                                        <WarningIcon height="100%" width="100%" />
                                    </Box>
                                    <P>
                                        <Message id="onboarding.payoutAccountNewBankAccount.hasExpiredPlaidBankAccountBanner" />
                                    </P>
                                </Banner>
                            )}
                            {showNewBankAccountLoading ? (
                                <LoadingDialog description="" />
                            ) : (
                                bankAccount &&
                                !hasExpiredPendingBankAccount && (
                                    <BankAccount
                                        bankAccount={bankAccount}
                                        onCancel={setPendingBankAccountIdToRemove}
                                        onReauthenticated={getPayoutAccounts}
                                        onVerified={setBankAccount}
                                    />
                                )
                            )}
                            {pendingBankAccountIdToRemove && (
                                <ConfirmCancelPendingBankAccount
                                    close={() => {
                                        setPendingBankAccountIdToRemove(null);
                                    }}
                                    isSettings={false}
                                    pendingBankAccountId={pendingBankAccountIdToRemove}
                                    onCanceled={() => {
                                        setBankAccount(null);
                                        setPendingBankAccountIdToRemove(null);
                                    }}
                                />
                            )}
                        </>
                    )}
                    {(!bankAccount || hasExpiredPendingBankAccount) && (
                        <AddBankBox isHidden={isPageLoading || showNewBankAccountLoading}>
                            <AddBankImage />
                            <Box>
                                <P marginBottom="10px" marginTop="8px">
                                    <Message id="onboarding.payoutAccountNewBankAccount.addDescription" />
                                </P>
                                {!payoutAccountsLoading && (
                                    <PayoutAccountNewBankAccountButton
                                        deleteExpiredOnCreate
                                        onCreateError={() => {
                                            setShowNewBankAccountLoading(false);
                                        }}
                                        onCreated={(bankAccount) => {
                                            getPayoutAccounts();
                                            setBankAccount(bankAccount);
                                            setShowNewBankAccountLoading(false);
                                        }}
                                        onPlaidCreated={() => {
                                            setHasErroredPendingBankAccount(false);
                                            setHasExpiredPendingBankAccount(false);
                                            setShowNewBankAccountLoading(true);
                                        }}>
                                        <Message id="onboarding.payoutAccountNewBankAccount.addButton" />
                                    </PayoutAccountNewBankAccountButton>
                                )}
                            </Box>
                        </AddBankBox>
                    )}
                </OnboardingFormContent>
                <OnboardingPageFooter hasClose>
                    <PayoutAccountBankAccountFormSubmitButton
                        bankAccountAdded={Boolean(bankAccount)}
                        isPendingBankAccountChosen={isPendingBankAccountChosen}
                    />
                </OnboardingPageFooter>
            </OnboardingForm>
            <GetBankAccount
                withBankAccount={setBankAccountFromBankAccount}
                withPendingBankAccount={setBankAccountFromPendingBankAccount}
            />
        </>
    );
};

export default OnboardingPayoutAccountNewBankAccountPage;
