import React, { createContext, useContext, useState } from 'react';

export interface IPermissionsContext {
    hasPermission: (permission: Permission) => boolean;
    setPermissions: (permissions: Permission[]) => void;
}

export enum Permission {
    AddDebitCard = 'pay.add_debit_card',
    Admin = 'pay.admin',
    InstantPayout = 'pay.instant_payout',
    Read = 'pay.read'
}

export const PermissionsContext = createContext<IPermissionsContext>({
    hasPermission: () => false,
    setPermissions: () => {}
});

export const PermissionsProvider: React.FC = (props) => {
    const [permissions, setPermissions] = useState<Permission[]>([]);
    const hasPermission = (permission) => permissions.includes(permission);

    return (
        <PermissionsContext.Provider
            value={{
                hasPermission,
                setPermissions: (nextPermissions) => {
                    const havePermissionsUpdated =
                        permissions.length !== nextPermissions.length ||
                        permissions.some((permission) => !nextPermissions.includes(permission));

                    if (havePermissionsUpdated) {
                        setPermissions(nextPermissions);
                    }
                }
            }}
            {...props}
        />
    );
};

export function usePermissions(): IPermissionsContext {
    const context = useContext(PermissionsContext);

    return context;
}
