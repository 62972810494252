export enum PayoutAccountStatus {
    Errored = 'errored',
    New = 'new',
    Validated = 'validated',
    VerificationFailed = 'verification_failed',
    Verified = 'verified'
}

export enum PayoutAccountType {
    BankAccount = 'bank_account',
    DebitCard = 'card_account'
}
