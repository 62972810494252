import { parsePhoneNumber } from 'libphonenumber-js';
import React, { useCallback, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { A, Banner, FormElementVariant, H3, H5, InfoIcon, P, styled, yup } from 'sp-ui';
import { ReactComponent as PoweredByStripeBadge } from '../assets/svg/app/powered-by-stripe.svg';
import { FormField } from '../components/form';
import {
    MerchantAccountBusinessType,
    MerchantAccountFormBusinessTypeRadioGroup,
    MerchantAccountFormCountrySelect,
    MerchantAccountFormNameFields,
    MerchantAccountFormSubmitButton
} from '../components/merchant-account';
import { GetNotificationPreference } from '../components/notification-preference';
import {
    OnboardingForm,
    OnboardingFormContent,
    OnboardingPageFooter,
    OnboardingPageHeader,
    Step
} from '../components/onboarding';
import { businessNameValidation, phoneValidation } from '../components/settings';
import Pendo from '../components/Pendo';
import { Message, useMessage } from '../components/intl';
import { usePaymentsApiFetch } from '../hooks';

export const BusinessInfoSection: React.FC = () => (
    <>
        <H3>
            <Message id="merchantAccountNew.businessTypeHeading" />
        </H3>
        <P>
            <Message id="merchantAccountNew.businessTypeDescription" />
        </P>
        <H5>
            <Message id="merchantAccountNew.businessTypeSubheading" />
        </H5>
        <P>
            <Message id="merchantAccountNew.businessTypeSubdescription" />
        </P>
        <MerchantAccountFormBusinessTypeRadioGroup />
        <MerchantAccountFormNameFields />
    </>
);

export const DoingBusinessAsSection: React.FC = () => {
    const t = useMessage();

    return (
        <>
            <H3>
                <Message id="merchantAccountNew.doingBusinessAsHeading" />
            </H3>
            <P>
                <Message id="merchantAccountNew.doingBusinessAsDescription" />
            </P>
            <PoweredByStripeBanner>
                <InfoIcon />
                <P>
                    <Message id="merchantAccountNew.stripeDescription" />
                    <PoweredByStripeBadge />
                </P>
            </PoweredByStripeBanner>
            <MerchantAccountNewFormField
                isRequired
                label="merchantAccountNew.businessNameLabel"
                labelSubtext={t('merchantAccountNew.businessNameLabelSubtext')}
                name="businessName"
                placeholder={t('merchantAccountNew.businessNamePlaceholder')}
            />
            <StyledMerchantAccountFormCountrySelect />
            <FormField
                isRequired
                label="merchantAccountNew.notificationPhoneHeading"
                labelSubtext={t('merchantAccountNew.notificationPhoneDescription')}
                name="notificationPhone"
                variant={FormElementVariant.PhoneInput}
            />
            <FormField
                label={
                    <Message
                        id="merchantAccountNew.acceptedTermsLabel"
                        values={{
                            termsOfUseLink: (
                                <A
                                    href="https://www.shootproof.com/legal/terms-of-use"
                                    target="_blank">
                                    <Message id="merchantAccountNew.acceptedTermsLink" />
                                </A>
                            )
                        }}
                    />
                }
                name="acceptedTerms"
                variant={FormElementVariant.Checkbox}
            />
        </>
    );
};

export const MerchantAccountNewFormField = styled(FormField)`
    margin-bottom: 32px;
`;

const MerchantAccountNewPage: React.FC = () => {
    const createMerchantAccount = async (formValues, { setErrors }) => {
        const { businessType, notificationPhone } = formValues;

        delete formValues.acceptedTerms;

        if (isBusinessType(MerchantAccountBusinessType.Company)(businessType)) {
            delete formValues.individualFirstName;
            delete formValues.individualLastName;
        } else {
            delete formValues.companyLegalName;
        }

        const { number: phone } = parsePhoneNumber(notificationPhone, 'US');

        await patchNotificationPreference({
            data: { phone },
            method: 'patch'
        });

        delete formValues.notificationPhone;

        const { data, title } = await performFetch({
            data: formValues,
            method: 'post'
        });

        if (title === '409 Conflict') {
            setDbaStep(true);
            setErrors({
                businessName: 'merchantAccountNew.businessNameDuplicateError'
            });
        } else {
            const { id } = data;

            setRedirectUrl(`/payment-account/${id}/verify`);
        }
    };
    const [dbaStep, setDbaStep] = useState<boolean>(true);
    const dbaStepValidationSchema = yup.object().shape({
        acceptedTerms: yup
            .bool()
            .oneOf([true], 'merchantAccountNew.acceptedTermsRequiredValidationError'),
        businessName: businessNameValidation,
        country: yup.string().length(2).required(),
        notificationPhone: phoneValidation
    });
    const initialValues = {
        acceptedTerms: false,
        businessName: '',
        businessType: '',
        companyLegalName: '',
        country: 'US',
        individualFirstName: '',
        individualLastName: '',
        notificationPhone: ''
    };
    const isBusinessType = (businessType) => (currentBusinessType) =>
        currentBusinessType === businessType;
    const [notificationPhone, setNotificationPhone] = useState('');
    const [merchantResponse, loading, performFetch] = usePaymentsApiFetch('/merchant-account');
    const { 2: patchNotificationPreference } = usePaymentsApiFetch('/notification-preference', {
        defer: true
    });
    const [redirectUrl, setRedirectUrl] = useState<string>();
    const setNotificationPhoneFromNotificationPreference = useCallback(
        ({ phone }) => (phone ? setNotificationPhone(phone) : setNotificationPhone('')),
        []
    );
    const t = useMessage();
    const validationSchema = yup.object().shape({
        acceptedTerms: yup
            .bool()
            .oneOf([true], 'merchantAccountNew.acceptedTermsRequiredValidationError'),
        businessType: yup.string().oneOf(Object.values(MerchantAccountBusinessType)).required(),
        businessName: businessNameValidation,
        companyLegalName: yup.string().when('businessType', {
            is: isBusinessType(MerchantAccountBusinessType.Company),
            then: yup
                .string()
                .max(200)
                .required('merchantAccountNew.companyNameRequiredValidationError')
        }),
        country: yup.string().length(2).required(),
        individualFirstName: yup.string().when('businessType', {
            is: isBusinessType(MerchantAccountBusinessType.Individual),
            then: yup
                .string()
                .max(100)
                .required('merchantAccountNew.individualFirstNameRequiredValidationError')
        }),
        individualLastName: yup.string().when('businessType', {
            is: isBusinessType(MerchantAccountBusinessType.Individual),
            then: yup
                .string()
                .max(100)
                .required('merchantAccountNew.individualLastNameRequiredValidationError')
        }),
        notificationPhone: phoneValidation
    });

    if (!loading && merchantResponse?.data) {
        const { data: merchantAccounts } = merchantResponse;
        const { completionStatus, id } = merchantAccounts[0] || {};

        if (merchantAccounts.length > 0) {
            if (completionStatus === 'incomplete') {
                return <Redirect push to={`/payment-account/${id}/onboarding`} />;
            } else {
                return <Redirect push to={`/payment-account/${id}/payment`} />;
            }
        }
    }

    if (redirectUrl) {
        return <Redirect push to={redirectUrl} />;
    }

    return (
        <>
            <OnboardingForm
                enableReinitialize
                initialValues={{ ...initialValues, notificationPhone }}
                onSubmit={createMerchantAccount}
                validationSchema={dbaStep ? dbaStepValidationSchema : validationSchema}>
                <OnboardingPageHeader hasCancel />
                <OnboardingFormContent currentStep={Step.First}>
                    {dbaStep ? <DoingBusinessAsSection /> : <BusinessInfoSection />}
                </OnboardingFormContent>
                <OnboardingPageFooter>
                    {dbaStep ? (
                        <MerchantAccountFormSubmitButton
                            onClick={(event, values) => {
                                event.preventDefault();

                                if (dbaStepValidationSchema.isValidSync(values)) {
                                    setDbaStep(false);
                                }
                            }}
                        />
                    ) : (
                        <MerchantAccountFormSubmitButton
                            loadingText={t('button.form.loading')}
                            validateFormOnRender
                        />
                    )}
                </OnboardingPageFooter>
            </OnboardingForm>
            <GetNotificationPreference onGot={setNotificationPhoneFromNotificationPreference} />
            <Pendo />
        </>
    );
};

export const PoweredByStripeBanner = styled(Banner)(
    ({ theme }) => `
        margin-bottom: 40px;

        p {
            display: flex;

            ${theme.responsive.smallScreenOnly`
                flex-direction: column;
            `}

            svg {
                flex: 0 0 119px;
                margin-left: 20px;

                ${theme.responsive.mediumScreenUp`
                    align-self: center;
                    height: 26px;
                `};

                ${theme.responsive.smallScreenOnly`
                    margin-top: 14px;
                    margin-left: 0;
                    flex-basis: 26px;
                    width: 119px;
                `}
            }
        }

        > svg {
            flex: 0 0 24px;
            height: 24px;
            margin-right: 16px;
        }
    `
);

export const StyledMerchantAccountFormCountrySelect = styled(MerchantAccountFormCountrySelect)`
    display: none;
    margin-bottom: 32px;
`;

export default MerchantAccountNewPage;
