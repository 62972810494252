import { Decimal } from 'decimal.js';
import React, { forwardRef } from 'react';
import {
    Box,
    BoxProps,
    Flex,
    Form,
    FormElementVariant,
    H1,
    IModal,
    ModalStepsProvider,
    P,
    Tooltip,
    styled,
    useFormikContext,
    useModal,
    yup
} from 'sp-ui';
import { useBalance } from '../balance';
import { Button, FormField } from '../form';
import { useMerchantAccount } from '../merchant-account';
import InstantPayoutCardAccountSelectModalContent from './InstantPayoutCardAccountSelectModalContent';
import InstantPayoutPreview from './InstantPayoutPreview';
import { PayoutMethod } from './common';
import { Permission, usePermissions } from '../permissions';
import { Message, useCurrency, useMessage } from '../intl';
import { usePaymentsApiFetch, usePaymentsApiMerchantAccountPath } from '../../hooks';

const CONFIRM_DISABLED_KEY = 'instant-payout-confirm-disabled';

interface IInstantPayoutActionProps {
    onInstantPayoutCreated: (object) => void;
}

export const InstantPayoutAction: React.FC<IInstantPayoutActionProps & BoxProps> = ({
    onInstantPayoutCreated,
    ...props
}) => {
    const {
        Modal: InstantPayoutConfirmModalComponent,
        close: closeInstantPayoutConfirmModal,
        open: openInstantPayoutConfirmModal
    } = useModal();
    const {
        Modal: InstantPayoutModalComponent,
        close: closeInstantPayoutModal,
        open: openInstantPayoutModal
    } = useModal();
    const { balance } = useBalance();
    const { availableInstant: amount, currency } = balance;
    const isConfirmDisabled = localStorage.getItem(CONFIRM_DISABLED_KEY) === 'true';
    const isDisabled = !amount || amount < 500;

    return (
        <InstantPayoutActionContainer justifyContent="flex-end" {...props}>
            {isDisabled ? (
                <InstantPayoutDisabledButtonWithTooltip />
            ) : (
                <Button onClick={openInstantPayoutModal}>
                    <Message id="instantPayout.newPayoutButton" />
                </Button>
            )}
            {!isDisabled && (
                <>
                    <InstantPayoutForm
                        currency={currency}
                        initialValues={{
                            amount: Number(amount) / 100,
                            disableConfirm: false,
                            payoutAccountId: undefined
                        }}
                        onInstantPayoutCreated={(payout) => {
                            if (isConfirmDisabled) {
                                closeInstantPayoutModal();
                            } else {
                                closeInstantPayoutConfirmModal();
                            }

                            onInstantPayoutCreated(payout);
                        }}>
                        {({ handleSubmit, isSubmitting }) => (
                            <>
                                <InstantPayoutModal
                                    amount={Number(amount)}
                                    modalComponent={InstantPayoutModalComponent}
                                    onPayoutAccountSelected={() => {
                                        if (isConfirmDisabled) {
                                            handleSubmit();
                                        } else {
                                            closeInstantPayoutModal();
                                            openInstantPayoutConfirmModal();
                                        }
                                    }}
                                />
                                <InstantPayoutConfirmModal
                                    handleSubmit={handleSubmit}
                                    isSubmitting={isSubmitting}
                                    modalComponent={InstantPayoutConfirmModalComponent}
                                />
                            </>
                        )}
                    </InstantPayoutForm>
                </>
            )}
        </InstantPayoutActionContainer>
    );
};

const InstantPayoutActionContainer = styled(Flex)`
    button {
        white-space: nowrap;
    }
`;

interface IInstantPayoutConfirmModalProps {
    handleSubmit: () => void;
    isSubmitting: boolean;
    modalComponent: IModal;
}

export const InstantPayoutConfirmModal: React.FC<IInstantPayoutConfirmModalProps> = ({
    handleSubmit,
    isSubmitting,
    modalComponent: Modal
}) => {
    const t = useMessage();

    return (
        <Modal>
            <Modal.Body>
                <H1 mb="20px">
                    <Message id="instantPayout.confirmModal.heading" />
                </H1>
                <P mb="32px">
                    <Message id="instantPayout.confirmModal.body" />
                </P>
                <FormField
                    label={t('instantPayout.confirmModal.disableConfirmLabel')}
                    name="disableConfirm"
                    variant={FormElementVariant.Checkbox}
                />
            </Modal.Body>
            <Modal.PrimaryButton isDisabled={isSubmitting} onClick={() => handleSubmit()}>
                <Message id="instantPayout.confirmModal.primaryButton" />
            </Modal.PrimaryButton>
            <Modal.SecondaryButton>
                <Message id="instantPayout.confirmModal.secondaryButton" />
            </Modal.SecondaryButton>
        </Modal>
    );
};

const InstantPayoutDisabledButtonContainer = styled(Box)`
    width: 100%;

    div {
        white-space: nowrap;
        width: 100%;
    }
`;

export const InstantPayoutDisabledButton = forwardRef<
    HTMLDivElement,
    { onMouseEnter?: () => void; onMouseLeave?: () => void }
>((props, ref) => (
    <InstantPayoutDisabledButtonContainer ref={ref}>
        <Button as={Box} isDisabled {...props}>
            <Message id="instantPayout.newPayoutButton" />
        </Button>
    </InstantPayoutDisabledButtonContainer>
));

export const InstantPayoutDisabledButtonWithTooltip = () => {
    const t = useMessage();

    return (
        <Tooltip tooltipLabel={t('instantPayout.newPayoutButton.disabledTooltip')}>
            <InstantPayoutDisabledButton />
        </Tooltip>
    );
};

type InstantPayoutFormValues = {
    amount: number;
    disableConfirm: boolean;
    payoutAccountId: string | undefined;
};

interface IInstantPayoutFormProps {
    currency: string;
    initialValues: InstantPayoutFormValues;
    onInstantPayoutCreated: (payout: object) => void;
}

export const InstantPayoutForm: React.FC<IInstantPayoutFormProps> = ({
    children,
    currency,
    initialValues,
    onInstantPayoutCreated,
    ...props
}) => {
    const $ = useCurrency(currency);
    const { amount } = initialValues;
    const payoutPath = usePaymentsApiMerchantAccountPath('/payout');
    const { 2: createPayout } = usePaymentsApiFetch(payoutPath, {
        defer: true
    });
    const createInstantPayout = async (
        { amount, disableConfirm, payoutAccountId },
        { resetForm, setFieldValue }
    ) => {
        const { data: payout } = await createPayout({
            data: {
                amount: new Decimal(Number(amount)).times(100).toNumber(),
                method: PayoutMethod.Instant,
                payoutAccount: payoutAccountId
            },
            method: 'post'
        });
        const { grossAmount } = payout;

        onInstantPayoutCreated(payout);
        resetForm();
        setFieldValue('amount', new Decimal(amount).minus(grossAmount / 100).toNumber());

        if (disableConfirm) {
            localStorage.setItem(CONFIRM_DISABLED_KEY, 'true');
        }
    };
    const t = useMessage();
    const validationSchema = yup.object().shape({
        amount: yup
            .number()
            .max(amount, t('instantPayout.form.amountMaxValidationError'))
            .min(5, ({ min }) =>
                t('instantPayout.form.amountMinValidationError', { amount: $(min) })
            ),
        disableConfirm: yup.boolean(),
        payoutAccountId: yup.string().required(),
        payoutMaxAmount: yup.boolean()
    });

    return (
        <Form<InstantPayoutFormValues>
            initialValues={initialValues}
            onSubmit={createInstantPayout}
            validateOnMount
            validationSchema={validationSchema}
            {...props}>
            {children}
        </Form>
    );
};

interface IInstantPayoutModalProps {
    amount: number;
    modalComponent: IModal;
    onPayoutAccountSelected: () => void;
}

export const InstantPayoutModal: React.FC<IInstantPayoutModalProps> = ({
    amount,
    modalComponent: Modal,
    onPayoutAccountSelected
}) => {
    const { merchantAccount } = useMerchantAccount();
    const { defaultCurrency } = merchantAccount;
    const { hasPermission } = usePermissions();
    const { isValid } = useFormikContext();
    const t = useMessage();
    const steps = [
        {
            canGoToNextStep: () => isValid,
            content: (
                <InstantPayoutCardAccountSelectModalContent
                    amount={amount}
                    canAddCards={hasPermission(Permission.AddDebitCard)}
                    currency={defaultCurrency}
                />
            ),
            heading: t('instantPayout.modal.headingStepOne')
        },
        {
            content: <InstantPayoutPreview />,
            heading: t('instantPayout.modal.headingStepTwo')
        }
    ];

    return (
        <ModalStepsProvider
            onLastStepComplete={() => {
                onPayoutAccountSelected();
            }}
            steps={steps}
            text={{
                buttons: {
                    last: t('instantPayout.modal.lastButton'),
                    next: t('continueButton'),
                    previous: t('modalWithSteps.previousButton')
                },
                progressHeading: (currentStep, stepCount) =>
                    t('modalWithSteps.progressHeading', {
                        currentStep,
                        stepCount
                    })
            }}>
            <Modal heading={t('instantPayout.modal.heading')} />
        </ModalStepsProvider>
    );
};

export default InstantPayoutAction;
