import React, { useEffect, useState } from 'react';
import { AlertDialog, Box, useFormikContext } from 'sp-ui';
import { Prompt, useHistory } from 'react-router-dom';
import { useMessage } from '../intl';

export const ConfirmNavigation: React.FC = ({ ...props }) => {
    const { dirty } = useFormikContext();
    const [isOpen, setIsOpen] = useState(false);
    const [lastLocation, setLastLocation] = useState<Location>();
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);
    const history = useHistory();
    const handleBlockedNavigation = (nextLocation) => {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            setIsOpen(true);
            setLastLocation(nextLocation);

            return false;
        }

        return true;
    };
    const onConfirmNavigationClick = () => {
        setIsOpen(false);
        setConfirmedNavigation(true);
    };
    const onClose = () => {
        setIsOpen(false);
    };
    const shouldBlockNavigation = (nextLocation) => {
        if (dirty) {
            if (nextLocation.pathname !== history.location.pathname) {
                return true;
            }
        }

        return false;
    };
    const t = useMessage();

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            history.push(lastLocation.pathname);
        }
    }, [confirmedNavigation, history, lastLocation]);

    return (
        <Box {...props}>
            <Prompt when={dirty} message={handleBlockedNavigation} />
            <AlertDialog
                body={t('settings.confirmNavigation.alertDialogBody')}
                header="settings.confirmNavigation.alertDialogHeader"
                isOpen={isOpen}
                onConfirm={onConfirmNavigationClick}
                onClose={onClose}
                primaryButtonText="settings.confirmNavigation.primaryButtonText"
            />
        </Box>
    );
};

export default ConfirmNavigation;
