export enum MerchantAccountBusinessType {
    Company = 'company',
    Individual = 'individual'
}

export enum MerchantAccountCompletionStatus {
    Complete = 'complete',
    Incomplete = 'incomplete',
    Pending = 'pending'
}

export enum MerchantAccountTier {
    Level1 = 'level-1',
    Level2 = 'level-2',
    Level3 = 'level-3'
}
