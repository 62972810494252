import React from 'react';
import { ButtonVariant, Flex, styled, useFormikContext } from 'sp-ui';
import { Button } from '../form';
import { goToShootProofAcceptPayments } from '../../services/onboarding';

interface IOnboardingPageFooterProps {
    hasClose?: boolean;
    submitFormOnClose?: boolean;
}

const OnboardingPageFooter: React.FC<IOnboardingPageFooterProps> = ({
    children,
    hasClose,
    submitFormOnClose
}) => {
    const { submitForm } = useFormikContext();

    return (
        <OnboardingPageFooterContainer>
            {hasClose && (
                <Button
                    message="onboarding.pageFooter.closeButton"
                    onClick={
                        submitFormOnClose
                            ? () => {
                                  submitForm();
                                  goToShootProofAcceptPayments();
                              }
                            : goToShootProofAcceptPayments
                    }
                    variant={ButtonVariant.Tertiary}
                />
            )}
            {children}
        </OnboardingPageFooterContainer>
    );
};

const OnboardingPageFooterContainer = styled(Flex)(
    ({ theme }) => `
        align-items: center;
        background: ${theme.colors.gray[100]};
        border-top: 1px solid ${theme.colors.gray[200]};
        justify-content: flex-end;
        padding: 16px 40px;

        button:not(:last-child) {
            margin-right: 16px;
        }

        ${theme.responsive.smallScreenOnly`
            padding: 16px;

            button:not(:last-child) {
                margin-right: auto;
            }
        `}
    `
);

export default OnboardingPageFooter;
