import React from 'react';
import { Box, styled } from 'sp-ui';

export const SettingsViewLayout = styled(Box)(
    ({ theme }) => `
        height: 100%;
        overflow-y: auto;
        padding: 0 0 0 40px;
    
        ${theme.responsive.smallScreenOnly`
            padding: 28px 16px;
        `}
    `
);

const SettingsView: React.FC = ({ children, ...props }) => {
    return <SettingsViewLayout {...props}>{children}</SettingsViewLayout>;
};

export default SettingsView;
