import React from 'react';
import { Link as ReactRouterDomLink, LinkProps } from 'react-router-dom';
import { A, BoxProps } from 'sp-ui';

const Link: React.FC<BoxProps & LinkProps> = ({ ...props }) => (
    <ReactRouterDomLink
        component={({ href: _, navigate, ...props }) => <A onClick={navigate} {...props} />}
        {...props}
    />
);

export default Link;
