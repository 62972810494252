import React from 'react';
import { LoadingDialog } from 'sp-ui';
import PayoutDetailsBackToPayoutLink from './BackToPayoutLink';
import PayoutDetails from '../PayoutDetails';
import { usePaymentsApiFetch, usePaymentsApiMerchantAccountPath } from '../../../hooks';

interface IPayoutDetailsPayoutViewProps {
    payoutId: string;
}

const PayoutDetailsPayoutView: React.FC<IPayoutDetailsPayoutViewProps> = ({ payoutId }) => {
    const payoutPath = usePaymentsApiMerchantAccountPath(`/payout/${payoutId}`);
    const [payoutResponse] = usePaymentsApiFetch(payoutPath);
    const { data: payout } = payoutResponse || {};

    return (
        <>
            <PayoutDetailsBackToPayoutLink />
            {payout ? <PayoutDetails payout={payout} /> : <LoadingDialog description="" />}
        </>
    );
};

export default PayoutDetailsPayoutView;
