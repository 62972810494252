import React, { useContext } from 'react';
import { A, Box, Flex } from 'sp-ui';
import { ReactComponent as BackArrow } from 'sp-ui/dist/assets/svg/back-arrow.svg';
import { PayoutDetailsViewContext } from './ViewContext';
import { Message } from '../../intl';

const PayoutDetailsBackToPayoutLink: React.FC = () => {
    const { backToPayout, backToPayoutText } = useContext(PayoutDetailsViewContext);

    return (
        <Box marginBottom="44px" onClick={backToPayout}>
            <A>
                <Flex alignItems="center">
                    <BackArrow fill="currentColor" />
                    <Message id={backToPayoutText} />
                </Flex>
            </A>
        </Box>
    );
};

export default PayoutDetailsBackToPayoutLink;
