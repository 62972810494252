import React from 'react';
import { A } from 'sp-ui';
import { Message } from './intl';

const HELP_BASE_URL = 'https://help.shootproof.com/hc/en-us';

interface IHelpLinkProps {
    children?: (helpUrl: string) => React.ReactElement;
    content?: string;
    path: string;
}

const HelpLink: React.FC<IHelpLinkProps> = ({ children, content, path }) => {
    const helpUrl = `${HELP_BASE_URL}${path}`;

    return children ? (
        children(helpUrl)
    ) : (
        <A href={helpUrl} rel="noopener noreferrer" target="_blank">
            <Message id={content} />
        </A>
    );
};

export default HelpLink;
