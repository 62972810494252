import React from 'react';
import { StatusPill, StatusPillVariant } from 'sp-ui';
import { PayoutStatus } from './common';
import { IPayout } from './types';
import withPayoutStatus from './withPayoutStatus';
import { useMessage } from '../intl';

interface IPayoutStatusPillProps {
    payout: IPayout;
    payoutStatus: PayoutStatus;
}

export const PayoutStatusPill: React.FC<IPayoutStatusPillProps> = ({ payoutStatus }) => {
    const t = useMessage();
    const { variant, tooltipLabel, statusPillText } = {
        [PayoutStatus.Canceled]: {
            variant: StatusPillVariant.Neutral,
            tooltipLabel: t('payoutStatusPill.canceledLabel'),
            statusPillText: t('payoutStatus.canceled')
        },
        [PayoutStatus.Failed]: {
            variant: StatusPillVariant.Danger,
            tooltipLabel: t('payoutStatusPill.failedLabel'),
            statusPillText: t('payoutStatus.failed')
        },
        [PayoutStatus.InTransit]: {
            variant: StatusPillVariant.Neutral,
            tooltipLabel: t('payoutStatusPill.inTransitLabel'),
            statusPillText: t('payoutStatus.inTransit')
        },
        [PayoutStatus.Paid]: {
            variant: StatusPillVariant.Success,
            tooltipLabel: t('payoutStatusPill.paidLabel'),
            statusPillText: t('payoutStatus.paid')
        },
        [PayoutStatus.Pending]: {
            variant: StatusPillVariant.Neutral,
            tooltipLabel: t('payoutStatusPill.pendingLabel'),
            statusPillText: t('payoutStatus.pending')
        },
        [PayoutStatus.Withdrawn]: {
            variant: StatusPillVariant.Neutral,
            tooltipLabel: t('payoutStatusPill.withdrawnLabel'),
            statusPillText: t('payoutStatus.withdrawn')
        }
    }[payoutStatus];

    return (
        <StatusPill statusPillText={statusPillText} tooltipLabel={tooltipLabel} variant={variant} />
    );
};

export default withPayoutStatus(PayoutStatusPill);
