import React from 'react';
import { ReactComponent as BankImage } from '../../assets/svg/payout-account-type/bank.svg';
import { ReactComponent as DebitCardImage } from '../../assets/svg/payout-account-type/debit-card.svg';
import { PayoutAccountType } from './common';
import { SplitChoiceRadioGroup } from '../form';

interface IPayoutAccountTypeRadioGroupProps {
    alwaysSmallScreen?: boolean;
    omitRecommended?: boolean;
}

const PayoutAccountTypeRadioGroup: React.FC<IPayoutAccountTypeRadioGroupProps> = ({
    alwaysSmallScreen,
    omitRecommended
}) => {
    const accountTypeChoices = [
        {
            description: 'payoutAccount.typeRadioGroup.debitCardDescription',
            image: DebitCardImage,
            label: 'payoutAccount.typeRadioGroup.debitCardLabel',
            recommended: !omitRecommended,
            value: PayoutAccountType.DebitCard
        },
        {
            description: 'payoutAccount.typeRadioGroup.bankAccountDescription',
            image: BankImage,
            label: 'payoutAccount.typeRadioGroup.bankAccountLabel',
            value: PayoutAccountType.BankAccount
        }
    ];

    return (
        <SplitChoiceRadioGroup
            alwaysSmallScreen={alwaysSmallScreen}
            choices={accountTypeChoices}
            name="type"
        />
    );
};

export default PayoutAccountTypeRadioGroup;
