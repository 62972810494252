import { parse } from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { useLocation } from 'react-router-dom';
import { A, IconModal, P } from 'sp-ui';
import { Button } from '../form';
import { useMerchantAccount } from '../merchant-account';
import { IBankAccount } from './types';
import { usePayoutAccount } from './Context';
import { Message } from '../intl';
import { usePaymentsApiFetch } from '../../hooks';
import { noop } from '../../utils';

export enum ErrorModalType {
    BankAccountCannotBeUsed = 'bank-account-cannot-be-used',
    Generic = 'generic',
    PlaidBankAccountCannotBeUsed = 'plaid-bank-account-cannot-be-used',
    PlaidError = 'plaid-error',
    PlaidLoginRequired = 'plaid-login-required'
}

interface IPayoutAccountNewBankAccountButtonProps {
    deleteExpiredOnCreate?: boolean;
    isLink?: boolean;
    isReauthenticating?: boolean;
    onCreateError?: () => void;
    onCreated?: (bankAccount: IBankAccount) => void;
    onPlaidCreated?: () => void;
    onPlaidReauthenticated?: () => void;
    plaidPendingBankAccountId?: string;
    withOnClick?: (onClick: () => void) => void;
}

const PayoutAccountNewBankAccountButton: React.FC<IPayoutAccountNewBankAccountButtonProps> = withPlaidLinkToken(
    ({
        children,
        deleteExpiredOnCreate,
        isLink,
        isReauthenticating,
        onCreateError = noop,
        onCreated = noop,
        onPlaidCreated = noop,
        onPlaidReauthenticated = noop,
        plaidClientName,
        plaidEnvironment,
        plaidLinkToken,
        plaidPendingBankAccountId,
        withOnClick
    }) => {
        const Component = isLink ? A : Button;
        const [closeModal, setCloseModal] = useState<() => void>(() => noop);
        const { 2: createPlaidAccessToken } = usePaymentsApiFetch('/plaid/access-token', {
            defer: true,
            method: 'post'
        });
        const { pendingBankAccounts, removeFromPendingBankAccounts } = usePayoutAccount();
        const { 2: deleteFirstPlaidPendingBankAccountLinkToken } = usePaymentsApiFetch(
            `/plaid/link-token/pending-bank-account/${pendingBankAccounts[0]?.id}`,
            {
                defer: true,
                method: 'delete'
            }
        );
        const { 2: deletePlaidPendingBankAccountLinkToken } = usePaymentsApiFetch(
            `/plaid/link-token/pending-bank-account/${plaidPendingBankAccountId}`,
            {
                defer: true,
                method: 'delete'
            }
        );
        const [errorModalType, setErrorModalType] = useState<ErrorModalType>(
            ErrorModalType.Generic
        );
        const { expirePlaidBankAccount } = parse(useLocation().search);
        const [inPlaid, setInPlaid] = useState<boolean>(false);
        const [isFetching, setIsFetching] = useState<boolean>(false);
        const onExit = async (error) => {
            setInPlaid(false);

            if (error !== null) {
                const { error_code } = error;

                if (plaidPendingBankAccountId && error_code === 'TOO_MANY_VERIFICATION_ATTEMPTS') {
                    await deletePlaidPendingBankAccountLinkToken();
                    removeFromPendingBankAccounts(plaidPendingBankAccountId);
                }
            }
        };
        const onSuccess = async (publicToken, meta) => {
            const { id } = merchantAccount;

            if (
                deleteExpiredOnCreate &&
                ['errored', 'verification_expired'].includes(
                    pendingBankAccounts[0]?.verificationStatus
                )
            ) {
                await deleteFirstPlaidPendingBankAccountLinkToken();
            }

            onPlaidCreated();
            setInPlaid(false);

            if (isReauthenticating) {
                onPlaidReauthenticated();

                return;
            }

            setIsFetching(true);

            setTimeout(async () => {
                const { accounts } = meta;
                const [{ id: accountId }] = accounts;
                const plaidAccessTokenData = expirePlaidBankAccount
                    ? { accountId, merchantId: id, publicToken, expired: true }
                    : { accountId, merchantId: id, publicToken };

                const { data, status, title } = await createPlaidAccessToken({
                    data: plaidAccessTokenData
                });

                setIsFetching(false);

                if (status === 400) {
                    let errorModalType = ErrorModalType.Generic;

                    switch (title) {
                        case 'Bank account is unusable':
                            errorModalType = ErrorModalType.BankAccountCannotBeUsed;
                            break;
                        case 'Plaid access not granted':
                        case 'Plaid products not supported':
                            errorModalType = ErrorModalType.PlaidError;
                            break;
                        case 'Plaid item not found':
                        case 'Plaid no auth accounts':
                            errorModalType = ErrorModalType.PlaidBankAccountCannotBeUsed;
                            break;
                        case 'Plaid login required':
                            errorModalType = ErrorModalType.PlaidLoginRequired;
                            break;
                    }

                    onCreateError();
                    openModal();
                    setErrorModalType(errorModalType);

                    return;
                }

                if (status === 500) {
                    onCreateError();
                    openModal();
                    setErrorModalType(ErrorModalType.Generic);

                    return;
                }

                const { plaidPendingBankAccountId } = data;
                const { institution } = meta;
                const [{ mask, name, verification_status: verificationStatus }] = accounts;
                const { institution_id: institutionId } = institution;

                setTimeout(() => {
                    onCreated({
                        accountName: name,
                        accountNumberMask: mask,
                        institutionId,
                        plaidPendingBankAccountId,
                        verificationStatus
                    });
                });
            });
        };
        const { open, ready } = usePlaidLink({
            clientName: plaidClientName,
            countryCodes: ['US'],
            env: plaidEnvironment,
            onExit,
            onSuccess,
            product: plaidPendingBankAccountId ? [] : ['auth'],
            token: plaidLinkToken
        });
        const isDisabled = !ready || inPlaid || isFetching;
        const getDescription = () => {
            switch (errorModalType) {
                case ErrorModalType.BankAccountCannotBeUsed:
                    return 'payoutAccount.newBankAccountButton.errorModal.bankAccountCannotBeUsed.description';
                case ErrorModalType.Generic:
                    return 'payoutAccount.newBankAccountButton.errorModal.description';
                case ErrorModalType.PlaidBankAccountCannotBeUsed:
                    return 'payoutAccount.newBankAccountButton.errorModal.plaidBankAccountCannotBeUsed.description';
                case ErrorModalType.PlaidError:
                    return 'payoutAccount.newBankAccountButton.errorModal.plaidError.description';
                case ErrorModalType.PlaidLoginRequired:
                    return 'payoutAccount.newBankAccountButton.errorModal.plaidLoginRequired.description';
            }
        };
        const getHeaderText = () => {
            switch (errorModalType) {
                case ErrorModalType.BankAccountCannotBeUsed:
                    return 'payoutAccount.newBankAccountButton.errorModal.bankAccountCannotBeUsed.heading';
                case ErrorModalType.Generic:
                    return 'payoutAccount.newBankAccountButton.errorModal.heading';
                case ErrorModalType.PlaidBankAccountCannotBeUsed:
                    return 'payoutAccount.newBankAccountButton.errorModal.plaidBankAccountCannotBeUsed.heading';
                case ErrorModalType.PlaidError:
                    return 'payoutAccount.newBankAccountButton.errorModal.plaidError.heading';
                case ErrorModalType.PlaidLoginRequired:
                    return 'payoutAccount.newBankAccountButton.errorModal.plaidLoginRequired.heading';
            }
        };
        const { merchantAccount } = useMerchantAccount();
        const [openModal, setOpenModal] = useState<() => void>(() => noop);
        const openPlaid = useCallback(() => {
            open();
            setInPlaid(true);
        }, [open]);
        const openSubscribe = useCallback((openModal, closeModal) => {
            setCloseModal(() => closeModal);
            setOpenModal(() => openModal);
        }, []);

        useEffect(() => {
            if (withOnClick) {
                withOnClick(openPlaid);
            }
        }, [openPlaid, withOnClick]);

        return (
            <>
                <Component disabled={isDisabled} isDisabled={isDisabled} onClick={openPlaid}>
                    {children}
                </Component>
                <IconModal
                    headerText={getHeaderText()}
                    hideSecondaryAction
                    openSubscribe={openSubscribe}
                    primaryAction={closeModal}
                    primaryActionText="payoutAccount.newBankAccountButton.errorModal.primaryAction">
                    <P textAlign="center">
                        <Message
                            id={getDescription()}
                            values={{
                                link: (
                                    <A
                                        href="https://help.shootproof.com/hc/en-us/requests/new"
                                        target="_blank">
                                        <Message id="payoutAccount.newBankAccountButton.errorModal.descriptionLink" />
                                    </A>
                                )
                            }}
                        />
                    </P>
                </IconModal>
            </>
        );
    }
);

function withPlaidLinkToken<T extends IPayoutAccountNewBankAccountButtonProps>(
    Component: React.ComponentType<
        T & { plaidClientName: string; plaidEnvironment: string; plaidLinkToken: string }
    >
): React.FC<T> {
    return (props) => {
        const RenderedComponent = props.isLink ? A : Button;
        const { plaidPendingBankAccountId } = props;
        const [plaidLinkTokenResponse] = usePaymentsApiFetch('/plaid/link-token', {
            data: { plaidPendingBankAccountId },
            method: 'post'
        });

        if (!plaidLinkTokenResponse) {
            return (
                <RenderedComponent disabled isDisabled>
                    {props.children}
                </RenderedComponent>
            );
        }

        const { data } = plaidLinkTokenResponse;
        const { clientName, environment, linkToken } = data;

        return (
            <Component
                plaidClientName={clientName}
                plaidEnvironment={environment}
                plaidLinkToken={linkToken}
                {...props}
            />
        );
    };
}

export default PayoutAccountNewBankAccountButton;
