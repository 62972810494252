import React from 'react';
import { FormElementVariant } from 'sp-ui';
import { PayoutScheduleInterval } from './common';
import { FormField } from '../form';
import { useMessage } from '../intl';

interface IPayoutScheduleIntervalFieldProps {
    omitLabels?: boolean;
}

const PayoutScheduleIntervalField: React.FC<IPayoutScheduleIntervalFieldProps> = ({
    omitLabels
}) => {
    const t = useMessage();
    const radioOptions = [
        {
            description: t('settings.payoutScheduleIntervalDailyOption'),
            helperText: t('settings.payoutScheduleIntervalDailyOptionHelper'),
            value: PayoutScheduleInterval.Daily
        },
        {
            description: t('settings.payoutScheduleIntervalWeeklyOption'),
            helperText: t('settings.payoutScheduleIntervalWeeklyOptionHelper'),
            value: PayoutScheduleInterval.Weekly
        },
        {
            description: t('settings.payoutScheduleIntervalMonthlyOption'),
            helperText: t('settings.payoutScheduleIntervalMonthlyOptionHelper'),
            value: PayoutScheduleInterval.Monthly
        }
    ];

    return (
        <FormField
            label={omitLabels ? null : 'settings.payoutScheduleInterval'}
            labelSubtext={omitLabels ? null : t('settings.payoutScheduleIntervalDescription')}
            name="payoutScheduleInterval"
            radioOptions={radioOptions}
            variant={FormElementVariant.RadioGroup}
        />
    );
};

export default PayoutScheduleIntervalField;
