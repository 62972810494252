import React from 'react';
import { Redirect } from 'react-router-dom';
import { styled } from 'sp-ui';
import { ReactComponent as ErrorIcon } from '../../assets/svg/error-icon.svg';
import {
    MerchantAccountCompletionStatus,
    useMerchantAccount
} from '../../components/merchant-account';
import { Step, OnboardingFormContent } from '../../components/onboarding';
import EmptyState from '../../components/EmptyState';
import { Message } from '../../components/intl';

export const ErrorImage = styled(ErrorIcon)(
    ({ theme }) => `
        color: ${theme.colors.gray[600]};
        height: 66px;
        width: 66px;

        path {
            fill: ${theme.colors.navy[400]};
        }
    `
);

const OnboardingPendingPage: React.FC = () => {
    const { merchantAccount } = useMerchantAccount();
    const { completionStatus, id } = merchantAccount;

    return completionStatus === MerchantAccountCompletionStatus.Complete ? (
        <Redirect to={`/payment-account/${id}/onboarding/complete`} />
    ) : (
        <OnboardingFormContent currentStep={Step.Second}>
            <EmptyState
                buttonOnClick={() => {
                    const url = `${process.env.REACT_APP_SP_ACCOUNT_ACCEPT_PAYMENTS_URL}`;

                    window.location.assign(url);
                }}
                buttonText="onboarding.pending.emptyState.button"
                description={<Message id="onboarding.pending.emptyState.description" />}
                heading="onboarding.pending.emptyState.heading"
                image={<ErrorImage />}
            />
        </OnboardingFormContent>
    );
};

export default OnboardingPendingPage;
