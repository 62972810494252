import React from 'react';
import { useMerchantAccount } from '../merchant-account';
import { GlobalErrorBanner } from '../navigation';
import { A } from 'sp-ui';
import { Message } from '../intl';
import { usePaymentsApiFetch, usePaymentsApiMerchantAccountPath } from '../../hooks';
import { noop } from '../../utils';

const PayoutAccountFutureRequirementsBanner: React.FC = () => {
    const {
        merchantAccount: { hasFutureRequirements }
    } = useMerchantAccount();

    if (!hasFutureRequirements) {
        return null;
    }

    const accountLinkPath = usePaymentsApiMerchantAccountPath('/account-link');
    const [accountLinkResponse] = usePaymentsApiFetch(accountLinkPath, {
        data: { type: 'update' },
        fetchOnUrlChange: true,
        handleForbidden: noop,
        method: 'post'
    });
    const accountLinkUrl = accountLinkResponse?.data?.url;

    return (
        <GlobalErrorBanner>
            <Message
                id="payoutAccount.futureRequirementsBanner.description"
                values={{
                    link: (
                        <A href={accountLinkUrl}>
                            <Message id="payoutAccount.futureRequirementsBanner.link" />
                        </A>
                    )
                }}
            />
        </GlobalErrorBanner>
    );
};

export default PayoutAccountFutureRequirementsBanner;
