import React from 'react';
import BalanceTransactionCard from './BalanceTransactionCard';
import { IBalanceTransaction } from '../types';

interface IBalanceTransactionCardsProps {
    balanceTransactions: IBalanceTransaction[];
    currency: string;
}

const BalanceTransactionCards: React.FC<IBalanceTransactionCardsProps> = ({
    balanceTransactions,
    currency
}) => {
    return (
        <>
            {balanceTransactions.map((balanceTransaction, i) => (
                <BalanceTransactionCard
                    balanceTransaction={balanceTransaction}
                    currency={currency}
                    key={i}
                />
            ))}
        </>
    );
};

export default BalanceTransactionCards;
