import React, { useContext } from 'react';
import { Flex, Span, styled } from 'sp-ui';
import { ReactComponent as ChevronRight } from 'sp-ui/dist/assets/svg/chevron-right.svg';
import BalanceTransactionType from './BalanceTransactionType';
import { PayoutDetailsViewContext } from './ViewContext';
import { IBalanceTransaction } from '../types';
import { Currency, Date } from '../../intl';

interface IBalanceTransactionCardProps {
    balanceTransaction: IBalanceTransaction;
    currency: string;
}

const BalanceTransactionCard: React.FC<IBalanceTransactionCardProps> = ({
    balanceTransaction,
    currency
}) => {
    const { created, net } = balanceTransaction;
    const { viewNote, viewPayout, viewTransaction } = useContext(PayoutDetailsViewContext);
    const viewSource = () => {
        const { metadata } = balanceTransaction;
        const {
            internalAssociatedNote,
            internalAssociatedPayoutId,
            internalAssociatedTransactionId
        } = metadata;

        if (internalAssociatedNote) {
            viewNote(balanceTransaction);
        } else if (internalAssociatedPayoutId) {
            viewPayout(internalAssociatedPayoutId);
        } else if (internalAssociatedTransactionId) {
            viewTransaction(internalAssociatedTransactionId);
        }
    };

    return (
        <BalanceTransactionCardContainer
            data-testid="balance-transaction-card"
            onClick={viewSource}>
            <Flex flex="1" flexDirection="column" maxWidth="200px">
                <Span marginBottom="7px">
                    <BalanceTransactionType balanceTransaction={balanceTransaction} />
                </Span>
                <Span>
                    <Date shortFormat value={created} />
                </Span>
            </Flex>
            <Flex alignItems="center" flex="1" justifyContent="flex-end">
                <BalanceTransactionNet>
                    <Currency currency={currency} value={net / 100} />
                </BalanceTransactionNet>
            </Flex>
            <ChevronRight />
        </BalanceTransactionCardContainer>
    );
};

const BalanceTransactionCardContainer = styled(Flex)(
    ({ theme }) => `
        align-items: center;
        color: ${theme.colors.gray[700]};
        cursor: pointer;
        padding: 12px 20px;

        &:not(:last-of-type) {
            border-bottom: 1px solid ${theme.colors.gray[200]};
        }

        span {
            color: inherit;

            + span {
                font-size: 12px;
                line-height: 16px;
            }
        }

        svg {
            fill: ${theme.colors.gray[500]};
            margin-left: 16px;
            width: 7px;
        }
    `
);

const BalanceTransactionNet = styled(Span)`
    font-weight: 500;
`;

export default BalanceTransactionCard;
