import React, { ReactNode } from 'react';
import { Box, H1, H3, MediumScreenUp, SmallScreenOnly, styled } from 'sp-ui';
import DetailsView from './DetailsView';

export const DetailsWithListView = styled(DetailsView, {
    shouldForwardProp: (prop) => prop !== 'visible'
})<{ visible: boolean }>(
    ({ theme, visible }) => `
        box-shadow: -2px 0px 8px ${theme.utility.convertHexToRgba('#000000', 0.1)};
        height: 100%;
        overflow-y: auto;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(${visible ? 0 : '100vw'});
        transition: transform 600ms ease;
        width: 440px;
        will-change: transform;

        ${theme.responsive.smallScreenOnly`
            width: 100%;
        `}
    `
);

export const ListView = styled(Box)(
    ({ theme }) => `
        height: 100%;
        padding: 0 40px 40px 40px;
        overflow: auto;

        ${theme.responsive.smallScreenOnly(`
            padding: 26px 16px;
        `)}
    `
);

interface IListWithDetailsViewProps {
    details?: ReactNode;
    heading: string;
    onDetailsClose: () => void;
}

const ListWithDetailsView: React.FC<IListWithDetailsViewProps> = ({
    children,
    details,
    heading,
    onDetailsClose,
    ...props
}) => (
    <ListWithDetailsViewContainer {...props}>
        <ListView>
            <MediumScreenUp>
                <H1 data-testid="list-view-title" marginBottom="8px" marginTop="40px">
                    {heading}
                </H1>
            </MediumScreenUp>
            <SmallScreenOnly>
                <H3 data-testid="list-view-title-small-screen" marginBottom="4px">
                    {heading}
                </H3>
            </SmallScreenOnly>
            {children}
        </ListView>
        <DetailsWithListView onClose={onDetailsClose} visible={Boolean(details)}>
            {details}
        </DetailsWithListView>
    </ListWithDetailsViewContainer>
);

const ListWithDetailsViewContainer = styled(Box)`
    height: 100%;
    position: relative;
`;

export default ListWithDetailsView;
