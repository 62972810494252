import React, { RefObject, useEffect } from 'react';
import {
    Box,
    FormElementVariant,
    Formik,
    FormikForm,
    FormikValues,
    P,
    useField,
    useFormikContext,
    yup
} from 'sp-ui';
import { ITransaction } from './types';
import withTransactionCard from './withTransactionCard';
import { CurrencyInput, FormField, Label } from '../form';
import { Message, useCurrency, useMessage } from '../intl';
import { usePaymentsApiFetch, usePaymentsApiMerchantAccountPath } from '../../hooks';
import { fromDollarsToCents } from '../../number';

type IRefundFormFields = {
    amount: number;
    note: string;
    reason: string;
};

interface IRefundFormProps {
    initialValues: IRefundFormFields;
    innerRef?: RefObject<FormikValues> | any;
    onRefundCreated: (object) => void;
    transaction: ITransaction;
}

enum RefundReason {
    Duplicate = 'duplicate',
    Fraudulent = 'fraudulent',
    RequestedByCustomer = 'requested_by_customer'
}

interface IRefundFormAmountFieldProps {
    currency: string;
}

export const RefundFormAmountField: React.FC<IRefundFormAmountFieldProps> = ({ currency }) => (
    <Box marginBottom="16px">
        <Label>
            <Message id="transactionRefundForm.amountLabel" />
        </Label>
        <CurrencyInput currency={currency} data-testid="refund-amount" name="amount" />
    </Box>
);

export const RefundFormDescription = withTransactionCard(({ card, transaction }) => {
    if (!card) {
        return null;
    }

    const { currency } = transaction;
    const $ = useCurrency(currency);
    const { values } = useFormikContext<IRefundFormFields>();
    const { amount } = values;
    const { brand, last4 } = card;

    return (
        <P mb="16px">
            <Message
                id="transactionRefundForm.description"
                values={{
                    amount: $(amount),
                    brand,
                    last4
                }}
            />
        </P>
    );
});

export const RefundFormReasonFields: React.FC = () => {
    const [field] = useField('reason');
    const { name, value } = field;
    const t = useMessage();
    const options = [
        {
            label: t('transactionRefundForm.reasonDuplicateOption'),
            value: RefundReason.Duplicate
        },
        {
            label: t('transactionRefundForm.reasonFraudulentOption'),
            value: RefundReason.Fraudulent
        },
        {
            label: t('transactionRefundForm.reasonRequestedByCustomerOption'),
            value: RefundReason.RequestedByCustomer
        }
    ];

    return (
        <Box>
            <FormField
                label="transactionRefundForm.reasonLabel"
                mb="12px"
                name={name}
                placeholder={t('transactionRefundForm.reasonPlaceholder')}
                selectOptions={options}
                variant={FormElementVariant.Select}
            />
            {value === RefundReason.RequestedByCustomer && (
                <FormField
                    name="note"
                    placeholder={t('transactionRefundForm.reasonDetailsPlaceholder')}
                    variant={FormElementVariant.TextArea}
                />
            )}
        </Box>
    );
};

const RefundForm: React.FC<IRefundFormProps> = ({
    initialValues,
    innerRef,
    onRefundCreated,
    transaction
}) => {
    const { amount: transactionAmount, amountRefunded, currency, id: transactionId } = transaction;
    const createRefund = async (formValues) => {
        const { amount, note } = formValues;
        const amountInCents = fromDollarsToCents(amount);

        if (!note) {
            delete formValues.note;
        }

        return await performFetch({
            data: {
                ...formValues,
                amount: amountInCents
            },
            method: 'post'
        });
    };
    const { amount } = initialValues;
    const initialAmountToDollars = (amount / 100).toFixed(2);
    const refundPath = usePaymentsApiMerchantAccountPath(`/transaction/${transactionId}/refund`);
    const [response, loading, performFetch] = usePaymentsApiFetch(refundPath, {
        defer: true
    });
    const validationSchema = yup.object().shape({
        amount: yup
            .number()
            .max((transactionAmount - amountRefunded) / 100)
            .min(0.01)
            .required(),
        note: yup.string().max(200),
        reason: yup.string().oneOf(Object.values(RefundReason)).required()
    });

    useEffect(() => {
        if (!loading) {
            if (response && response.data.status === 'succeeded') {
                const { data: refund } = response;

                onRefundCreated(refund);
            }
        }
    }, [loading, onRefundCreated, response]);

    return (
        <Formik
            initialValues={{ ...initialValues, amount: initialAmountToDollars }}
            innerRef={innerRef}
            onSubmit={createRefund}
            validateOnBlur
            validationSchema={validationSchema}>
            <FormikForm>
                <RefundFormDescription transaction={transaction} />
                <RefundFormAmountField currency={currency} />
                <RefundFormReasonFields />
            </FormikForm>
        </Formik>
    );
};

export default RefundForm;
