import React from 'react';
import { Box, Flex, H4, LoadingDialog, P, Span, styled } from 'sp-ui';
import { Decimal } from 'decimal.js';
import { ReactComponent as CollageLogo } from '../../assets/svg/app/collage-logo.svg';
import { ReactComponent as ShootProofLogo } from '../../assets/svg/app/shootproof-logo.svg';
import { ReactComponent as TaveLogo } from '../../assets/svg/app/tave-logo.svg';
import { Strong } from '../text';
import RefundAction from './RefundAction';
import TransactionSource from './Source';
import TransactionStatusBanner from './StatusBanner';
import TransactionStatusPill from './StatusPill';
import { TransactionDisputeStatus, TransactionRefundStatus, TransactionStatus } from './common';
import { ITransaction } from './types';
import withTransactionCard from './withTransactionCard';
import withTransactionStatus from './withTransactionStatus';
import Hr from '../Hr';
import { Currency, Date, Message, useCurrency, useMessage } from '../intl';
import {
    Detail,
    DetailsViewContent,
    DetailsViewHeading,
    DetailsViewSupertext
} from '../../layouts/DetailsView';
import { getReasonTranslationKey } from '../../services/dispute';
import { useTheme } from '../../hooks';

export const CreditCardMask = withTransactionCard(({ card }) => {
    if (card) {
        const { brand, last4 } = card;

        return <>{`${brand} •••• ${last4}`}</>;
    } else {
        return <>{'-'}</>;
    }
});

export enum TransactionAppLogoVariant {
    ShootProof = 'shootproof',
    Tave = 'tave'
}

interface ITransactionAppLogoProps {
    showCollageLogo: boolean;
    variant: TransactionAppLogoVariant;
}

export const TransactionAppLogo: React.FC<ITransactionAppLogoProps> = ({
    showCollageLogo,
    variant
}) => {
    const { colors } = useTheme();
    const isShootProofVariant = variant === TransactionAppLogoVariant.ShootProof;

    return (
        <TransactionAppLogoContainer>
            <TransactionAppLogoSvgContainer
                background={isShootProofVariant ? colors.blue[400] : colors.purple[200]}>
                {isShootProofVariant && <ShootProofLogo />}
                {!isShootProofVariant && <TaveLogo />}
            </TransactionAppLogoSvgContainer>
            {showCollageLogo && (
                <>
                    <Span marginLeft="10px" marginRight="10px">
                        +
                    </Span>
                    <TransactionAppLogoSvgContainer
                        background={colors.gray[100]}
                        border={`1px ${colors.gray[200]} solid`}>
                        <CollageLogo />
                    </TransactionAppLogoSvgContainer>
                </>
            )}
        </TransactionAppLogoContainer>
    );
};

export const TransactionAppLogoContainer = styled(Flex)`
    align-items: center;
    justify-content: center;
    margin: -55px auto 20px;

    span {
        font-size: 20px;
    }
`;

export const TransactionAppLogoSvgContainer = styled(Flex)`
    align-items: center;
    border-radius: 30px;
    flex-grow: 0;
    height: 60px;
    justify-content: space-around;
    width: 60px;

    svg {
        max-height: 20px;
        max-width: 40px;
        width: 100%;
    }
`;

interface ITransactionDetailsProps {
    onRefunded?: (transaction: ITransaction) => void;
    transaction: ITransaction;
}

const TransactionDetails: React.FC<ITransactionDetailsProps> = withTransactionCard(
    ({ isTransactionCardLoading, onRefunded = () => {}, transaction }) => {
        const { metadata, updated } = transaction;

        return (
            <Flex data-testid="transaction-detail" justifyContent="center" minHeight="100%">
                {isTransactionCardLoading ? (
                    <LoadingDialog description="" />
                ) : (
                    <Box width="100%">
                        <DetailsViewSupertext>
                            <TransactionSource collapseSource transactionMetadata={metadata} />
                            <br />
                            <Date includeTime shortFormat value={updated} />
                        </DetailsViewSupertext>
                        <TransactionOverview onRefunded={onRefunded} transaction={transaction} />
                        <TransactionTimeline transaction={transaction} />
                        <TransactionPaymentDetails transaction={transaction} />
                    </Box>
                )}
            </Flex>
        );
    }
);

interface ITransactionOverviewProps {
    onRefunded: (transaction: ITransaction) => void;
    transaction: ITransaction;
}

export const TransactionOverview: React.FC<ITransactionOverviewProps> = ({
    onRefunded,
    transaction
}) => {
    const {
        amount,
        app,
        contactName,
        currency,
        metadata,
        refundable,
        statementDescriptor
    } = transaction;
    const { brandName, contextTitle, sourceType } = metadata;
    const creditCardMask = <CreditCardMask transaction={transaction} />;
    const isPopUpShopTransaction = sourceType === 'pop-up-shop';

    return (
        <DetailsViewContent>
            <TransactionAppLogo
                showCollageLogo={isPopUpShopTransaction}
                variant={
                    app === 'shootproof'
                        ? TransactionAppLogoVariant.ShootProof
                        : TransactionAppLogoVariant.Tave
                }
            />
            <DetailsViewHeading>
                <Currency currency={currency} value={amount / 100} />
            </DetailsViewHeading>
            <TransactionStatusContainer>
                <TransactionStatusPill transaction={transaction} />
            </TransactionStatusContainer>
            <TransactionStatusBanner marginBottom="24px" transaction={transaction} />
            <Hr marginBottom="22px" />
            {!isPopUpShopTransaction && (
                <Detail label="transactionDetails.creditCardMaskLabel" value={creditCardMask} />
            )}
            {!isPopUpShopTransaction && (
                <Detail
                    label="transactionDetails.statementDescriptorLabel"
                    value={statementDescriptor}
                />
            )}
            <Detail label="transactionDetails.brandNameLabel" value={brandName} />
            {contextTitle && (
                <Detail label="transactionDetails.contextTitleLabel" value={contextTitle} />
            )}
            <Detail label="transactionDetails.contactNameLabel" value={contactName} />
            {refundable && (
                <TransactionOverviewButtons>
                    <Hr marginBottom="24px" marginTop="20px" />
                    <RefundAction onRefunded={onRefunded} transaction={transaction} />
                </TransactionOverviewButtons>
            )}
        </DetailsViewContent>
    );
};

export const TransactionOverviewButtons = styled(Box)`
    button {
        width: 100%;
    }
`;

export const TransactionPaymentDetail = styled(Detail)(
    ({ theme }) => `
        justify-content: space-between;
        margin-bottom: 8px;

        label {
            color: ${theme.colors.gray[700]};
            line-height: 20px;
        }

        span {
            color: ${theme.colors.gray[700]};
            line-height: 20px;
        }
    `
);

interface ITransactionPaymentDetailsProps {
    transaction: ITransaction;
    transactionDisputeStatus: TransactionDisputeStatus;
    transactionStatus: TransactionStatus;
}

export const TransactionPaymentDetails = withTransactionStatus<ITransactionPaymentDetailsProps>(
    ({ transaction, transactionDisputeStatus }) => {
        const {
            amount,
            amountRefunded,
            currency,
            disputes,
            feeAmount,
            feeAmountRefunded,
            metadata,
            netAmount,
            rate,
            salesTaxAmount,
            salesTaxAmountRefunded
        } = transaction;
        const { cardRate, cardFixedFee } = rate;
        const [dispute] = disputes;
        const { feeAmount: disputeFeeAmount } = dispute || { feeAmount: null };
        const getCurrencyFor = (amount) => <Currency currency={currency} value={amount / 100} />;
        const isDisputedWithAmountRemoved = [
            TransactionDisputeStatus.Lost,
            TransactionDisputeStatus.Pending
        ].includes(transactionDisputeStatus);
        const isDisputedWithFeeRemoved = [
            TransactionDisputeStatus.Lost,
            TransactionDisputeStatus.Pending,
            TransactionDisputeStatus.Won
        ].includes(transactionDisputeStatus);
        const {
            commissionPercent,
            digitalsSubtotal,
            labCostAmount,
            labCreditAmount,
            orderSubtotal,
            shipping,
            sourceType,
            taxesFees
        } = metadata;
        const isPopUpShopTransaction = sourceType === 'pop-up-shop';
        const formattedCardRate = new Decimal(cardRate).times(100).toNumber();
        const $ = useCurrency(currency);

        return (
            <DetailsViewContent data-testid="transaction-payment" paddingBottom="7px">
                <H4 marginBottom="20px">
                    <Message id="transactionDetails.paymentDetailsHeading" />
                </H4>
                {isPopUpShopTransaction && (
                    <>
                        <TransactionPaymentDetail
                            label="transactionDetails.orderSubtotalLabel"
                            value={getCurrencyFor(orderSubtotal)}
                        />
                        <TransactionPaymentDetail
                            label="transactionDetails.digitalsSubtotalLabel"
                            labelTooltip="transactionDetails.digitalsSubtotalTooltip"
                            value={getCurrencyFor(digitalsSubtotal)}
                        />
                        <TransactionPaymentDetail
                            label="transactionDetails.shippingLabel"
                            value={getCurrencyFor(shipping)}
                        />
                        <TransactionPaymentDetail
                            label="transactionDetails.taxesFeesLabel"
                            value={getCurrencyFor(taxesFees)}
                        />
                        <Hr marginBottom="12px" marginTop="10px" />
                    </>
                )}
                <TransactionPaymentDetail
                    label="transactionDetails.amountLabel"
                    value={getCurrencyFor(amount)}
                />
                {labCostAmount && (
                    <TransactionPaymentDetail
                        label="transactionDetails.labCostAmountLabel"
                        value={getCurrencyFor(-labCostAmount)}
                    />
                )}
                {labCreditAmount && (
                    <TransactionPaymentDetail
                        label="transactionDetails.labCreditAmountLabel"
                        value={getCurrencyFor(labCreditAmount)}
                    />
                )}
                {isDisputedWithAmountRemoved && (
                    <TransactionPaymentDetail
                        label="transactionDetails.disputedLabel"
                        value={getCurrencyFor(-amount)}
                    />
                )}
                {amountRefunded > 0 && (
                    <TransactionPaymentDetail
                        label="transactionDetails.amountRefundedLabel"
                        value={getCurrencyFor(-amountRefunded)}
                    />
                )}
                {isDisputedWithFeeRemoved && disputeFeeAmount > 0 && (
                    <TransactionPaymentDetail
                        label="transactionDetails.disputeFeeAmountLabel"
                        labelTooltip="transactionDetails.disputeFeeAmountTooltip"
                        value={getCurrencyFor(-disputeFeeAmount)}
                    />
                )}
                {!isPopUpShopTransaction && feeAmount > 0 && (
                    <TransactionPaymentDetail
                        label="transactionDetails.feeAmountLabel"
                        labelTooltip="transactionDetails.feeAmountTooltip"
                        labelTooltipValues={{
                            cardRate: formattedCardRate,
                            cardFee: $(cardFixedFee / 100)
                        }}
                        value={getCurrencyFor(-feeAmount)}
                    />
                )}
                {feeAmountRefunded > 0 && (
                    <TransactionPaymentDetail
                        label="transactionDetails.feeAmountRefundedLabel"
                        value={getCurrencyFor(feeAmountRefunded)}
                    />
                )}
                {!!salesTaxAmount && (
                    <TransactionPaymentDetail
                        label="transactionDetails.salesTaxAmountLabel"
                        value={getCurrencyFor(-salesTaxAmount)}
                    />
                )}
                {!!salesTaxAmountRefunded && (
                    <TransactionPaymentDetail
                        label="transactionDetails.salesTaxAmountRefundedLabel"
                        value={getCurrencyFor(salesTaxAmountRefunded)}
                    />
                )}
                <Hr marginBottom="12px" marginTop="10px" />
                <TransactionStrongPaymentDetail
                    label={
                        isPopUpShopTransaction
                            ? 'transactionDetails.netAmountPopUpShopLabel'
                            : 'transactionDetails.netAmountLabel'
                    }
                    labelTooltipComponent={
                        commissionPercent ? (
                            <TransactionPaymentDetailsPopUpShopNetAmountTooltipLabel
                                commissionPercent={commissionPercent}
                            />
                        ) : null
                    }
                    value={getCurrencyFor(netAmount)}
                />
            </DetailsViewContent>
        );
    }
);

interface ITransactionPaymentDetailsPopUpShopNetAmountTooltipLabelProps {
    commissionPercent: string;
}

export const TransactionPaymentDetailsPopUpShopNetAmountTooltipLabel: React.FC<ITransactionPaymentDetailsPopUpShopNetAmountTooltipLabelProps> = ({
    commissionPercent
}) => (
    <Box>
        <Span>
            <Message id="transactionDetails.popUpShopNetAmountTooltip" />
        </Span>
        <TransactionPaymentDetailsPopUpShopCommissionCalculation>
            <TransactionPaymentDetailsPopUpShopPercentageCommission>
                <Span whiteSpace="nowrap">
                    <Message
                        id="transactionDetails.popUpShopNetAmountCommissionPercentTooltip"
                        values={{ commissionPercent }}
                    />
                </Span>
                <Flex alignItems="center" flexDirection="column" marginLeft="8px">
                    <TransactionPaymentDetailsPopUpShopCommissionSpan
                        minWidth="86px"
                        whiteSpace="nowrap">
                        <Message id="transactionDetails.orderSubtotalLabel" />
                    </TransactionPaymentDetailsPopUpShopCommissionSpan>
                    <Strong>+</Strong>
                    <TransactionPaymentDetailsPopUpShopCommissionSpan minWidth="86px">
                        <Message id="transactionDetails.shippingLabel" />
                    </TransactionPaymentDetailsPopUpShopCommissionSpan>
                </Flex>
            </TransactionPaymentDetailsPopUpShopPercentageCommission>
            <Strong marginLeft="6px" marginRight="6px">
                +
            </Strong>
            <TransactionPaymentDetailsPopUpShopCommissionSpan
                height="48px"
                paddingBottom="11px"
                paddingTop="11px">
                <Message id="transactionDetails.digitalsSubtotalLabel" />
            </TransactionPaymentDetailsPopUpShopCommissionSpan>
            <Strong marginLeft="6px" marginRight="6px">
                =
            </Strong>
            <TransactionPaymentDetailsPopUpShopCommissionSpan
                height="48px"
                paddingBottom="11px"
                paddingTop="11px">
                <Message id="transactionDetails.popUpShopNetAmountMarginTooltip" />
            </TransactionPaymentDetailsPopUpShopCommissionSpan>
        </TransactionPaymentDetailsPopUpShopCommissionCalculation>
    </Box>
);

export const TransactionPaymentDetailsPopUpShopCommissionCalculation = styled(Flex)`
    align-items: center;
    margin-top: 8px;

    span {
        font-size: 10px;
    }
`;

export const TransactionPaymentDetailsPopUpShopCommissionSpan = styled(Span)(
    ({ theme }) => `
        background: ${theme.colors.gray[100]};
        display: inline-block;
        font-size: 8px;
        line-height: 14px;
        padding-left: 4px;
        padding-right: 4px;
        text-align: center;
    `
);

export const TransactionPaymentDetailsPopUpShopPercentageCommission = styled(Flex)(
    ({ theme }) => `
        align-items: center;
        border: 1px solid ${theme.colors.gray[300]};
        padding: 10px 8px;
        position: relative;

        &:after, &:before {
            background: white;
            content: "";
            height: 1px;
            left: 12px;
            position: absolute;
            width: calc(100% - 24px);
        }

        &:after {
            top: -1px;
        }

        &:before {
            bottom: -1px;
        }
    `
);

export const TransactionStatusContainer = styled(Flex)`
    justify-content: space-around;
    margin-bottom: 20px;
`;

export const TransactionStrongPaymentDetail = styled(TransactionPaymentDetail)`
    span {
        font-weight: 500;
    }
`;

export enum TransactionTimelineIndicatorVariant {
    Danger = 'danger',
    Neutral = 'neutral',
    Success = 'success',
    Warning = 'warning'
}

interface ITransactionTimelineProps {
    transaction: ITransaction;
}

export const TransactionTimeline: React.FC<ITransactionTimelineProps> = withTransactionStatus(
    ({ transaction, transactionDisputeStatus, transactionStatus }) => {
        const {
            completed,
            created,
            currency,
            disputes,
            initiated,
            refunded,
            refunds
        } = transaction;
        const paymentStarted = initiated || created;
        const $ = useCurrency(currency);
        const t = useMessage();
        const orderByTimelineValueDescending = (array) =>
            array.sort(({ value }, { value: nextTimelineValue }) =>
                value === nextTimelineValue ? 0 : value < nextTimelineValue ? 1 : -1
            );
        const orderTimelineEvents = () => {
            const [dispute] = disputes;
            const { created: disputeCreated, isInquiry, reason, resolvedOn } = dispute || {};
            const timelineEvents: ITransactionTimelineEntryProps[] = [];

            if (refunds.length > 0) {
                refunds.forEach(({ amount, created, note, reason, status }) => {
                    const reasonLabelMap = {
                        duplicate: 'transactionDetails.timelineRefundedReasonDuplicateLabel',
                        fraudulent: 'transactionDetails.timelineRefundedReasonFraudulentLabel',
                        requested_by_customer:
                            'transactionDetails.timelineRefundedReasonRequestedByCustomerLabel'
                    };
                    const label = t('transactionDetails.timelineRefundedLabel', {
                        amount: $(amount / 100),
                        reason: t(reasonLabelMap[reason])
                    });

                    if (status === TransactionRefundStatus.Failed) {
                        timelineEvents.push({
                            indicatorVariant: TransactionTimelineIndicatorVariant.Danger,
                            label: t('transactionDetails.timelineFailedRefundLabel'),
                            value: created
                        });
                    }

                    timelineEvents.push({
                        label,
                        labelSubtext: note || '',
                        value: created
                    });
                });
            }

            if (transactionStatus === TransactionStatus.Blocked) {
                timelineEvents.push({
                    indicatorVariant: TransactionTimelineIndicatorVariant.Danger,
                    label: t('transactionDetails.timelineBlockedLabel'),
                    value: completed
                });
            }

            if (transactionStatus === TransactionStatus.Failed) {
                timelineEvents.push({
                    indicatorVariant: TransactionTimelineIndicatorVariant.Danger,
                    label: t('transactionDetails.timelineFailedLabel'),
                    value: completed
                });
            }

            if (transactionDisputeStatus === TransactionDisputeStatus.Lost && resolvedOn) {
                timelineEvents.push({
                    label: t('transactionDetails.timelineDisputeLostLabel'),
                    value: resolvedOn
                });
            }

            if (
                transactionDisputeStatus === TransactionDisputeStatus.Won &&
                resolvedOn &&
                !refunded
            ) {
                timelineEvents.push({
                    indicatorVariant: TransactionTimelineIndicatorVariant.Success,
                    label: t('transactionDetails.timelineDisputeWonLabel'),
                    value: resolvedOn
                });
            }

            if (transactionDisputeStatus !== TransactionDisputeStatus.None && !isInquiry) {
                timelineEvents.push({
                    indicatorVariant: TransactionTimelineIndicatorVariant.Warning,
                    label: t('transactionDetails.timelineDisputedLabel', {
                        reason: t(getReasonTranslationKey(reason))
                    }),
                    value: disputeCreated
                });
            }

            if (isInquiry) {
                timelineEvents.push({
                    indicatorVariant: TransactionTimelineIndicatorVariant.Warning,
                    label: t('transactionDetails.timelinePreDisputeLabel'),
                    value: disputeCreated
                });
            }

            if (
                ![TransactionStatus.Blocked, TransactionStatus.Failed].includes(transactionStatus)
            ) {
                timelineEvents.push({
                    indicatorVariant: TransactionTimelineIndicatorVariant.Success,
                    label: t('transactionDetails.timelineCompletedLabel'),
                    value: completed
                });
            }

            return orderByTimelineValueDescending(timelineEvents);
        };

        return (
            <DetailsViewContent data-testid="transaction-timeline" paddingBottom="4px">
                <H4 marginBottom="20px">
                    <Message id="transactionDetails.timelineHeading" />
                </H4>
                {orderTimelineEvents().map((props, index) => (
                    <TransactionTimelineEntry key={index} {...props} />
                ))}
                <TransactionTimelineEntry
                    hideBar
                    label={t('transactionDetails.timelinePaymentStartedLabel')}
                    value={paymentStarted}
                />
            </DetailsViewContent>
        );
    }
);

export const TransactionTimelineBar = styled(Box)(
    ({ theme }) => `
        border-left: 1px solid ${theme.colors.gray[200]};
        flex: 1;
    `
);

interface ITransactionTimelineEntryProps {
    hideBar?: boolean;
    indicatorVariant?: TransactionTimelineIndicatorVariant;
    label: string;
    labelSubtext?: string;
    value: string;
}

export const TransactionTimelineEntry: React.FC<ITransactionTimelineEntryProps> = ({
    hideBar = false,
    indicatorVariant,
    label,
    labelSubtext,
    value
}) => {
    return (
        <Flex data-testid="transaction-timeline-entry">
            <Flex alignItems="center" direction="column" marginBottom="-5px" marginRight="28px">
                <TransactionTimelineIndicator variant={indicatorVariant} />
                {!hideBar && <TransactionTimelineBar />}
            </Flex>
            <Box marginBottom="12px">
                <P maxLineCount={3}>
                    {label}
                    {labelSubtext && (
                        <>
                            <br />
                            {labelSubtext}
                        </>
                    )}
                </P>
                <TransactionTimelineTimestamp>
                    <Date includeTime value={value} />
                </TransactionTimelineTimestamp>
            </Box>
        </Flex>
    );
};

interface ITransactionTimelineIndicatorProps {
    variant?: TransactionTimelineIndicatorVariant;
}

export const TransactionTimelineIndicator: React.FC<ITransactionTimelineIndicatorProps> = ({
    variant = TransactionTimelineIndicatorVariant.Neutral
}) => {
    const { colors } = useTheme();
    let dotBackground;

    switch (variant) {
        case TransactionTimelineIndicatorVariant.Danger:
            dotBackground = colors.red[600];

            break;
        case TransactionTimelineIndicatorVariant.Neutral:
            dotBackground = colors.gray[200];

            break;
        case TransactionTimelineIndicatorVariant.Success:
            dotBackground = colors.green[400];

            break;
        case TransactionTimelineIndicatorVariant.Warning:
            dotBackground = colors.orange[400];

            break;
    }

    return <TransactionTimelineIndicatorDot style={{ background: dotBackground }} />;
};

export const TransactionTimelineIndicatorDot = styled(Box)`
    border-radius: 4.5px;
    flex: 0 0 9px;
    margin-bottom: 2px;
    margin-top: 7px;
    width: 9px;
`;

export const TransactionTimelineTimestamp = styled(Span)(
    ({ theme }) => `
        color: ${theme.colors.gray[400]};
        font-size: 12px;
        line-height: 16px;
    `
);

export default TransactionDetails;
