import React from 'react';
import { BoxProps, P, smallScreenOnly } from 'sp-ui';
import { useMerchantAccount } from './Context';
import { Permission, withPermission } from '../permissions';
import Link from '../Link';
import { Message } from '../intl';

export const LinkWithAdminPermission = withPermission(Permission.Admin, Link);

const PayoutSchedule: React.FC<BoxProps> = (props) => {
    const { merchantAccount } = useMerchantAccount();
    const { id, payoutScheduleInterval } = merchantAccount;
    const { [payoutScheduleInterval]: translation } = {
        daily: 'merchantAccountPayoutSchedule.daily',
        monthly: 'merchantAccountPayoutSchedule.monthly',
        weekly: 'merchantAccountPayoutSchedule.weekly'
    };

    return (
        <P marginBottom="20px" {...props}>
            <Message id={translation} /> <SmallScreenBr />
            <LinkWithAdminPermission
                paddingBottom="22px"
                to={`/payment-account/${id}/settings/payout`}>
                <Message id="merchantAccountPayoutSchedule.editLink" />
            </LinkWithAdminPermission>
        </P>
    );
};

const SmallScreenBr = smallScreenOnly((props) => <br {...props} />);

export default PayoutSchedule;
