import React, { useState } from 'react';
import { Banner, BannerVariant, Flex, LoadingDialog, P, styled } from 'sp-ui';
import { PayoutStatus } from './common';
import { PayoutDetailsBalanceTransactions, PayoutDetailsViewProvider } from './details';
import { IPayout } from './types';
import { PayoutAccount } from '../payout-account';
import PayoutStatusPill from './StatusPill';
import withPayoutStatus from './withPayoutStatus';
import Hr from '../Hr';
import { Currency, Date, Message } from '../intl';
import {
    Detail,
    DetailsViewContent,
    DetailsViewHeading,
    DetailsViewSupertext
} from '../../layouts/DetailsView';

interface IPayoutDetailsProps {
    payout: IPayout;
}

const PayoutDetails: React.FC<IPayoutDetailsProps> = ({ payout }) => {
    const {
        amount,
        arrivalDate,
        created,
        currency,
        feeAmount,
        grossAmount,
        id: payoutId,
        method,
        payoutAccountResource
    } = payout;
    const [hasLoaded, setHasLoaded] = useState<boolean>(false);
    const isInstantPayout = method === 'instant';
    const methodTranslation = isInstantPayout
        ? 'payoutDetails.instantMethod'
        : 'payoutDetails.standardMethod';

    return (
        <Flex
            data-testid="payout-detail"
            flexDirection="column"
            height={hasLoaded ? 'initial' : '100%'}
            justifyContent="center">
            <PayoutDetailsViewProvider backToPayoutText="payoutDetails.backToPayout">
                {hasLoaded ? (
                    <>
                        <DetailsViewSupertext marginBottom="24px">
                            <Date includeTime value={created} />
                        </DetailsViewSupertext>
                        <DetailsViewContent>
                            <DetailsViewHeading>
                                <Currency currency={currency} value={amount / 100} />
                            </DetailsViewHeading>
                            <PayoutStatusContainer>
                                <PayoutStatusPill payout={payout} />
                            </PayoutStatusContainer>
                            <PayoutDetailsBanner payout={payout} />
                            <Hr marginBottom="22px" />
                            <Detail
                                label="payoutDetails.payoutAccountLabel"
                                value={<PayoutAccount payoutAccount={payoutAccountResource} />}
                            />
                            <Detail
                                label="payoutDetails.methodLabel"
                                value={<Message id={methodTranslation} />}
                            />
                            <Detail
                                label="payoutDetails.arrivalDateLabel"
                                value={<Date ignoreTime value={arrivalDate} />}
                            />
                            <Detail
                                label="payoutDetails.grossAmountLabel"
                                value={<Currency currency={currency} value={grossAmount / 100} />}
                            />
                            <Detail
                                label="payoutDetails.feeAmountLabel"
                                value={<Currency currency={currency} value={feeAmount / 100} />}
                            />
                        </DetailsViewContent>
                    </>
                ) : (
                    <LoadingDialog description="" />
                )}
                <PayoutDetailsBalanceTransactions
                    currency={currency}
                    isInstantPayout={isInstantPayout}
                    isVisible={hasLoaded}
                    onLoaded={setHasLoaded}
                    payoutId={payoutId}
                />
            </PayoutDetailsViewProvider>
        </Flex>
    );
};

export const PayoutDetailsBanner = withPayoutStatus(({ payoutStatus }) =>
    payoutStatus === PayoutStatus.Failed ? (
        <Banner marginBottom="24px" variant={BannerVariant.Danger}>
            <P>
                <Message id="payoutDetails.failedBanner" />
            </P>
        </Banner>
    ) : null
);

export const PayoutStatusContainer = styled(Flex)`
    justify-content: space-around;
    margin-bottom: 20px;
`;

export default PayoutDetails;
