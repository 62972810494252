import React, { useCallback, useEffect, useRef } from 'react';
import { Box, BoxProps, Flex, Label, P, Span, styled } from 'sp-ui';
import { ReactComponent as CloseIcon } from '../assets/svg/close-icon.svg';
import { Message, useMessage } from '../components/intl';

interface IDetailProps {
    label: string;
    labelTooltip?: string;
    labelTooltipComponent?: React.ReactNode;
    labelTooltipValues?: Record<string, number | string>;
    value: React.ReactNode;
}

export const Detail: React.FC<IDetailProps> = ({
    label,
    labelTooltip,
    labelTooltipComponent,
    labelTooltipValues,
    value,
    ...props
}) => {
    const t = useMessage();

    return (
        <DetailContainer {...props}>
            <Label
                // @ts-ignore
                tooltipLabel={
                    labelTooltipComponent
                        ? labelTooltipComponent
                        : labelTooltip && t(labelTooltip, labelTooltipValues)
                }>
                <Message id={label} />
            </Label>
            <Span>{value}</Span>
        </DetailContainer>
    );
};

const DetailContainer = styled(Flex)(
    ({ theme }) => `
        margin-bottom: 4px;

        label {
            color: ${theme.colors.gray[600]};
            line-height: 24px;
            margin-right: 12px;
        }

        span {
            line-height: 24px;
        }
    `
);

interface IDetailsProps {
    onClose: () => void;
}

const DetailsView: React.FC<IDetailsProps> = ({ children, onClose, ...props }) => {
    const onDocumentKeyup = useCallback(
        ({ keyCode }: KeyboardEvent) => {
            // Escape
            if (keyCode === 27) {
                onClose();
            }
        },
        [onClose]
    );
    const ref = useRef<HTMLDivElement>(null);
    const onDocumentMousedown = useCallback(
        ({ target }) => {
            const targetInModal = ref.current?.contains(target);
            const targetInPortal = !document.getElementById('root')?.contains(target);

            if (!targetInModal && !targetInPortal) {
                onClose();
            }
        },
        [onClose, ref]
    );

    useEffect(() => {
        document.removeEventListener('keyup', onDocumentKeyup);
        document.addEventListener('keyup', onDocumentKeyup);

        return () => {
            document.removeEventListener('keyup', onDocumentKeyup);
        };
    }, [onDocumentKeyup]);

    useEffect(() => {
        document.removeEventListener('mousedown', onDocumentMousedown);
        document.addEventListener('mousedown', onDocumentMousedown);

        return () => {
            document.removeEventListener('mousedown', onDocumentMousedown);
        };
    }, [onDocumentMousedown]);

    return (
        <DetailsViewContainer {...props} ref={ref}>
            <DetailsViewCloseButton data-testid="close-details-action" onClick={onClose}>
                <CloseIcon />
            </DetailsViewCloseButton>
            {children}
        </DetailsViewContainer>
    );
};

export const DetailsViewCloseButton = styled(Box)(
    ({ theme }) => `
        cursor: pointer;
        margin-right: 3px;
        margin-top: 3px;
        padding: 3px;
        position: absolute;
        right: 24px;
        width: 17px;

        svg {
            fill: ${theme.colors.gray[700]};
            height: 11px;
            width: 11px;
        }
    `
);

export const DetailsViewContainer = styled(Box)(
    ({ theme }) => `
        background: white;
        padding: 24px;

        ${theme.responsive.smallScreenOnly(`
            padding-left: 16px;
            padding-right: 16px;
        `)}
    `
);

export const DetailsViewContent: React.FC<BoxProps> = (props) => (
    <DetailsViewContentBox mb="16px" p="24px" {...props} />
);

const DetailsViewContentBox = styled(Box)(
    ({ theme }) => `
        background: white;
        border: 1px solid ${theme.colors.gray[200]};

        ${theme.responsive.smallScreenOnly(`
            padding-left: 16px;
            padding-right: 16px;
        `)}
    `
);

export const DetailsViewHeading = styled(Span)`
    display: block;
    font-size: 44px;
    font-weight: 300;
    line-height: 56px;
    margin-bottom: 5px;
    text-align: center;
`;

export const DetailsViewSupertext = styled(P)(
    ({ marginBottom, theme }) => `
        color: ${theme.colors.gray[700]};
        line-height: 24px;
        margin-bottom: ${marginBottom || '52px'};
        text-align: center;
    `
);

export default DetailsView;
