export enum TransactionDisputeStatus {
    Lost = 'lost',
    None = 'none',
    Pending = 'pending',
    WarningClosed = 'warning_closed',
    Won = 'won'
}

export enum TransactionRefundStatus {
    Canceled = 'canceled',
    Failed = 'failed',
    Pending = 'pending',
    Succeeded = 'succeeded'
}

export enum TransactionStatus {
    Blocked = 'blocked',
    Disputed = 'disputed',
    Failed = 'failed',
    FailedRefund = 'failed-refund',
    Pending = 'pending',
    Refunded = 'refunded',
    PartiallyRefunded = 'partially-refunded',
    PreDispute = 'pre-dispute',
    Succeeded = 'succeeded'
}
