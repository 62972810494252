import React, { useLayoutEffect } from 'react';
import { Box, Flex, LoadingDialog } from 'sp-ui';
import { useMessage } from '../../components/intl';
import { OnboardingStepper, Step } from '../../components/onboarding';

const OnboardingCompletePage: React.FC = () => {
    const t = useMessage();

    useLayoutEffect(() => {
        const url = `${process.env.REACT_APP_SP_ACCOUNT_ACCEPT_PAYMENTS_URL}`;

        setTimeout(() => {
            window.location.assign(url);
        }, 2000);
    }, []);

    return (
        <Flex flexDirection="column" height="100%">
            <OnboardingStepper currentStep={Step.Fourth} isCurrentStepComplete />
            <Box height="100%">
                <LoadingDialog
                    description=""
                    heading={t('onboarding.complete.loadingDialog.heading')}
                    subheading={t('onboarding.complete.loadingDialog.subheading')}
                />
            </Box>
        </Flex>
    );
};

export default OnboardingCompletePage;
