// @ts-ignore
import React from 'react';
import {
    A,
    Box,
    BoxProps,
    ButtonVariant,
    Flex,
    Formik,
    FormikForm,
    FormikHelpers,
    FormikValues,
    H3,
    P,
    Tooltip,
    styled,
    yup
} from 'sp-ui';
import { ConfirmNavigation, FormButton } from '../form';
import { Message } from '../intl';
import { noop } from '../../utils';

export const DisabledLink = styled(A)(
    ({ theme }) => `
        color: ${theme.colors.gray[200]};
        
        &:hover {
            color: ${theme.colors.gray[200]};
        }
    `
);

interface ISettingsDetailProps<T extends FormikValues> {
    heading?: string;
    headingAction?: {
        isDisabled?: boolean;
        onClick: () => void;
        text: string;
        tooltip?: typeof Tooltip;
    };
    initialValues?: T;
    onSubmit?: (formValues: T, formHelpers: FormikHelpers<T>) => Promise<void> | void;
    noChildrenPadding?: boolean;
    subheading?: string;
    validationSchema?: yup.ObjectSchema<any>;
}

const SettingsDetail = <T extends FormikValues>({
    children,
    heading,
    headingAction,
    initialValues = {} as T,
    onSubmit = noop,
    noChildrenPadding = false,
    subheading,
    validationSchema,
    ...props
}: ISettingsDetailProps<T> & Omit<BoxProps, 'onSubmit'>) => {
    const HeadingActionTooltip =
        headingAction && headingAction.tooltip ? headingAction.tooltip : React.Fragment;

    return (
        <Formik<T>
            initialValues={initialValues}
            onSubmit={async (values, formikHelpers) => {
                await onSubmit(values, formikHelpers);

                formikHelpers.resetForm({ values });
            }}
            validateOnMount
            validationSchema={validationSchema}>
            {({ resetForm, validateForm }) => (
                <Box position="relative" {...props}>
                    <SettingsDetailForm>
                        {heading && (
                            <SettingsDetailHeading>
                                <H3>{heading}</H3>
                                {subheading && (
                                    <SettingsDetailSubheading>
                                        {subheading}
                                    </SettingsDetailSubheading>
                                )}
                                {headingAction &&
                                    (headingAction.isDisabled ? (
                                        <DisabledLink onClick={() => {}}>
                                            <HeadingActionTooltip>
                                                {headingAction.text}
                                            </HeadingActionTooltip>
                                        </DisabledLink>
                                    ) : (
                                        <A onClick={headingAction.onClick}>
                                            <HeadingActionTooltip>
                                                {headingAction.text}
                                            </HeadingActionTooltip>
                                        </A>
                                    ))}
                            </SettingsDetailHeading>
                        )}
                        <Box padding={noChildrenPadding ? undefined : '18px 20px 14px'}>
                            {children}
                        </Box>
                        {onSubmit !== noop && (
                            <SettingsDetailFormButtons direction="row-reverse" padding="15px 20px">
                                <FormButton disableUntilFormDirty disableUntilFormValid>
                                    <Message id="settings.detail.formButtons.save" />
                                </FormButton>
                                <FormButton
                                    disableUntilFormDirty
                                    marginRight="36px"
                                    loadedText={undefined}
                                    loadingText={undefined}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        resetForm();
                                        setTimeout(validateForm);
                                    }}
                                    variant={ButtonVariant.Dismissive}>
                                    <Message id="settings.detail.formButtons.cancel" />
                                </FormButton>
                            </SettingsDetailFormButtons>
                        )}
                        <ConfirmNavigation />
                    </SettingsDetailForm>
                </Box>
            )}
        </Formik>
    );
};

export const SettingsDetailForm = styled(FormikForm)(
    ({ theme }) => `
        border: 1px solid ${theme.colors.gray[200]};
        border-radius: 4px;
    `
);

export const SettingsDetailFormButtons = styled(Flex)(
    ({ theme }) => `
        background: ${theme.colors.gray[100]};
        border-top: 1px solid ${theme.colors.gray[200]};
    `
);

export const SettingsDetailHeading = styled(Flex)(
    ({ theme }) => `
        border-bottom: 1px solid ${theme.colors.gray[200]};
        flex-direction: column;
        padding: 18px 20px 14px;

        h3 {
            margin-bottom: 3px;
        }

        ${theme.responsive.mediumScreenUp`
            position: relative;

            a {
                position: absolute;
                right: 20px;
                top: 19px;
            }
        `}
    `
);

export const SettingsDetailSubheading = styled(P)(
    ({ theme }) => `
        color: ${theme.colors.gray[400]};

        ${theme.responsive.smallScreenOnly`
            margin-bottom: 6px;
        `}
    `
);

export default SettingsDetail;
