import { Path } from 'path-parser';
import { parse } from 'query-string';
import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { RouteComponentProps } from 'react-router-dom';
import { usePaymentsApiFetch } from '../hooks';

const AUTHENTICATION_TOKEN_COOKIE = 'auth';
const IS_IMPERSONATED_COOKIE = 'i-oidc';
const PLATFORM_ACCOUNT_COOKIE = 'platform-account-oidc';
const PLATFORM_USER_COOKIE = 'platform-user-oidc';
const RETURN_TO_STORAGE_KEY = 'return-to';

const Authentication: React.FC<Pick<RouteComponentProps, 'location'>> = ({ location }) => {
    const { code, returnTo = '' } = parse(location.search);
    const { 0: response, 2: getAuthToken } = usePaymentsApiFetch('/auth/login', {
        data: { code },
        defer: true,
        excludeAuthorizationHeader: true,
        method: 'post'
    });
    const { 1: setCookie } = useCookies<any>([]);

    useEffect(() => {
        if (code) {
            getAuthToken();
        }
    }, [code, getAuthToken]);

    useEffect(() => {
        if (!code || (response && response.status === 401)) {
            const authorizationUrlQueryParams = {
                client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
                max_age: process.env.REACT_APP_OIDC_MAX_AGE,
                response_type: 'code',
                scope: process.env.REACT_APP_OIDC_SCOPE,
                redirect_uri: `${process.env.REACT_APP_BASE_URL}/authenticate`
            };
            const authorizationUrlQuery = `?${Object.entries(authorizationUrlQueryParams)
                .map((entry) => entry.join('='))
                .join('&')}`;
            const authorizationUrl = `${process.env.REACT_APP_OIDC_URL}${authorizationUrlQuery}`;

            localStorage.setItem(RETURN_TO_STORAGE_KEY, `${returnTo}`);
            window.location.assign(authorizationUrl);
        }
    }, [code, response, returnTo]);

    useEffect(() => {
        if (response?.data?.authToken) {
            const { data } = response;
            const { authToken, isDelegated, platformAccountId, platformUserId } = data;
            const redirectTo = localStorage.getItem(RETURN_TO_STORAGE_KEY) || '/';
            let redirectPath = '/';

            try {
                ({ path: redirectPath } = Path.createPath(redirectTo));
            } catch {}

            localStorage.removeItem(RETURN_TO_STORAGE_KEY);
            setCookie(AUTHENTICATION_TOKEN_COOKIE, authToken, {
                path: '/',
                sameSite: 'strict',
                secure: process.env.NODE_ENV === 'production'
            });
            setCookie(IS_IMPERSONATED_COOKIE, isDelegated, {
                path: '/',
                sameSite: 'strict',
                secure: process.env.NODE_ENV === 'production'
            });
            setCookie(PLATFORM_ACCOUNT_COOKIE, platformAccountId, {
                path: '/',
                sameSite: 'strict',
                secure: process.env.NODE_ENV === 'production'
            });
            setCookie(PLATFORM_USER_COOKIE, platformUserId, {
                path: '/',
                sameSite: 'strict',
                secure: process.env.NODE_ENV === 'production'
            });
            window.location.replace(redirectPath);
        }
    }, [response, setCookie]);

    return null;
};

interface IAuthenticationTokenHook {
    invalidateToken: () => void;
    isDelegated: boolean;
    token?: string;
    platformAccountId?: string;
    platformUserId?: string;
}

export function useAuthenticationToken(): IAuthenticationTokenHook {
    const { 0: cookies, 2: removeCookie } = useCookies<any>([AUTHENTICATION_TOKEN_COOKIE]);

    return {
        invalidateToken: () => {
            removeCookie(AUTHENTICATION_TOKEN_COOKIE, { path: '/' });
            removeCookie(IS_IMPERSONATED_COOKIE, { path: '/' });
            removeCookie(PLATFORM_ACCOUNT_COOKIE, { path: '/' });
            removeCookie(PLATFORM_USER_COOKIE, { path: '/' });
        },
        isDelegated: cookies[IS_IMPERSONATED_COOKIE] === 'true',
        token: cookies[AUTHENTICATION_TOKEN_COOKIE],
        platformAccountId: cookies[PLATFORM_ACCOUNT_COOKIE],
        platformUserId: cookies[PLATFORM_USER_COOKIE]
    };
}

export default Authentication;
