import React from 'react';
import { LoadingDialog } from 'sp-ui';
import PayoutDetailsBackToPayoutLink from './BackToPayoutLink';
import { TransactionDetails } from '../../transaction';
import { usePaymentsApiFetch, usePaymentsApiMerchantAccountPath } from '../../../hooks';

interface IPayoutDetailsTransactionViewProps {
    transactionId: string;
}

const PayoutDetailsTransactionView: React.FC<IPayoutDetailsTransactionViewProps> = ({
    transactionId
}) => {
    const transactionsPath = usePaymentsApiMerchantAccountPath(`/transaction/${transactionId}`);
    const [transactionResponse] = usePaymentsApiFetch(transactionsPath);
    const { data: transaction } = transactionResponse || {};

    return (
        <>
            <PayoutDetailsBackToPayoutLink />
            {transaction ? (
                <TransactionDetails transaction={transaction} />
            ) : (
                <LoadingDialog description="" />
            )}
        </>
    );
};

export default PayoutDetailsTransactionView;
