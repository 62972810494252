import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useEffect } from 'react';
import { usePaymentsApiFetch } from '../../hooks';

const GetNotificationPreference = ({ onGot }) => {
    const [notificationPreferenceResponse] = usePaymentsApiFetch('/notification-preference');
    const preference = notificationPreferenceResponse?.data;

    useEffect(() => {
        if (preference) {
            if (preference.phone) {
                preference.phone = parsePhoneNumberFromString(
                    preference.phone,
                    'US'
                )?.formatNational();
            }

            onGot(preference);
        }
    }, [onGot, preference]);

    return null;
};

export default GetNotificationPreference;
