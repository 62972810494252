import React from 'react';
import { Box, H1, H3, P, MediumScreenUp, SmallScreenOnly, styled } from 'sp-ui';

export const DetailLayout = styled(Box)(
    ({ theme }) => `
        height: auto;
        margin-left: auto;
        margin-right: auto;
        max-width: 550px;
        padding-bottom: 38px;

        ${theme.responsive.smallScreenOnly`
            margin: 0 0 26px 0;
            max-width: 100%;
        `}
    `
);

interface ISettingsDetailLayoutProps {
    heading: string;
    subheading: string;
}

const SettingsDetailLayout: React.FC<ISettingsDetailLayoutProps> = ({
    children,
    heading,
    subheading,
    ...props
}) => {
    return (
        <DetailLayout {...props}>
            <MediumScreenUp>
                <H1 data-testid="settings-view-heading" mb="8px">
                    {heading}
                </H1>
                <P data-testid="settings-view-subheading" mb="34px">
                    {subheading}
                </P>
            </MediumScreenUp>
            <SmallScreenOnly>
                <H3 data-testid="settings-view-heading-small-screen" mb="4px">
                    {heading}
                </H3>
                <P data-testid="settings-view-subheading-small-screen" mb="20px">
                    {subheading}
                </P>
            </SmallScreenOnly>
            {children}
        </DetailLayout>
    );
};

export default SettingsDetailLayout;
