import {
    Menu as ChakraUiMenu,
    MenuButton,
    MenuList as ChakraUiMenuList,
    MenuItem as ChakraUiMenuItem
} from '@chakra-ui/react';
import React from 'react';
import { Box, BoxProps, Span, styled } from 'sp-ui';
import { ReactComponent as KebabIcon } from '../assets/svg/kebab-icon.svg';
import { Message } from './intl';

export interface IMenuItem {
    isDestructive?: boolean;
    onClick: () => void;
    text: string;
}

interface IMenuProps {
    items: IMenuItem[];
}

const Menu: React.FC<IMenuProps & BoxProps> = ({ children, items, ...props }) => (
    <MenuContainer data-testid="menu" height="24px" width="24px" {...props}>
        <ChakraUiMenu placement="bottom-end">
            <MenuButton data-testid="menu-button" height="100%" width="100%">
                {children || <KebabIcon />}
            </MenuButton>
            <MenuList data-testid="menu-list">
                {items.map(({ text, ...props }, index) => (
                    <MenuItem data-testid={`menu-item-{index}`} key={index} {...props}>
                        <Span>
                            <Message id={text} />
                        </Span>
                    </MenuItem>
                ))}
            </MenuList>
        </ChakraUiMenu>
    </MenuContainer>
);

const MenuContainer = styled(Box)`
    [x-placement='bottom-end']:before {
        top: -6px;
        transform: rotate(45deg);
    }

    [x-placement='top-end']:before {
        bottom: -6px;
        transform: rotate(225deg);
    }
`;

export const MenuItem = styled(ChakraUiMenuItem, {
    shouldForwardProp: (prop) => prop !== 'isDestructive'
})(
    ({ isDestructive, theme }) => `
        cursor: pointer;
        min-height: auto;
        padding: 0 16px;

        span {
            color: ${isDestructive ? theme.colors.red[600] : theme.colors.gray[600]};
        }

        &:focus, &:hover {
            background: inherit;
        }

        &:not(:last-of-type) {
            margin-bottom: 8px;
        }
    `
);

export const MenuList = styled(ChakraUiMenuList)(
    ({ theme }) => `
        border-color: ${theme.colors.gray[200]};
        margin-right: -5px;
        min-width: 125px;
        padding-bottom: 10px;
        padding-top: 13px;

        &:before {
            background: white;
            border: 1px solid ${theme.colors.gray[200]};
            border-bottom: none;
            border-right: none;
            height: 10px;
            position: absolute;
            right: 11px;
            width: 10px;
        }
    `
);

export default Menu;
