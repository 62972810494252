export enum PayoutMethod {
    Instant = 'instant',
    Standard = 'standard'
}

export enum PayoutStatus {
    Canceled = 'canceled',
    Failed = 'failed',
    InTransit = 'in_transit',
    Paid = 'paid',
    Pending = 'pending',
    Withdrawn = 'withdrawn'
}
