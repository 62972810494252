import React, { useCallback } from 'react';
import { IModal } from 'sp-ui';
import { NewDebitCardModalContent, usePayoutAccount } from '../payout-account';
import { useMessage } from '../intl';
import { useToast } from '../toast';

interface INewBankAccountModalProps {
    close: () => void;
    modalComponent: IModal;
}

const NewDebitCardModal: React.FC<INewBankAccountModalProps> = ({
    close,
    modalComponent: Modal
}) => {
    const { createToast } = useToast();
    const { addToPayoutAccounts } = usePayoutAccount();
    const t = useMessage();
    const onDebitCardCreated = useCallback(
        (payoutAccount) => {
            addToPayoutAccounts(payoutAccount);
            close();
            createToast({
                text: t('payoutAccount.newPayoutAccountModalToast.text'),
                subText: t('payoutAccount.newPayoutAccountModalToast.subText')
            });
        },
        [addToPayoutAccounts, close, createToast, t]
    );

    return (
        <Modal heading={t('settings.newDebitCardModal.heading')}>
            <NewDebitCardModalContent
                hideBackButton
                onCancel={close}
                onSuccess={onDebitCardCreated}
            />
        </Modal>
    );
};

export default NewDebitCardModal;
