import React from 'react';
import { useMerchantAccount } from './Context';
import { GlobalErrorBanner } from '../navigation';
import { Message } from '../intl';

const MerchantAccountRejectedBanner: React.FC = () => {
    const {
        merchantAccount: { disabledReason }
    } = useMerchantAccount();

    if (!disabledReason || !disabledReason.includes('rejected.')) {
        return null;
    }

    return (
        <GlobalErrorBanner>
            <Message id="merchantAccount.rejectedBanner.description" />
        </GlobalErrorBanner>
    );
};

export default MerchantAccountRejectedBanner;
