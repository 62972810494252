import React, { useEffect } from 'react';
import { useMerchantAccount } from './merchant-account';
import { useAuthenticationToken } from './Authentication';

interface IPendoProps {
    hasMerchantAccountContext?: boolean;
}

const Pendo: React.FC<IPendoProps> = ({ hasMerchantAccountContext }) => {
    const { isDelegated } = useAuthenticationToken();

    return isDelegated ? null : hasMerchantAccountContext ? (
        <PendoWithMerchantAccountPresent />
    ) : (
        <PendoInitializer />
    );
};

interface IPendoInitializerProps {
    accountMetadata?: object;
    visitorMetadata?: object;
}

export const PendoInitializer: React.FC<IPendoInitializerProps> = ({
    accountMetadata = {},
    visitorMetadata = {}
}) => {
    const { platformAccountId, platformUserId } = useAuthenticationToken();

    useEffect(() => {
        // @ts-ignore
        if (window.pendo) {
            // @ts-ignore
            window.pendo.initialize({
                account: { ...accountMetadata, id: platformAccountId },
                visitor: { ...visitorMetadata, id: platformUserId }
            });
        }
    }, [accountMetadata, platformAccountId, platformUserId, visitorMetadata]);

    return null;
};

export const PendoWithMerchantAccountPresent: React.FC = () => {
    useMerchantAccount();

    return (
        <PendoInitializer
            accountMetadata={{
                sp_pay_account: 'yes'
            }}
        />
    );
};

export default Pendo;
